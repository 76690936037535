import { Box, Grid } from "@mui/material";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";
import CustomFilters from "../../../components/Selects/CustomsSelector/CustomFilters";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";

export default function FacilityOverviewFilterMenu(props) {
  const {
    customs,
    onSubmit = () => {},
    setModalState = () => {},
    setState,
    state,
  } = props;
  const { filters = {} } = state;
  const {tag = ''} = filters

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "30rem",
      }}
    >
      <Grid container>
        {/* Tag Id */}
        <Grid item xs={12}>
          <SimpleTextField
            onChange={(event) =>
              setState((prevState) => ({
                ...prevState,
                filters: {
                  ...prevState.filters,
                  tag: event.target.value,
                },
              }))
            }
            fullWidth
            size="large"
            value={tag}
            label="Tag Id"
          />
        </Grid>

        {/* Custom Data Elements */}
        <CustomFilters
          cardSx={{ marginTop: ".5rem" }}
          customs={customs}
          state={state}
          setState={setState}
          parentSx={{ marginTop: "1.5rem" }}
          xsSize={12}
        />
      </Grid>

      {/* Submit and cancel buttons */}
      <Grid
        container
        sx={{ justifyContent: "space-between", margin: "1rem 0" }}
      >
        {/* Cancel */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="cancel"
            fullWidth
            label="Cancel"
            onClick={() =>
              setModalState((prevState) => ({ ...prevState, modalShow: false }))
            }
          />
        </Grid>

        {/* Submit */}
        <Grid item xs={4}>
          <MaterialUiButton
            color="submit"
            fullWidth
            label="Submit"
            onClick={() =>
              onSubmit()
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}
