import { useEffect, useState } from "react";
import { Box, Grid, Tooltip } from "@mui/material/";
import { MapInteractionCSS } from "react-map-interaction";
import Draggable from "react-draggable";
import ZoneRadius from "./components/ZoneRadius";

export default function FacilityImage(props) {
  const {
    facilityImage,
    handleImageLoad = () => {},
    img,
    isFacilityPage = false,
    setModalState,
    setState,
    state = {},
  } = props;

  const classes = {
    image: {
      position: "absolute",
      pointerEvents: "none",
      height: "100%",
    },
    overViewContainer: {
      alignItems: "center",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      margin: "0 auto",
      padding: "1rem 0",
      position: "relative",
    },
    radioText: {
      position: "absolute",
      textAlign: "center",
      userSelect: "none",
    },
  };

  const {
    assets,
    facilityZoneHashMap = {},
    farPoint = {},
    fixedNodes,
    grid = {},
    radioHashMap = {},
    viewOptions = {},
    zeroPoint = {},
  } = state;
  const {
    showAssets = true,
    showRadios = true,
    showZones = true,
  } = viewOptions;
  const [facilityImageState, setFacilityImageState] = useState([]);
  const [points, setPoints] = useState({
    zeroPoint: { disablePlunger: false, hasLoaded: false, x: 0, y: 0 },
    farPoint: { disablePlunger: false, hasLoaded: false, x: 0, y: 0 },
  });

  // These values grab the ratio between pixels and the user provided (m) of their facility
  const lengthValue = Math.abs(zeroPoint.x - farPoint.x) / grid.x;
  const heightValue = Math.abs(zeroPoint.y - farPoint.y) / grid.y;

  function getY(y) {
    // If the start point is in the lower left we hit the if statement, else its in the upper left
    if (zeroPoint.y > farPoint.y) {
      // return (img.height - (img.height - zeroPoint.y)) - y * heightValue + zeroPoint.y ;
      return img.height - (img.height - zeroPoint.y) - y * heightValue;
    } else {
      return y * heightValue + zeroPoint.y;
    }
  }

  // We used this useEffect to set the image and set the far and zero points
  useEffect(
    () => {
      // We are settings fixedNodes as a stateful item in this component.
      // That way the pins will move around in the case a user saves a new value
      setFacilityImageState(fixedNodes);
      setPoints((prevState) => ({
        ...prevState,
        farPoint: { ...farPoint, hasLoaded: true },
        zeroPoint: { ...zeroPoint, hasLoaded: true },
      }));
      //set conditional which only includes the zero and far point on facility page because there is a possibility of the zero and far points changing.
      //In the inventory page view, the zero and far points will not change
    },
    isFacilityPage ? [farPoint, fixedNodes, zeroPoint] : []
  );

  return (
    // <Grid sx={classes.overViewContainer} item xs={12}>
    <Grid sx={classes.overViewContainer} item>
      <MapInteractionCSS
        disablePan={
          !points.zeroPoint.disablePlunger || !points.farPoint.disablePlunger
        }
      >
        <div
          style={{
            //if in facility page and no points have been set, then add 40px to height to make points visible
            height: `${
              isFacilityPage && !points.zeroPoint.disablePlunger
                ? img.height + 70
                : img.height
            }px`,
          }}
        >
          {/* conditionally render image */}
          {/* add need for zero point, far point, and grid as well as facilityImage if not in facilitypage */}
          {facilityImage ? (
            <img
              alt={
                state.selectedFacility?.name
                  ? `${state.selectedFacility?.name}-facility-layout`
                  : "facility-layout-placeholder"
              }
              draggable={false}
              height={img.height !== 0 ? img.height : null}
              onLoad={handleImageLoad}
              // ref={imgRef}
              src={facilityImage}
            />
          ) : null}

          {/* Im not sure why. But if we move the children below the parent points the Y-axis gets all Foobar */}

          {/* Chilren */}

          {/* Draggable was taking the default height value before the image size could be
                determined. Which is why we have this !==0 catch. We also do want want to 
                display any of the radios if either the zeroPoint or Far/Endpoint are not set
            */}

          {img.height !== 0 &&
          points.zeroPoint.disablePlunger &&
          points.farPoint.disablePlunger
            ? facilityImageState?.map((item, index) => {
                const { mountPoints = [] } = item;
                return mountPoints.map((point, index) => {
                  const { mountPointId, xyz = {} } = point;
                  const { x = 0, y = 0 } = xyz;
                  return (
                    <Draggable
                      bounds="parent"
                      defaultPosition={{
                        x: x * lengthValue + zeroPoint.x,
                        // y: y * heightValue + zeroPoint.y,
                        y: getY(y),
                      }}
                      disabled
                      key={mountPointId}
                    >
                      <div
                        className="pointer"
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            selectedMountPointId: point.mountPointId,
                            selectedNode: item,
                          }));
                          setModalState({
                            modalShow: true,
                            title: "Mount Point Information",
                          });
                        }}
                        style={{
                          color: "red",
                          width: "1px",
                          position: "absolute",
                        }}
                      >
                        <i
                          className={`fas fa-broadcast-tower ${classes.radioText}`}
                        />
                      </div>
                    </Draggable>
                  );
                });
              })
            : null}

          {/* Zones */}
          {img.height !== 0 &&
          points.zeroPoint.disablePlunger &&
          points.farPoint.disablePlunger &&
          showZones
            ? Object.keys(facilityZoneHashMap).map((zone, index) => {
                const {
                  count = 0,
                  name = "",
                  opacity = 0.5,
                  radius = 0,
                  xyz = null,
                  zoneId = "",
                  zoneType = "processing",
                  color = zoneType === "processing" ? "red" : "#947447",
                } = facilityZoneHashMap[zone];
                const { x, y } = xyz || {};

                // No need to render a zone if there is no xyz coords
                if (xyz) {
                  return (
                    <Draggable
                      bounds="parent"
                      defaultPosition={{
                        x: x * lengthValue + zeroPoint.x,
                        // y: y * heightValue + zeroPoint.y,
                        y: getY(y),
                      }}
                      disabled
                      key={zoneId}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          border: `1px solid ${color}`,
                          display: "flex",
                          height: "10px",
                          justifyContent: "center",
                          opacity,
                          position: "absolute",
                          width: "10px",
                          // We want target zones to sit ontop of processing zones
                          zIndex: `${zoneType === "processing" ? 1 : 2}}`,
                        }}
                      >
                        <ZoneRadius
                          backgroundColor={color}
                          label={`${name} ${
                            count !== 0 ? "Search Results:" + count : ""
                          }`}
                          size="100px"
                          style={{ position: "absolute" }}
                          radiusX={radius * lengthValue}
                          radiusY={radius * heightValue}
                        />
                      </Box>
                    </Draggable>
                  );
                }
              })
            : null}

          {/* Radios */}
          {img.height !== 0 &&
          points.zeroPoint.disablePlunger &&
          points.farPoint.disablePlunger &&
          showRadios
            ? Object.keys(radioHashMap).map((radio, index) => {
                const {
                  color = "black",
                  radioMac = "",
                  xyz = null,
                  radioId = "",
                } = radioHashMap[radio];
                const { x, y } = xyz || {};

                // No need to render a zone if there is no xyz coords
                if (xyz) {
                  return (
                    <Draggable
                      bounds="parent"
                      defaultPosition={{
                        x: x * lengthValue + zeroPoint.x,
                        // y: y * heightValue + zeroPoint.y,
                        y: getY(y),
                      }}
                      disabled
                      key={radioId}
                    >
                      <Tooltip title={radioMac}>
                        <Box
                          sx={{
                            alignItems: "center",
                            border: "1px solid black",
                            backgroundColor: "black",
                            display: "flex",
                            height: "8px",
                            justifyContent: "center",
                            position: "absolute",
                            width: "8px",
                            zIndex: 3,
                          }}
                        />
                      </Tooltip>
                    </Draggable>
                  );
                }
              })
            : null}

          {/* Assets */}
          {img.height !== 0 &&
          points.zeroPoint.disablePlunger &&
          points.farPoint.disablePlunger &&
          showAssets
            ? //iterate over assets in state
              assets.map((asset, index) => {
                const {
                  color = "green",
                  assetId = "",
                  tag = "",
                  xyz = null,
                } = asset;
                const { x, y } = xyz || {};

                // No need to render an asset if there is no xyz coords
                if (xyz) {
                  return (
                    <Draggable
                      bounds="parent"
                      defaultPosition={{
                        x: x * lengthValue + zeroPoint.x,
                        // y: y * heightValue + zeroPoint.y,
                        y: getY(y),
                      }}
                      disabled
                      key={assetId}
                    >
                      <Tooltip title={tag}>
                        <Box
                          sx={{
                            //square styling
                            // alignItems: "center",
                            // border: `1px solid ${color}`,
                            // backgroundColor: color,
                            // display: "flex",
                            // height: "8px",
                            // justifyContent: "center",
                            // position: "absolute",
                            // width: "8px",
                            // zIndex: 3,

                            //triangle styling
                            color: color,
                            position: "absolute",
                            width: "0",
                            height: "0",
                            borderLeft: "6px solid transparent",
                            borderRight: "6px solid transparent",
                            borderBottom: `8px solid ${color}`,
                            cursor: "move",
                            zIndex: 3,
                          }}
                        />
                      </Tooltip>
                    </Draggable>
                  );
                }
              })
            : null}

          {/* Start Point */}
          {points.zeroPoint.hasLoaded && !zeroPoint?.disablePlunger ? (
            <Draggable
              bounds="parent"
              defaultPosition={{
                x: points.zeroPoint.x,
                y: points.zeroPoint.y,
              }}
              disabled={points.zeroPoint.disablePlunger}
              onStop={(other, data) => {
                setState((prevState) => ({
                  ...prevState,
                  zeroPoint: {
                    ...prevState.zeroPoint,
                    x: data.x,
                    y: data.y,
                  },
                }));
              }}
            >
              {/* circle with x */}
              <div
                className="box pointer"
                style={{
                  alignItems: "center",
                  backgroundColor: "green",
                  borderRadius: "50%" /* Makes the div circular */,
                  color: "white",
                  display: "flex",
                  fontSize: "24px",
                  height: "16px",
                  justifyContent: "center",
                  width: "16px",
                }}
              >
                &times;
              </div>
            </Draggable>
          ) : null}

          {/* End Point */}
          {points.farPoint.hasLoaded && !farPoint?.disablePlunger ? (
            <Draggable
              bounds="parent"
              defaultPosition={{
                x: points.farPoint.x,
                y: points.farPoint.y,
              }}
              disabled={points.farPoint.disablePlunger}
              onStop={(other, data) => {
                setState((prevState) => ({
                  ...prevState,
                  farPoint: { ...prevState.farPoint, x: data.x, y: data.y },
                }));
              }}
            >
              <div
                className="box pointer"
                style={{ color: "red", position: "absolute", width: "1px" }}
              >
                <i className="fas fa-bullseye" />
              </div>
            </Draggable>
          ) : null}
        </div>
      </MapInteractionCSS>
    </Grid>
  );
}
