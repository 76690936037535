import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { checkSession } from "../globalStore/slices/user/userSlice";
import { init } from "../globalStore/slices/organization/organizationSlice";
import { initAssetHistory } from "../globalStore/slices/assetHistory/thunks/init";
import { initAssets } from "../globalStore/slices/assets/thunks/init";
import { initDevices } from "../globalStore/slices/devices/devicesSlice";
import { initInventoryHistory } from "../globalStore/slices/inventoryHistory/thunks/init";
import { initSettings } from "../globalStore/slices/settings/thunks/init";
import { LicenseInfo } from "@mui/x-license-pro";
import { Grow } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { theme } from "../styles/MuiTheme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import AuthContainer from "./auth";
import lxlogobluegreen from "./../assets/lxlogobluegreen.png";
import NoAuthContainer from "./noAuth";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    maxWidth: "300px",
  },
}));

export default function App() {
  const classes = useStyles();
  const [auth, setAuth] = useState({
    isInitializing: true,
    isAuthed: false,
  });

  const dispatch = useDispatch();

  // Setting the licence key for MaterialUi
  LicenseInfo.setLicenseKey(
    "4c348c947cbab5a346354780d4d2933eTz00OTM4NSxFPTE2OTI0NzM0MDU5MjQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  

  // first render, check session and update store with user data, then dispatch init thunk to organization slice and update store with organization data
  useEffect(() => {
    dispatch(checkSession(localStorage.getItem("token"))).then((res) => {
      if (res.payload?.success) {
        dispatch(init()).then((res) => {
          // dispatching other initializors for our store.
          dispatch(initDevices());
          dispatch(initAssets());
          dispatch(initAssetHistory());
          dispatch(initInventoryHistory());
          dispatch(initSettings());

          // after one second, ending intialization sequence and reporting whether the user's session is valid (isAuthed)
          setTimeout(() => {
            setAuth({
              isInitializing: false,
              isAuthed: res?.payload?.success || false,
            });
          }, 1000);
        });
      } else {
        setTimeout(() => {
          setAuth({ isInitializing: false, isAuthed: false });
        }, 1000);
      }
    });
  }, [dispatch]);

  const { isInitializing, isAuthed } = auth;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {isInitializing ? (
          <div className={classes.container}>
            <Grow
              in={isInitializing}
              style={{ transformOrigin: "0 0 0 0" }}
              timeout={1000}
            >
              <img src={lxlogobluegreen} className={classes.icon} alt="LocatorX launch logo" />
            </Grow>
          </div>
        ) : (
          <Router>
            {isAuthed ? (
              <>
                <AuthContainer setAuth={setAuth} />
              </>
            ) : (
              <NoAuthContainer setAuth={setAuth} />
            )}
          </Router>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
