import { useState, useEffect } from "react";
import {
  createQrBackgroundSessions,
  createTemplate,
  deleteQrBackgroundSessions,
  deleteTemplate,
} from "./api";
import { FontSelect } from "../../components/Selects/Font Select/FontSelect";
import ConfirmationDialog from "./ConfirmationDialog";
import DeleteTemplateDialog from "./DeleteTemplateDialog";
import FontSize from "../../components/ReusedComponents/FontSize";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import Select from "../../components/Forms/FieldTypes/Select";
import SelectionTool from "./SelectionTool";
import TextFormatButtonGroup from "../../components/Buttons/TextFormatButtonGroup";

export default function CQRTemplate(props) {

  const classes = {
    formControl: {
      minWidth: 120,
      width: "100%",
    },
    optionItem: {
      display: "flex",
      justifyContent: "space-between",
    },
    root: {
      padding: ".5rem 1rem",
    },
    textField: {
      width: "100%",
    },
  }

  const [cqrTemplateOptions, setCQRTemplateOptions] = useState([]);
  const {
    aboveData,
    aboveImages,
    batchAsset,
    belowData,
    belowImages,
    changePage,
    consoleRole,
    cqrLayoutName,
    cqrTemplates,
    dataElementAbove,
    dataElementBelow,
    font,
    fontSize,
    modalShow,
    product,
    qrBackgroundSession,
    retrieveQrBackgrounds,
    selectCQRLayout,
    setModalShow,
    setQrBackgroundSession,
    setState,
    templateSize,
    textFormats,
  } = props;
  const [deleteTemplateModalShow, setDeleteTemplateModalShow] = useState(false);

  // Previously we had the logic for deleting CQR templates here. But we need to have to have a 
  // discussion on where we want to put this. Should it be here? Or should it be on the settings page?
  // With everything else.
  const [cqrTemplateToDelete, setCqrTemplateToDelete] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    content: "",
    severity: "",
    title: "",
  });
  const aboveAndBelowSelectOptions = batchAsset
    ? [
        { label: "None", value: "none" },
        {
          label: "Item Level Data",
          value: "item",
        },
        {
          label: "Product Level Data",
          value: "product",
        },
        { label: "Batch Level Data", value: "batch" },
        { label: "Upload Image", value: "images" },
        {
          label: "User Defined Text",
          value: "userDefinedText",
        },
      ]
    : [
        { label: "None", value: "none" },
        { label: "Asset Tag", value: "assetTag" },
        { label: "Description", value: "description" },
        { label: "Category", value: "category" },
        { label: "Asset Details", value: "assetDetails" },
        { label: "Upload Image", value: "images" },
        {
          label: "User Defined Text",
          value: "userDefinedText",
        },
      ];

  useEffect(() => {
    const templateOptions = [
      { label: "Select", value: "", edit: false, isDisabled: true },
      { label: "Add New", value: "addNew", edit: false },
      { label: "None", value: "none", edit: false },
    ];

    cqrTemplates.forEach((template) => {
      const { propertiesMap = {} } = template;
      const { templateValues = {} } = propertiesMap;

      // We sort out what templtes are available to the customer depending on their user role
      // That way product users do not see Templates reserved for Assets
      if (consoleRole === templateValues.consoleRole) {
        templateOptions.push({
          label: template.name,
          value: template.qrBackgroundId,
          edit: true,
        });
      }
    });

    setCQRTemplateOptions(templateOptions);
  }, [consoleRole, cqrTemplates, setCQRTemplateOptions]);

  useEffect(() => {
    // In the case a user navigates to a new page, we clean up the session
    return props.history.listen(() => {
      if (
        qrBackgroundSession.qrBackgroundSessionId &&
        qrBackgroundSession.qrBackgroundSessionId.length > 0
      ) {
        deleteQrBackgroundSessions(
          { ...props },
          qrBackgroundSession.qrBackgroundSessionId
        ).then(() => {});
      }
    });
  });


  const templateSizeOptions = [
    { label: "Small (250Px X 250Px)", value: 250 },
    { label: "Medium (350Px X 350Px)", value: 350 },
    { label: "Large (550Px X 550Px)", value: 550 },
  ];

  return (
    <>
      {/* Dialog to delete a template */}
      <DeleteTemplateDialog
        template={cqrTemplateToDelete}
        modalShow={() => {
          setDeleteTemplateModalShow((prevState) => !prevState);
        }}
        onSubmit={(templateToDelete) => {
          deleteTemplate({ ...props }, templateToDelete).then(() => {
            // Close the delete modal
            setDeleteTemplateModalShow(false);

            // Refreshes the "Select CQR Layout" select items
            setCQRTemplateOptions((prevState) => {
              const newArray = prevState.filter((template) => {
                return template.value !== templateToDelete;
              });
              return newArray;
            });

            // Resets the template
            setState((prevState) => {
              return {
                ...prevState,
                aboveData: "",
                belowData: "",
                dataElementAbove: "",
                dataElementBelow: "",
                font: "",
                fontSize: "10",
                selectCQRLayout:
                  templateToDelete === selectCQRLayout.value
                    ? { label: "Add New", value: "addNew" }
                    : prevState.selectCQRLayout,
                textFormats: [],
              };
            });
          });
          setConfirmationModal({
            content: "Template Delete",
            show: true,
            severity: "success",
          });
        }}
        onCancel={() => {
          setDeleteTemplateModalShow(false);
        }}
        open={deleteTemplateModalShow}
        setState={setState}
      />

      {/* Here to handle error or success */}
      <MaterialConfirmationModal
        closeModal={() => {
          setConfirmationModal((prevState) => ({ ...prevState, show: false }));
        }}
        content={confirmationModal.content}
        modalOpen={confirmationModal.show}
        severity={confirmationModal.severity}
        title={confirmationModal.title}
      />

      {/* Confirmation Dialog for Creating a template */}
      <ConfirmationDialog
        changePage={changePage}
        cqrLayoutName={cqrLayoutName}
        modalShow={() => {
          setModalShow((prevState) => !prevState);
        }}
        onChange={(value) => {
          setState((prevState) => ({
            ...prevState,
            cqrLayoutName: { value: value, error: false },
          }));
        }}
        onSubmit={() => {
          let duplicateTemplateNameExist = false;
          const specialCharacters = [
            "!",
            "$",
            "&",
            "+",
            ",",
            "/",
            "@",
            ";",
            "?",
            ":",
            "=",
            "#",
          ];

          cqrTemplates.forEach((template) => {
            if (
              template.name.toLowerCase() === cqrLayoutName.value.toLowerCase()
            ) {
              duplicateTemplateNameExist = true;
            }
          });

          // We verify that there is not a duplicate name
          if (duplicateTemplateNameExist) {
            setConfirmationModal({
              show: true,
              content: "Please choose another template name.",
              severity: "error",
              title: "Duplicate Template Names",
            });
          } else if (
            cqrLayoutName.value
              .split("")
              .some((el) => specialCharacters.indexOf(el) >= 0)
          ) {
            //TODO: Jeff should fix a bug on the backend that allows template previews to take
            // special characters. When this happens we need to remove this logic.
            setConfirmationModal({
              show: true,
              content: "Please choose another CQR Layout name.",
              severity: "error",
              title: `CQR Layout names cannot contain the following characters: ${specialCharacters.join(
                " "
              )}.`,
            });
          } else {
            setModalShow(false);
            createTemplate({
              ...props,
              cqrLayoutName: { value: cqrLayoutName.value },
            }).then((response) => {
              const { name, qrBackgroundId } = response;
              // Once we create the template we then turn it into the selected template, incase the
              // user clicks the back button
              setState((prevState) => ({
                ...prevState,
                selectCQRLayout: {
                  label: name,
                  value: qrBackgroundId,
                  edit: true,
                },
              }));

              // Modal appears stating everything worked
              setConfirmationModal({
                content: "Template Created",
                show: true,
                severity: "success",
              });

              // Kill the template and refresh to QRBackground options
              setTimeout(() => {
                setConfirmationModal({
                  content: "",
                  show: false,
                  severity: "success",
                });
                retrieveQrBackgrounds();
              }, 500);

              // Switch to the next page
              setTimeout(() => {
                changePage();
              }, 600);
            });
          }
        }}
        open={modalShow}
        setState={setState}
      />

      <Grid container>
        <Grid sx={classes.root} item xs={6}>
            <Select
              label="Select CQR Layout"
              onChange={(event) => {
                const userSelectedTemplate = cqrTemplateOptions.find(
                  (item) => item.value === event.target.value
                );

                if (userSelectedTemplate?.value === "none") {
                  // Set the values back to default
                  setState((prevState) => ({
                    ...prevState,
                    aboveData: "",
                    belowData: "",
                    dataElementAbove: { label: null, value: null },
                    dataElementBelow: { label: null, value: null },
                    font: "",
                    fontSize: "10",
                    textFormats: [],
                  }));

                  // If there was a qrBackgroundSession active, we terminate the session
                  // and restore the qrBackgroundSession to its default state.
                  if (qrBackgroundSession.qrBackgroundSessionId.length > 0) {
                    deleteQrBackgroundSessions(
                      { ...props },
                      qrBackgroundSession.qrBackgroundSessionId
                    ).then(() => {
                      setQrBackgroundSession({
                        qrBackgroundSessionId: "",
                        image: "",
                      });
                    });
                  }
                } else if (userSelectedTemplate?.value === "addNew") {
                  // Set the values back to default
                  setState((prevState) => ({
                    ...prevState,
                    aboveData: "",
                    belowData: "",
                    dataElementAbove: { label: null, value: null },
                    dataElementBelow: { label: null, value: null },
                    font: "",
                    fontSize: "10",
                    textFormats: [],
                  }));

                  // Create a new session instance
                  createQrBackgroundSessions({ ...props }).then((response) => {
                    setQrBackgroundSession({
                      image: "",
                      qrBackgroundSessionId:
                        response.qrBackground.qrBackgroundId,
                    });
                  });
                } else {
                  const selectedTemplate = cqrTemplates.find(
                    (template) => template.name === userSelectedTemplate.label
                  );
                  // Set the values to whatever template is selected
                  setState((prevState) => ({
                    ...prevState,
                    ...selectedTemplate?.propertiesMap?.templateValues,
                    images: selectedTemplate?.images,
                  }));

                  // If there was a qrBackgroundSession active, we terminate the session
                  // and restore the qrBackgroundSession to its default state.
                  if (qrBackgroundSession.qrBackgroundSessionId.length > 0) {
                    deleteQrBackgroundSessions(
                      { ...props },
                      qrBackgroundSession.qrBackgroundSessionId
                    ).then(() => {
                      setQrBackgroundSession({
                        qrBackgroundSessionId: "",
                        image: "",
                      });
                    });
                  }
                }
                setState((prevState) => ({
                  ...prevState,
                  selectCQRLayout: userSelectedTemplate,
                }));
              }}
              options={cqrTemplateOptions}
              value={selectCQRLayout?.value}
              variant="outlined"
            />
        </Grid>

        {/* Template Size */}
        {selectCQRLayout.label === "Select" ||
        selectCQRLayout.label === "None" ? null : (
          <Grid sx={classes.root} item xs={6}>
            <Select
              disabled={selectCQRLayout.value !== "addNew"}
              label="Template Size"
              onChange={(event) => {
                const selectedTemplateSize = templateSizeOptions.find(
                  (item) => item.value === event.target.value
                );

                setState((prevState) => ({
                  ...prevState,
                  templateSize: selectedTemplateSize,
                }));
              }}
              options={templateSizeOptions}
              value={templateSize.value}
              variant="outlined"
            />
          </Grid>
        )}
      </Grid>

      {selectCQRLayout.label === "Select" ||
      selectCQRLayout.label === "None" ? null : (
        <Grid container>
          {/* Add Data Element Above CQR */}
          <Grid sx={classes.root} item xs={6}>
              <Select
                color="submit"
                disabled={selectCQRLayout.value !== "addNew"}
                label="Add Data Element Above CQR"
                onChange={(event) => {
                  const selectedOption = aboveAndBelowSelectOptions.find(
                    (item) => item.value === event.target.value
                  );

                  setState((prevState) => {
                    const body = {
                      ...prevState,
                      aboveData: { label: null, value: null },
                      dataElementAbove: selectedOption,
                    };

                    // This is here to clear out any images the user might of uploaded to the session
                    // and update the session image. "They add a image and then select another Data Element"
                    if (
                      selectedOption?.value !== "images" &&
                      aboveImages.length > 0
                    ) {
                      body.aboveImages = [];
                    }

                    return body;
                  });
                }}
                options={aboveAndBelowSelectOptions}
                value={dataElementAbove?.value}
                variant="outlined"
              />
          </Grid>

          {/* Add Data Element Below CQR */}
          <Grid sx={classes.root} item xs={6}>
              <Select
                color="submit"
                disabled={selectCQRLayout.value !== "addNew"}
                label="Add Data Element Below CQR"
                onChange={(event) => {
                  const selectedOption = aboveAndBelowSelectOptions.find(
                    (item) => item.value === event.target.value
                  );

                  setState((prevState) => {
                    const body = {
                      ...prevState,
                      belowData: { label: null, value: null },
                      dataElementBelow: selectedOption,
                    };

                    // This is here to clear out any images the user might of uploaded to the session
                    // and update the session image. "They add a image and then select another Data Element"
                    if (selectedOption?.value !== "images" && belowImages.length > 0) {
                      body.belowImages = [];
                    }

                    return body;
                  });
                }}
                options={aboveAndBelowSelectOptions}
                value={dataElementBelow.value}
                variant="outlined"
              />
          </Grid>
        </Grid>
      )}

      {/* Build CQR Template */}
      {selectCQRLayout.label === "Select" ||
      selectCQRLayout.label === "None" ? null : (
        <Grid container>
          {/* Above Data */}
          <Grid sx={classes.root} item xs={6}>
            <SelectionTool
              {...props}
              disabled={selectCQRLayout.value !== "addNew"}
              menuPortalTarget={document.querySelector("main")}
              menuPosition={"fixed"}
              onChange={(value) => {
                setState((prevState) => ({
                  ...prevState,
                  aboveData: value,
                }));
              }}
              position="above"
              product={product}
              setState={setState}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              type={dataElementAbove.value}
              value={aboveData}
            />
          </Grid>

          {/* Below Data */}
          <Grid sx={classes.root} item xs={6}>
            <SelectionTool
              {...props}
              disabled={selectCQRLayout.value !== "addNew"}
              menuPortalTarget={document.querySelector("main")}
              menuPosition={"fixed"}
              onChange={(value) => {
                setState((prevState) => ({
                  ...prevState,
                  belowData: value,
                }));
              }}
              position="below"
              product={product}
              setState={setState}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              type={dataElementBelow.value}
              value={belowData}
            />
          </Grid>

          {/* Font Select */}
          <Grid sx={classes.root} item xs={6}>
            <FontSelect
              isDisabled={selectCQRLayout.value !== "addNew"}
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  font: event.target.value,
                }));
              }}
              useCQRFonts={true}
              value={font}
              variant="outlined"
            />
          </Grid>

          {/* Font options */}
          <Grid sx={classes.root} container>
            <Grid item xs={6}>
              <Grid container>
                {/* Font Size */}
                <Grid item xs={6}>
                  <FontSize
                    disabled={selectCQRLayout.value !== "addNew"}
                    onChange={(event) => {
                      setState((prevState) => ({
                        ...prevState,
                        fontSize: event.target ? event.target.value : "",
                      }));
                    }}
                    value={fontSize}
                    variant="outlined"
                  />
                </Grid>

                {/* Bold Italic Underlined  */}
                <Grid item xs={6}>
                  <TextFormatButtonGroup
                    disabled={selectCQRLayout.value !== "addNew"}
                    onChange={(values) =>
                      setState((prevState) => ({
                        ...prevState,
                        textFormats: values,
                      }))
                    }
                    textFormats={textFormats}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
