import { formatLatLon } from "../../../../utils/lat_lon_utils";
import { isEmpty } from "lodash";
import { parseUserInfo } from "../utils";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useTranslation as UseTranslation } from "react-i18next";
import modifyColumns from "../../../../utils/modifyColumns";

export default function columns(props) {
  const {
    appUserType,
    classifications,
    columnOrder,
    customs,
    facilities = {},
    history,
    parentAssetIdTagMap = {},
    timeZone,
    zones = {},
  } = props;

  const { t } = UseTranslation();

  const showPCA = ![
    "Asset-Operations",
    "Asset",
    "Warehouse-Operations",
  ].includes(appUserType);

  const classificationColumns = !isEmpty(classifications)
    ? Object.keys(classifications).map((classKey, index) => {
        const { name } = classifications[classKey];
        return {
          field: name,
          headerClassName: "datagrid-header",
          headerName: name,
          id: name,
          renderCell: (data) => {
            const { classificationMap = {} } = data.row;

            const classificationValue = Object.keys(classificationMap).filter(
              (k) => k === name
            )[0];

            return (
              <div
                className="pointer"
                key={name}
                onClick={() => onClick(data.row)}
              >
                {classificationMap[classificationValue] || t("dnpinput1")}
              </div>
            );
          },
          valueGetter: (data) => {
            const row = data.row;
            if (row.classificationMap) {
              const classificationValue = row.classificationMap[classKey] || "";
              return classificationValue;
            }
            return "";
          },
        };
      })
    : [];

  const customColumns = !isEmpty(customs)
    ? customs.map((item) => {
        const { name } = item;
        return {
          field: name,
          headerClassName: "datagrid-header",
          headerName: name,
          id: name,
          renderCell: (data) => {
            const { customMap = {} } = data.row;

            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];

            return (
              <div
                className="pointer"
                key={name}
                onClick={() => onClick(data.row)}
              >
                {customMap[customValue] || t("dnpinput1")}
              </div>
            );
          },
          valueGetter: (data) => {
            const row = data.row;
            const { customMap = {} } = row;
            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];
            const ans = customMap[customValue] || "";
            return ans;
            // }
          },
        };
      })
    : [];

  const historyTableColumns = [
    // timeOfLog
    {
      field: "timeOfLog",
      headerClassName: "datagrid-header",
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { timeOfLogLong } = data.row;

        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {timeOfLogLong
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (data) => {
        const timeOfLogLong = data.row.timeOfLogLong;
        return timeOfLogLong
          ? new Date(timeOfLogLong)
          : //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
            new Date(8640000000000000);
      },
    },
    // tag
    {
      field: "tag",
      headerClassName: "datagrid-header",
      headerName: "Inventory Tag",
      id: "tag",
      renderCell: (data) => {
        const { tag } = data.row;
        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {tag ? tag : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { tag } = data.row;
        return tag ? tag : t("dnpinput1");
      },
    },
    // category
    {
      field: "category",
      headerClassName: "datagrid-header",
      headerName: "Inventory Category",
      id: "category",
      renderCell: (data) => {
        const { category } = data.row;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {category ? category : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) =>
        data.row.category ? data.row.category : t("dnpinput1"),
    },
    // description
    {
      field: "description",
      headerClassName: "datagrid-header",
      headerName: "Description",
      id: "description",
      renderCell: (data) => {
        const { propertiesMap = {} } = data.row;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row)}
          >
            {propertiesMap?.description || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => data.row.propertiesMap?.description, // required for nested values
    },
    // lastEvent
    {
      field: "lastEvent",
      headerClassName: "datagrid-header",
      headerName: "Last Event",
      id: "lastEvent",
      renderCell: (data) => {
        const { lastEvent } = data.row;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row)}
          >
            {lastEvent ? lastEvent : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { lastEvent } = data.row;
        return lastEvent ? lastEvent : t("dnpinput1");
      }
    },
    // user
    {
      field: "user",
      headerClassName: "datagrid-header",
      headerName: "User",
      id: "user",
      renderCell: (data) => {
        const row = data.row;
        const userInfo = parseUserInfo(row);
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row)}
          >
            {userInfo || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => parseUserInfo(data.row), // required for nested values
    },
    // facility
    {
      field: "facility",
      headerClassName: "datagrid-header",
      headerName: "Facility Name",
      id: "facility",
      renderCell: (data) => {
        const { facility, facilityId, latitude, longitude } = data.row;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {facility
              ? facility
              : !latitude || !longitude
              ? t("dnpinput1")
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered"}
          </div>
        );
      },
      valueGetter: (data) => {
        const { row = {} } = data;
        const { facilityId = "", latitude = "", longitude = "" } = row;
        return !latitude || !longitude
          ? t("dnpinput1")
          : facilityId && facilities[facilityId] && facilities[facilityId]?.name
          ? facilities[facilityId]?.name
          : "Not Registered";
      },
    },
     // latLong
     {
      field: "latLong",
      headerName: "Latitude, Longitude",
      id: "latLong",
      renderCell: (data) => {
        const { latitude, longitude, propertiesMap } = data.row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return (
          <div
            onClick={() => onClick(data.row)}
            key={data.row.assetId}
          >
            {formattedLatLong}
          </div>
        );
      },
      valueGetter: (data) => {
        const { latitude, longitude } = data.row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return formattedLatLong;
      },
    },
    // zone
    {
      field: "zone",
      headerClassName: "datagrid-header",
      headerName: "Zone",
      id: "zone",
      renderCell: (data) => {
        const { zone = {} } = data.row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;

        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {/* Show pZoneName. If not present show pZoneName. If not present the Zone Name */}
            {pZoneName
              ? pZoneName
              : zoneName
              ? zoneName
              : zoneId && zones[zoneId]
              ? zones[zoneId].name
              : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { zone = {} } = data.row;
        const { zoneName = "", pZoneName = "", zoneId = "" } = zone;

        return zoneName
          ? zoneName
          : pZoneName
          ? pZoneName
          : zoneId && zones[zoneId]
          ? zones[zoneId].name
          : t("dnpinput1");
      },
    },
    // binLocation
    {
      field: "binLocation",
      headerClassName: "datagrid-header",
      headerName: "Bin Location",
      id: "binLocation",
      renderCell: (data) => {
        const { zone = {} } = data.row;
        const { binLocation = "" } = zone;
        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              data.row.zone
                ? binLocation
                : data.row.binLocation
                ? data.row.binLocation
                : data.row.propertiesMap && data.row.propertiesMap.binLocation
                ? data.row.propertiesMap.binLocation
                : t("dnpinput1")
            }
          </div>
        );
      },
      valueGetter: (data) => {
        const { zone = {} } = data.row;
        const { binLocation = "" } = zone;
        return data.row.zone
        ? binLocation
        : data.row.binLocation
        ? data.row.binLocation
        : data.row.propertiesMap && data.row.propertiesMap.binLocation
        ? data.row.propertiesMap.binLocation
        : t("dnpinput1");
      }
    },
    // parentId
    {
      field: "parentId",
      headerClassName: "datagrid-header",
      headerName: "Parent Asset Tag",
      id: "parentId",
      renderCell: (data) => {
        const row = data.row || {};

        const parent = row.ancestors ? row.ancestors[0] : null;
        const parentName = parent ? parentAssetIdTagMap[parent] : "";

        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick({ assetId: parent })}
          >
            {parentName}
          </div>
        );
      },
      valueGetter: (data) => {
        const row = data.row || {};

        if (!row.ancestors) {
          return "";
        } else {
          // immediate parent is the first item in ancestors set, the child item's parent node in the hierarchy tree
           const parent = row.ancestors ? row.ancestors[0] : null;
          const parentName = parent ? parentAssetIdTagMap[parent] : "";
          
          return parentName;
        }
      },
    },
    // quantityCurrent
    {
      field: "quantityCurrent",
      headerClassName: "datagrid-header",
      headerName: "Qty",
      id: "quantityCurrent",
      renderCell: (data) => {
        const row = data.row;
        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {row.quantity?.quantityCurrent || t("dnpinput1")}
          </div>
        );
      },
      type: "number",
      valueGetter: (data) => {
        if (data.row.quantity?.quantityCurrent) {
          return parseInt(data.row.quantity.quantityCurrent);
        } else {
          return Number.NEGATIVE_INFINITY;
        }
      },
    },
  ]
    // We check to see if the users type needs the PCA field.
    .filter((col) => {
      if (!showPCA) {
        return col.id !== "flagged";
      } else {
        return true;
      }
    })
    .concat(classificationColumns)
    .filter((col) => {
      // get rid of event specific comments
      if (col.field.includes("Comments") && col.field !== "Comments") {
        return false;
      } else {
        return true;
      }
    })
    .concat(customColumns);

  const onClick = (row) => {
    const { assetId = "" } = row;
    if (assetId) {
      const url = `/inventorySnapshot/${assetId}`;
      history.push(url);
    }
  };

  // Here we need to go through the columnOrder and handle Sorting / Renaming / Visibility settings
  if (columnOrder) {
    return modifyColumns({ columnOrder, historyTableColumns });
  }

  return { historyTableColumns };
}
