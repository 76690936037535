import { useState } from "react";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function UserBadge() {

  const classes = {
    avatar: {
      cursor: "pointer",
      color: "#000000de !important",
      backgroundColor: "#FFFFFF !important",
      height: "45px",
      width: "45px"
    },
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const firstInitial = localStorage
    .getItem("firstName")
    ?.charAt(0)
    .toUpperCase();
  const lastInitial = localStorage.getItem("lastName")?.charAt(0).toUpperCase();
  const initials =
    firstInitial && lastInitial ? `${firstInitial}${lastInitial}` : null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Avatar
        onClick={(e) => handleClick(e)}
        sx={classes.avatar}
      >
        {initials}
      </Avatar>
      <Menu
        id="simple-menu"
        autoFocus={false}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            history.push("/logout");
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
