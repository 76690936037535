import  React, {useState} from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useStyles } from "./styles";

export default function CollapsableListItem(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles({});

  const { item = {} } = props;
  const { children = [] } = item;

  return (
    <React.Fragment key={item.name}>
      <ListItem
        classes={{
          root: classes.listItem,
        }}
        className={anchorEl ? classes.listItemActive : classes.listItem}
        button
        key={`${item.name}`}
        onClick={(e) => handleClick(e)}
      >
        <div>
          <ListItemIcon
            className={`fas ${item.icon} fa-2x`}
            classes={{ root: classes.listItemIcon }}
          />
          <ListItemText
            classes={{
              primary: classes.listItemText,
            }}
            primary={item.label}
          />
        </div>
      </ListItem>
      <Menu
        // try list: classes.menuList
        classes={{ paper: classes.menuPaper }}
        elevation={0}
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children.map((child, idx) => (
          <MenuItem
            key={`${child.name} - ${idx}`}
            className={classes.menuItem}
            onClick={() => {
              if (props.onClick) {
                if (child.hyperlink) {
                  props.onClick(child.hyperlink, true);
                  handleClose();
                } else {
                  props.onClick(child.name);
                  handleClose();
                }
              }
            }}
          >
            {child.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
