import { naturalSort } from "../../utils/naturalSort";
import AutocompleteSelect from "../../components/Forms/FieldTypes/AutocompleteSelect";

export default function ItemLevelDataElementSelect(props) {
  const {
    itemLevelDataElements,
    readOnly,
    selectedItemLevelDataElements,
    setState,
  } = props;
  const itemLevelDataElementOptions = () => {
    let options = [];
    itemLevelDataElements.forEach((element) => {
      const { dataType, id, label } = element;
      options.push({ dataType, id, label, value: id });
    });
    return options;
  };

  return (
    <AutocompleteSelect
      inputProps={{ "data-cypress-id": "products-product-mdl-select-item-lde" }}
      disabled={readOnly}
      label="Item Level Data Elements"
      getOptionLabel={(option) => option.label}
      multiple={true}
      onChange={(event, value) => {
        setState((prevState) => ({
          ...prevState,
          selectedItemLevelDataElements: value,
        }));
      }}
      options={itemLevelDataElementOptions().sort((a, b) =>
        naturalSort(a.label, b.label)
      )}
      value={selectedItemLevelDataElements}
    />
  );
}
