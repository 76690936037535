import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import { Bar, getElementAtEvent } from 'react-chartjs-2'
import { isValidColor, toMMDDYYYYFormat } from '../../../globalStore/utils/helpers';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux'

function DailyEventsBar(props) {

  const allEventTypesMap = useSelector((state) => state.organization.organization.allEventTypesMap)

  const classes = {
    labels: {
      display: "flex",
      fontSize: "12px",
      paddingLeft: "2.2%",
      marginTop: "8px",
      marginBottom: "25px",
      width: "100%",
    },
    label: {
      //since there'll always be 28 labels, divide 100 by 28 to get the width of each label.
      //this will make the labels evenly spaced across the chart
      //the 2.2% is to account for the  y axis labels space on the left side of the chart
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      width: 100/28 + "%",
      //rotate label 45 degrees counter-clockwise
      transform: "rotate(-45deg)",
    }
  }
    
  const { eventsGridRef, selectedBar, setSelectedBar, barDatasets, last28Days } = props
    const barRef = useRef()

    // const classes = useStyles()

    useEffect(() => {
        const barInstance = barRef.current?.chartInstance
    }, [])

      //create color pallete with 45 colors to use for bar chart not including the first 15 colors, grey, white, and black
      const colorPallete = [
        '#3366E6', 'pink', 'orange', '#00B3E6', '#CCFF1A', 'purple', '#999966', '#FF6633', 'green', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#FF1A66', '#E6331A', '#33FFCC', '#FFB399',
        '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D', '#80B300', '#809900',
        '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#FF1A66', '#E6331A', '#33FFCC', '#FFB399', '#FF33FF', '#FFFF99',
        '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC', '#FFB399'
      ]

    const handleBarClick = (event) => {
        const chartInstance = barRef.current.data;
        const activeElement = getElementAtEvent(barRef.current, event)[0];
        if (activeElement) {
          const datasetIndex = activeElement.datasetIndex;
          const dataIndex = activeElement.index;
          const dataValue = chartInstance.datasets[datasetIndex].data[dataIndex];
          const labelValue = chartInstance.labels[dataIndex];
          const formattedLabelValue = new Date(labelValue).toLocaleDateString('en-US', {timeZone: 'UTC'})
          const eventType = chartInstance.datasets[datasetIndex].label
          setSelectedBar({date: toMMDDYYYYFormat(formattedLabelValue), eventType: eventType})
        }
    }

    const handleLabelClick = (e, date) => {
      //if user clicks on label or presses enter or space key, set selected bar date and set focus to events grid
      if(e.type === "click" || (e.type === "keydown" && e.keyCode === 13 || e.keyCode === 32) ) {
        setSelectedBar({ date: date })
        eventsGridRef.current?.focus()
      }
    }
    
    const dataSets = []

    for(let key in barDatasets) {
      dataSets.push({
        label: key,
        data: barDatasets[key]
      })
    }
    
    colorPallete.forEach((color, index) => {
       
      const currDataSet = dataSets[index]
      const currDataSetLabel = currDataSet?.label

      //makes sure the event exists in allEventTypesMap and the color is valid
      if(currDataSet && allEventTypesMap[currDataSetLabel] && isValidColor(allEventTypesMap[currDataSetLabel].color)) {
        currDataSet.backgroundColor = allEventTypesMap[currDataSetLabel].color
      }
      else if(currDataSet) {
        currDataSet.backgroundColor = color
      }
      else return
    })

    return (
      <Grid item xs={12}>
        <Bar
            data={{
                labels: last28Days,
                datasets: dataSets
            }}
            height={40}
            width={300}
            options={{
                plugins: {
                  // make sure tooltip only shows the portion of the stacked bar that is hovered on
                  tooltip: {
                    mode: 'point',
                    intersect: true,
                    },
                  },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                          //default x axis label is being hidden because event listener cant be added to labels
                          display: false,
                          //remove year from label
                          // callback: function(val, index) {
                          //   return last28Days[index].slice(0,-5)
                          // },
                          //if tick label matches selected date, make background color light blue
                          // backdropColor: (context) => {
                          //   if(context.index === selectedDateIndex) {
                          //     return '#7ec2f2'
                          //   }
                          //   else {return "#E6E7E8"}
                          // },
                          // showLabelBackdrop: true,
                        },
                    },
                    y: {
                      stacked: true
                    }
                },
            }}
            onClick={handleBarClick}
            ref={barRef}
        />
        <Typography component="div" role='tablist' sx={classes.labels}>
          {last28Days.map((date, index) => {
            return <Typography component="p"
              aria-label={`${date} events`}
              aria-selected={date === selectedBar.date ? true : false}
              id={date}
              key={date}
              onClick={(e) => handleLabelClick(e, date)}
              onKeyDown={(e) => handleLabelClick(e, date)}
              role='tab'
              style={{ cursor: "pointer", backgroundColor: date === selectedBar.date ? "#7ec2f2" : "#E6E7E8" }}
              tabIndex={0}
              sx={classes.label}
            >
              {date.slice(0, -5)}
            </Typography>
          })}
        </Typography>
    </Grid>
  )
}

export default DailyEventsBar