import _ from "lodash";
import { Box, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import moment from "moment-timezone";
import Paper from "@mui/material/Paper";

function Overview(props) {

  const theme = useTheme();

  const classes = {
    root: {
      flexGrow: 1,
      height: "60vh",
      overflow: "auto",
    },
    snapshot: {
      color: theme.palette.text.secondary,
      maxHeight: "100%",
      minHeight: "30vh",
      overflow: "auto",
      padding: theme.spacing(2),
      textAlign: "left",
    },
  }

  const {
    assetData = {},
    facilities,
    eventTypesMap = {},
    isInventorySnapshot,
  } = props;
  const {
    classificationMap = {},
    customMap,
    facilityId,
    lastEvent = "None provided",
    latitude,
    longitude,
    propertiesMap = {},
    quantity = {},
    tag = "None provided",
    timeCreatedUser,
    timeNeeded,
    zone,
  } = assetData;
  const { description = "None provided", category = "None provided" } =
    propertiesMap;
  const { quantityCurrent } = quantity;

  return (
    <Box sx={classes.root}>
      <Typography component={"h1"} sx={{ textAlign: "left" }} variant="h5">
        {isInventorySnapshot ? "Inventory" : "Asset"} Information
      </Typography>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Paper sx={classes.snapshot} elevation={0}>
            <Grid container spacing={3}>
              {/* Asset Tag */}
              <Grid item xs={12} sm={4} sx={{ wordWrap: "break-word" }}>
                <strong>
                  {isInventorySnapshot ? "Inventory" : "Asset"} Tag
                </strong>{" "}
                <br />
                {tag}
              </Grid>

              {/* Description */}
              <Grid item xs={12} sm={4} sx={{ wordWrap: "break-word" }}>
                <strong>Description</strong> <br />
                {description}
              </Grid>

              {/* Category */}
              <Grid item xs={12} sm={4} sx={{ wordWrap: "break-word" }}>
                <strong>Category</strong> <br />
                {category}
              </Grid>

              {/* Facilities */}
              <Grid item xs={12} sm={4} sx={{ wordWrap: "break-word" }}>
                <strong>Last Location</strong> <br />
                {latitude && longitude && facilities[facilityId]
                  ? facilities[facilityId].name
                  : "None provided"}
              </Grid>

              {/* Status */}
              <Grid item xs={12} sm={4} sx={{ wordWrap: "break-word" }}>
                <strong>Status</strong>
                <br />
                {eventTypesMap[lastEvent]?.color ? (
                  <Box
                    style={{
                      color: eventTypesMap[lastEvent].color,
                    }}
                  >
                    {lastEvent}
                  </Box>
                ) : (
                  `${lastEvent}`
                )}
              </Grid>

              {/* Date Created User */}
              {timeCreatedUser ? (
                <Grid item xs={12} sm={4} sx={{ wordWrap: "break-word" }}>
                  <strong>Date Created</strong> <br />
                  {moment(timeCreatedUser, "YYYYMMDDHHmmss").format(
                    "MM/DD/YYYY"
                  ) || "None provided"}
                </Grid>
              ) : null}

              {/* Date needed */}
              {timeNeeded ? (
                <Grid item xs={12} sm={4} sx={{ wordWrap: "break-word" }}>
                  <strong>Date Needed</strong> <br />
                  {moment(timeNeeded, "YYYYMMDDHHmmss").format("MM/DD/YYYY") ||
                    "None provided"}
                </Grid>
              ) : null}

              {/* Bin Location */}
              {zone && zone.binLocation ? (
                <Grid item xs={12} sm={4} sx={{ wordWrap: "break-word" }}>
                  <strong>Bin Location</strong> <br />
                  {zone.binLocation}
                </Grid>
              ) : null}

              {/* Customs */}
              {customMap && !_.isEmpty(customMap)
                ? Object.keys(customMap)
                    .sort((a, b) => {
                      if (a === b) {
                        return 0;
                      } else {
                        return a.localeCompare(b);
                      }
                    })
                    .map((key, idx) => {
                      if (customMap[key]) {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={key === "note" ? 12 : 4}
                            key={`${idx} - ${key}`}
                            sx={{ wordWrap: "break-word" }}
                          >
                            <strong>
                              {key === "note" ? "Notes" : _.startCase(key)}
                            </strong>{" "}
                            <br />
                            {customMap[key]}
                          </Grid>
                        );
                      }
                      return null;
                    })
                : null}

              {/* Asset Data */}
              {propertiesMap && !_.isEmpty(propertiesMap)
                ? Object.keys(propertiesMap)
                    .filter((key) => key === "note")
                    .map((key, idx) => {
                      if (
                        propertiesMap[key] &&
                        typeof propertiesMap[key] === "string"
                      ) {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={key === "note" ? 12 : 4}
                            key={`${idx} - ${key}`}
                            sx={{ wordWrap: "break-word" }}
                          >
                            <strong>
                              {key === "note" ? "Notes" : _.startCase(key)}
                            </strong>{" "}
                            <br />
                            {propertiesMap[key]}
                          </Grid>
                        );
                      }
                      return null;
                    })
                : null}

              {/* Classifications */}
              {Object.keys(classificationMap).map((item, index) => {
                return (
                  <Grid item key={`${item}-${index}`} sm={4} sx={{ wordWrap: "break-word" }} xs={12}>
                    <strong>{item}</strong>
                    <br />
                    {classificationMap[item]}
                  </Grid>
                );
              })}

              {/* Quantity Current */}
              {quantityCurrent ? (
                <Grid item xs={12} sm={4} sx={{ wordWrap: "break-word" }}>
                  <strong>Quantity Qty</strong>
                  <br />
                  {quantityCurrent}
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Overview;
