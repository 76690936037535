import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, InputLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

export default function LXDatePicker(props) {
  const {
    disabled = false,
    format = "MM/DD/yyyy",
    id = "date-picker-inline-timeCreatedUser",
    label = null,
    labelPlacement = "top",
    onChange,
    size,
    slotProps = {},
    value,
  } = props;
  const classes = {
    inputField: {
      width: "100%",
    }
  };

  return (
    <Box
      sx={
        labelPlacement === "start"
          ? {
              display: "flex",
              alignItems: "center",
            }
          : {}
      }
    >
      <Box
        sx={{
          color: "#6D6E70",
          display: "flex",
          fontFamily: "Lato",
          fontWeight: "bold !important",
          justifyContent: "space-between",
          lineHeight: 1,
          marginBottom: `${labelPlacement === "start" ? "inherit" : ".5rem"}`,
          marginTop: `${labelPlacement === "start" ? "inherit" : ".2rem"}`,
          marginRight: `${labelPlacement === "start" ? ".5rem" : ""}`,
        }}
      >
        <InputLabel
          sx={{
            color: "#6D6E70",
            fontFamily: "Lato",
            fontWeight: "bold",
          }}
        >
          {label}
        </InputLabel>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          sx={classes.inputField}
          format={format}
          disabled={disabled}
          id={id}
          onChange={(event) => {
            onChange(event);
          }}
          size={size}
          slotProps={slotProps}
          value={value}
        />
      </LocalizationProvider>
    </Box>
  );
}
