import { Grid } from "@mui/material";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";

export default function ConfirmationModalContent(props) {

  const classes = {
    buttonContainer: {
      display: "flex",
    },
    container: {
      textAlign: "center",
      padding: "1rem",
    },
    content: {
      marginBottom: "1rem",
    },
    text: {
      color: "black !important",
    },
  }

  const { handleSubmit, handleCancel, content } = props;
  return (
    <Grid sx={classes.container} container>
      {/* Content */}
      <Grid sx={classes.content} item xs={12}>
        {content}
      </Grid>

      {/* Button Container */}
      <Grid sx={classes.buttonContainer} item xs={12}>
        {/* Confirmation Button */}
        <Grid item xs={6}>
          <MaterialUiButton
                  color="submit"
                  cypressId={"modal-confirm-yes"}
                  label="YES"
                  onClick={() => handleSubmit()}
          />
        </Grid>

        {/* Cancel Button */}
        <Grid item xs={6}>
          <MaterialUiButton
                  color="cancel"
                  cypressId={"modal-confirm-no"}
                  label="NO"
                  onClick={() => handleCancel()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
