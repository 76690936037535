import { createAsyncThunk } from "@reduxjs/toolkit";

export const assets = {
  saveFilterSettings: createAsyncThunk(
    "inventory/inventory/saveFilterSettings",
    async (inventoryStatusTableSettings, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;
      // retrieve token and user Id from store
      const { token = "", appUserId = "" } = getState().user;
      // also retrieve current filters to spread over incoming filters, just in case incoming filters do not contain all up-to-date properties
      const { filters: filtersFromStore = {} } = getState().assets;
      const filters = { ...filtersFromStore, ...inventoryStatusTableSettings };
      const data = await apiService.inventory
        .saveFilterSettings({ token, appUserId }, filters)
        .then((data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: { type: "error", text: data.error },
            });
          } else if (data.success) {
            return {
              success: true,
              filters: filters,
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while saving your filter settings.",
              },
            });
          }
        });
      // data.reduxRequestId = thunkAPI.requestId;
      return data;
    }
  ),
  showLastEvents: createAsyncThunk(
    "inventory/inventory/showLastEvents",
    async (inventoryStatusTableSettings = null, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;
      const { token = "", organizationId = "" } = getState().user;
      const { classifications } = getState().organization;

      const { start = 0, limit = 25 } = inventoryStatusTableSettings;

      const data = await apiService.inventory
        .showLastEvents(
          { token, organizationId, classifications },
          inventoryStatusTableSettings
        )
        .then(async (data) => {
          if (data.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                type: "error",
                text: data.error,
              },
            });
          } else if (data.success) {
            return {
              success: true,
              assets: data.assets,
              count: data.count,
              // calculate page location by dividing start (e.g., page 3, 4th position in data) and limit (e.g., rows per page, max number of rows) and finding lower limit (math.floor)
              page: Math.floor(start / limit),
              parentAssetIdTagMap: data.parentAssetIdTagMap,
              mapTableData: [],
            };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching asset histories.",
              },
            });
          }
        });
      return data;
    }
  ),
  retrieveParentIds: createAsyncThunk(
    "inventory/inventory/retrieveParentIds",
    async (inventoryStatusTableSettings = null, thunkAPI) => {
      const { getState, extra: apiService, dispatch } = thunkAPI;
      const { token = "" } = getState().user;

      const data = await apiService.inventory
        .retrieveParentIds({ token })
        .then(async (response) => {
          if (response.error) {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: response.error,
              },
            });
          } else if (response.success) {
            let parentIds = {};
            return { success: true, parentIds };
          } else {
            return dispatch({
              type: "alert/showAlert",
              payload: {
                text: "Something went wrong while fetching asset histories.",
              },
            });
          }
        });
      return data;
    }
  ),
};
