import { createSlice } from "@reduxjs/toolkit";

import { init } from "./thunks/init";
import * as thunks from "./thunks/thunks";
import { extraReducers } from "./thunks/extraReducers";

import { reducers } from "./reducers";

import * as organizationSelectors from "./selectors";

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    init: {
      status: "idle",
    },
    batches: {},
    childOrganizations: {},
    classifications: {},
    consoleRoles: {},
    customs: [],
    devices: {},
    displayConfig: [],
    facilities: {},
    facilitiesList: [],
    mobileRoles: {},
    organization: {},
    products: {},
    productsList: [],
    usersMap: {},
    zones: {},
  },
  reducers: {
    ...reducers,
  },
  extraReducers: {
    ...extraReducers,
  },
});

// Action creators are generated for each case reducer function
const {
  addZone,
  editZone,
  removeZone,
  removeZones,
  updateBatches,
  updateChildOrganizations,
  updateClassifications,
  updateCustoms,
  updateDisplayConfig,
  updateFacilities,
  updateFacility,
  updateFacilityZones,
  removeFacility,
  updateOrganization,
  updateProducts,
  updateUsersMap,
} = organizationSlice.actions;

export {
  addZone,
  editZone,
  init,
  organizationSelectors,
  removeZone,
  removeZones,
  thunks,
  updateBatches,
  updateChildOrganizations,
  updateClassifications,
  updateCustoms,
  updateDisplayConfig,
  updateFacilities,
  updateFacility,
  updateFacilityZones,
  removeFacility,
  updateOrganization,
  updateProducts,
  updateUsersMap,
};

export const organizationErrorSelector = (state) => state.organization.error;

export default organizationSlice.reducer;
