import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    bottomPush: {
      height: "100px",
    },
    drawer: (props) => ({
      flexShrink: 0,
      width: props.sideNavWidth,
    }),
    drawerPaper: (props) => ({
      backgroundColor: "#32355C",
      width: props.sideNavWidth,
    }),
    listItem: {
      justifyContent: "center",
      "& *": {
        color: "#fefefe",
      },
      "&:focus-visible": {
        backgroundColor: "#A0C46D",
      },
      "&:hover": {
        "& *": {
          color: "white!important",
        },
        backgroundColor: "#A0C46D",
      },
    },
    listItemActive: {
      backgroundColor: "#A0C46D",
      "& *": {
        color: "white",
      },
    },
    listItemIcon: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    listItemText: {
      fontSize: "0.7em",
      textAlign: "center",
    },
    nestedListItem: {
      paddingLeft: theme.spacing(4),

      "& *": {
        color: "white",
      },

      "&:hover": {
        backgroundColor: "#A0C46D",

        color: "#414348",
      },
    },
    nestedListItemActive: {
      backgroundColor: "#A0C46D",
      paddingLeft: theme.spacing(4),
      "& *": {
        color: "#414348",
      },
      "&:hover": {
        backgroundColor: "#A0C46D",
        color: "#414348",
      },
    },
    menuIcon: (props) => ({
      height: props.appBarHeight,
      justifyContent: "center",
    }),
    menuItem: {
      backgroundColor: "#414348",
      color: "white",
      "&:hover": {
        color: "white",
        backgroundColor: "#32355C",
      },
    },
    menuList: {
      justifyContent: "center",
      paddingBottom: "0!important",
      paddingTop: "0!important",
    },
    menuPaper: {
      backgroundColor: "#414348",
    },
    userBadgeItem: {
      backgroundColor: "#32355C",
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      position: 'sticky',
      "&:hover": {
        backgroundColor: "#A0C46D",
      },
    },
  })
);
