import { deleteFacilityImage, updateFacility } from "./api";
import Grid from "@mui/material/Grid";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import Typography from "@mui/material/Typography";

export default function ResetFacilityImage(props) {
  const classes = {
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1rem 0",
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      padding: "2rem 1rem",
    },
    header: {
      textAlign: "center",
      paddingBottom: "1rem",
    },
  };

  const {
    facilityId,
    handleSnackbar,
    selectedFacility,
    setModalState,
    state,
    setState,
  } = props;

  const { images = [] } = selectedFacility;
  // if there is a an image with imageType of "PDF", get its id. Otherwise, get the png image or first image id
  const imageToDelete =
    images.find((image) => image.imageType === "PDF") ||
    images.find((image) => image.imageType === "PNG") ||
    images[0];
  const imageToDeleteId = imageToDelete?.imageId || "";

  return (
    <Grid sx={classes.container} container>
      <Grid sx={classes.header} item xs={12}>
        <Typography variant="h6">
          Are you sure you want to remove the facility image?
        </Typography>
      </Grid>
      {/* Buttons */}
      <Grid sx={classes.buttonContainer} item xs={12}>
        <Grid item xs={4}>
          <MaterialUiButton
            color="cancel"
            fullWidth
            label="CANCEL"
            onClick={() => {
              setModalState((prevState) => ({
                ...prevState,
                modalShow: false,
              }));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <MaterialUiButton
            color="primary"
            fullWidth
            label="YES"
            onClick={() => {
              deleteFacilityImage({
                ...props,
                facilityId,
                facilityImageId: imageToDeleteId,
              }).then((res = {}) => {
                if (res.success) {
                  //run updateFacility function to delete image and if that call was successful, reset propertiesMap to null
                  // makes sure future images dont have zero and far points or grid upon being added
                  updateFacility({ ...props }, {}).then((res = {}) => {
                    if (res.success) {
                      //fire off an updateFacilty function which sets the propertiesMap to null.
                      //only after this can the state updates be made

                      // Close the Modal
                      setModalState((prevState) => ({
                        ...prevState,
                        modalShow: false,
                      }));

                      // Handles the snackbar
                      handleSnackbar("Facility Image Removed", false);

                      // Sets the state
                      setState((prevState) => ({
                        ...prevState,
                        facilityImage: null,
                        facilityImageId: null,
                        selectedFacility: res.facility,
                        farPoint: { x: 0, y: 0, disablePlunger: false },
                        zeroPoint: { x: 0, y: 0, disablePlunger: false },
                      }));
                    }
                  });
                }
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
