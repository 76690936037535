import { useState } from "react";
import _ from "lodash";
import { Box, FormControl, TextField } from "@mui/material/";
import { InputLabel } from "@mui/material";
import { searchHistories } from "../../../utils/API/AssetHistories/AssetHistories";
import Autocomplete from "@mui/material/Autocomplete";

export default function BinSelector(props) {
  const {
    apiUrl,
    labelPlacement = "top",
    onChange = () => {},
    onError = () => {},
    organizationId,
    size = "small",
    token,
    value = null,
    variant,
  } = props;

  const [options, setOptions] = useState([]);

  const debounce_try = _.debounce((inputValue) => {
    searchHistories(
      { apiUrl, token, organizationId },
      { filters: { binLocationWildCard: inputValue, limit: 300 } }
    ).then((results) => {
      if (results.error) {
        onError(results.error);
      }
      const options =
        results.assetHistories && results.assetHistories.length
          ? [
              ...new Set(
                results.assetHistories
                  .filter((history) => history.binLocation)
                  .map((history) => history.binLocation)
              ),
            ].map((binLocation) => {
              return {
                label: binLocation,
                value: binLocation,
              };
            })
          : [];

      setOptions(options);
    });
  }, 1000);

  return (
    <Box
      sx={
        labelPlacement === "start"
          ? {
              display: "flex",
              alignItems: "center",
            }
          : {}
      }
    >
      <Box
        sx={
          labelPlacement === "start"
            ? {
                display: "flex",
                alignItems: "center",
              }
            : {}
        }
      >
        <Box
          sx={{
            color: "#6D6E70",
            display: "flex",
            fontFamily: "Lato",
            fontWeight: "bold !important",
            justifyContent: "space-between",
            lineHeight: 1,
            marginBottom: `${labelPlacement === "start" ? "inherit" : ".5rem"}`,
            marginTop: `${labelPlacement === "start" ? "inherit" : ".2rem"}`,
            marginRight: `${labelPlacement === "start" ? ".5rem" : ""}`,
          }}
        >
          <InputLabel
            htmlFor="inventory-ea-txtselect-binlocation"
            sx={{
              color: "#6D6E70",
              fontFamily: "Lato",
              fontWeight: "bold",
            }}
          >
            Bin Location
          </InputLabel>
        </Box>
      </Box>
      <FormControl
        className="bin-select"
        sx={{ width: "100%" }}
        variant={variant ? variant : "standard"}
      >
        {/* https://mui.com/material-ui/react-autocomplete/#search-as-you-type */}
        <Autocomplete
          autoComplete
          disableCloseOnSelect
          filterOptions={(x) => x}
          filterSelectedOptions
          getOptionLabel={(option) => option?.label}
          id="inventory-ea-txtselect-binlocation"
          includeInputInList
          multiple
          noOptionsText="Start typing to search bins"
          onChange={(event, value) => onChange(value)}
          onClose={() => setOptions([])}
          onInputChange={(event, newInputValue) => {
            if (
              newInputValue !== "undefined" &&
              newInputValue.trim().length !== 0
            ) {
              debounce_try(newInputValue);
            }
          }}
          options={options}
          renderInput={(params) => <TextField {...params} fullWidth />}
          size={size}
          sx={{ width: "100%" }}
          value={value}
        />
      </FormControl>
    </Box>
  );
}
