import React, { useState, useEffect, useRef } from 'react'
import DailyEventsBar from './DailyEventsBar'
import DailyEventsGrid from './DailyEventsGrid'
import { getDateXDaysAgo, getPreviousNDays, toMMDDYYYYFormat } from '../../../globalStore/utils/helpers'
import { Grid } from '@mui/material'
import { thunks } from '../../../globalStore/slices/inventoryHistory/inventoryHistorySlice'
import { useDispatch } from 'react-redux'

function DailyEvents(props) {

  const eventsGridRef = useRef(null);

  const [recentEvents, setRecentEvents] = useState([]);
  const [selectedDayEvents, setSelectedDayEvents] = useState([]);
  // make sure date format is the same as the date format in the events data (05/10/2023)
  const todaysDateFormatted = toMMDDYYYYFormat(new Date())
  const [selectedBar, setSelectedBar] = useState({date: todaysDateFormatted, eventType: ''})

  const { searchRecentHistories } = thunks.inventoryHistory
  const dispatchGlobal = useDispatch()

  const todaysDate = new Date()
  const pastDate = getDateXDaysAgo(todaysDate, 28)
  
  const rangeStartTime = pastDate.getTime()
  const rangeEndTime = todaysDate.getTime()

  //newest date to left
  // const last28Days = getPreviousNDays(new Date(), 28)
  //newest date to the right
  const last28Days = getPreviousNDays(new Date(), 28).slice().reverse()
  
  useEffect(() => {
    // api call to get events for last 28 days
    // going to need the time range for the last 28 days and the organization id as well as the token
    dispatchGlobal(searchRecentHistories( {rangeStart: rangeStartTime, rangeEnd: rangeEndTime} )).then((res) => {
      setRecentEvents(res.payload.recentHistories)
    }
    )
  }, [])

  //every time selectedBar changes, check if there is a date and eventType, if so, get events for that date and eventType, if not, get events for selectedDate
  useEffect(() => {
    if(selectedBar.date && selectedBar.eventType) {
      getEventsForSelectedEventType()
    }
    else {
      getAllEventsForSelectedDay()
    }
    
  }, [selectedBar])
  
  function getAllEventsForSelectedDay() {
    // filter recentEvents for events that match the selected date
    const filteredEvents = recentEvents.filter(event => {
      return event.timeOfLogString === toMMDDYYYYFormat(selectedBar.date)
    })
    setSelectedDayEvents(filteredEvents)
  }

  function getEventsForSelectedEventType() {
    const filteredEvents = recentEvents.filter(event => {
      return event.timeOfLogString === toMMDDYYYYFormat(selectedBar.date) && event.event === selectedBar.eventType
    })
    setSelectedDayEvents(filteredEvents)
  }

  function configureBarDatasets() {
    const possibleEvents = {}

    let eventType;
    let eventDate;
    let dropOffIndex;
    let newEventValue;

    recentEvents.forEach(event => {

      eventType = event.event
      eventDate = event.timeOfLogString
      dropOffIndex = last28Days.indexOf(eventDate)

      //if possibleEvents doesnt have the event type, fill an array with 28 0s and at the dropOffIndex(date which event happened), put a 1
      if(possibleEvents[eventType] === undefined) {
        possibleEvents[eventType] = new Array(28).fill(0)
        possibleEvents[eventType][dropOffIndex] = 1
      }
      else if(possibleEvents[eventType]) {
        newEventValue = possibleEvents[eventType][dropOffIndex]
        // handles case where the event type is already in the possibleEvents object but the value is undefined
        if(newEventValue === undefined){
          possibleEvents[eventType][dropOffIndex] = 1
        }
        else {possibleEvents[eventType][dropOffIndex] = newEventValue + 1}
      }
      else {
        console.warn("Unaccounted for scenario with getting daily events for bar chart")
      }
    })

    return possibleEvents
  }

  return (
    <Grid
      container
      style={{
        marginTop: "1rem",
        width: "100% !important",
        padding: "0 15px",
        margin: "0 auto",
      }}
    >
      <DailyEventsBar
        {...props}
        eventsGridRef={eventsGridRef}
        barDatasets={configureBarDatasets()}
        last28Days={last28Days}
        selectedBar={selectedBar}
        setSelectedBar={setSelectedBar}
      />

      <DailyEventsGrid
        {...props}
        eventsGridRef={eventsGridRef}
        selectedBar={selectedBar}
        selectedDayEvents={selectedDayEvents}
      />
    </Grid>
  )
}

export default DailyEvents
