import { formatLatLon } from "../../../../utils/lat_lon_utils";
import { isEmpty } from "lodash";
import { parseUserInfo } from "../utils";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useTranslation as UseTranslation } from "react-i18next"; // "useTranslation is neither a React function component nor a custom React Hook function. React component names must start with an uppercase letter"
import modifyColumns from "../../../../utils/modifyColumns";

export default function buildColumns(props) {
  const {
    appUserType,
    classifications,
    columnOrder,
    customs,
    facilities,
    history,
    timeZone,
    zones,
  } = props;
  const showPCA = ![
    "Asset-Operations",
    "Asset",
    "Warehouse-Operations",
  ].includes(appUserType);

  const { t } = UseTranslation();

  const classificationColumns = !isEmpty(classifications)
    ? Object.keys(classifications).map((classKey) => {
      const { name } = classifications[classKey];
      return {
        field: name,
        headerName: name,
        renderCell: (data) => {
          const { row, tabIndex } = data;
          const { classificationMap = {} } = row;

          const classificationValue = Object.keys(classificationMap).filter(
            (k) => k === name
          )[0];

          return (
            <div
              className="pointer"
              key={tabIndex}
              onClick={() => onClick(row)}
            >
              {classificationMap[classificationValue] || t("dnpinput1")}
            </div>
          );
        },
        valueGetter: (data) => {
          const { row } = data;
          const { classificationMap = {} } = row;

          const classificationValue = Object.keys(classificationMap).filter(
            (k) => k === name
          )[0];

          return classificationMap[classificationValue] || t("dnpinput1");
        },
      };
    })
    : [];

  const customColumns = !isEmpty(customs)
    ? customs.map((item) => {
      const { customId = '', name = '' } = item;
      return {
        field: name,
        headerName: name,
        id: "name",
        renderCell: (data) => {
          const { row, tabIndex } = data;
          const { customMap = {} } = row;

          const customValue = Object.keys(customMap).filter(
            (k) => k === name
          )[0];

          return (
            <div
              className="pointer"
              key={`${tabIndex}-${customId}}`}
              onClick={() => onClick(row)}
            >
              {customMap[customValue] || t("dnpinput1")}
            </div>
          );
        },
        valueGetter: (data) => {
          const { row } = data;
          const { customMap = {} } = row;

          const customValue = Object.keys(customMap).filter(
            (k) => k === name
          )[0];

          return customMap[customValue] || t("dnpinput1");
        }
      };
    })
    : [];

  const historyTableColumns = [
    // timeOfLog
    {
      field: "timeOfLog",
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { timeOfLogLong } = data.row;
        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {timeOfLogLong
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (data) => {
        const timeOfLogLong = data.row.timeOfLogLong;
        return timeOfLogLong ? new Date(timeOfLogLong) :
          //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
          new Date(8640000000000000);
      }
    },
    // assetTag
    {
      field: "assetTag",
      headerName: "Inventory Tag",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const { assetTag } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {assetTag ? assetTag : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { row } = data;
        const { assetTag } = row;
        return assetTag ? assetTag : t("dnpinput1");
      },
    },
    // assemblyId
    {
      field: "assemblyId",
      headerName: "Ticket Id",
      id: "assemblyId",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const { assemblyName } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {assemblyName ? assemblyName : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        return data.row.assemblyName || t("dnpinput1")
      },
    },
    // category
    {
      field: "category",
      headerName: "Inventory Category",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const { category } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {category ? category : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { row } = data;
        const { category } = row;
        return category ? category : t("dnpinput1");
      },
    },
    // event
    {
      field: "event",
      headerName: "Event",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const { event } = row;
        return (
          <div
            className="pointer"
            key={tabIndex}
            style={{
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => onClick(row)}
          >
            {event ? event : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { row } = data;
        const { event } = row;
        return event ? event : t("dnpinput1");
      }
    },
    // user
    {
      field: "user",
      headerName: "User",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const userInfo = parseUserInfo(row);
        return (
          <div
            className="pointer"
            key={tabIndex}
            style={{
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => onClick(row)}
          >
            {userInfo || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => parseUserInfo(data.row),
    },
    // facility
    {
      field: "facility",
      headerName: "Facility Name",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const { facility, facilityId, latitude, longitude } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {facility
              ? facility
              : !latitude || !longitude
                ? t("dnpinput1")
                : facilityId &&
                  facilities[facilityId] &&
                  facilities[facilityId]?.name
                  ? facilities[facilityId]?.name
                  : "Not Registered"}
          </div>
        );
      },
      valueGetter: (data) => {
        const { row } = data;
        const { facility, facilityId, latitude, longitude } = row;

        return facility
          ? facility
          : !latitude || !longitude
            ? t("dnpinput1")
            : facilityId && facilities[facilityId] && facilities[facilityId]?.name
              ? facilities[facilityId]?.name
              : "Not Registered";
      },
    },
    // latLong
    {
      field: "latLong",
      headerName: "Latitude, Longitude",
      id: "latLong",
      renderCell: (data) => {
        const { latitude, longitude, propertiesMap } = data.row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return (
          <div
            onClick={() => onClick(data.row)}
            // className={classes.columnHeader}
            key={data.row.assetId}
          >
            {formattedLatLong}
          </div>
        );
      },
      valueGetter: (data) => {
        const { latitude, longitude } = data.row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return formattedLatLong;
      },
    },
    // zone
    {
      field: "zone",
      headerName: "Zone",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const { zone = {} } = row;
        const {
          zoneName = "",
          pZoneName = "",
          zoneId = "",
        } = zone;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {/* Show pZoneName. If not present show pZoneName. If not present the Zone Name */}
            {pZoneName
              ? pZoneName
              : zoneName
                ? zoneName
                : zoneId && zones[zoneId]
                  ? zones[zoneId].name
                  : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { row } = data;
        const { zone = {} } = row;
        const { pZoneName = "", zoneId = "" } = zone;

        return pZoneName
          ? pZoneName
          : zoneId && zones[zoneId]
            ? zones[zoneId].name
            : t("dnpinput1");
      },
    },
    // binLocation
    {
      field: "binLocation",
      headerName: "Bin Location",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const { zone = {} } = row;
        const { binLocation = "" } = zone;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              row.zone
                ? binLocation
                : row.binLocation
                  ? row.binLocation
                  : row.propertiesMap && row.propertiesMap.binLocation
                    ? row.propertiesMap.binLocation
                    : t("dnpinput1")
            }
          </div>
        );
      },
      valueGetter: (data) => {
        const { row } = data;
        const { zone = {} } = row;
        const { binLocation = "" } = zone;
        return row.zone
          ? binLocation
          : row.binLocation
            ? row.binLocation
            : row.propertiesMap && row.propertiesMap.binLocation
              ? row.propertiesMap.binLocation
              : t("dnpinput1");
      },
    },
    // state
    {
      field: "state",
      headerName: "State",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const { state = t("dnpinput1") } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {state ? state : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { row } = data;
        const { state = t("dnpinput1") } = row;
        return state ? state : t("dnpinput1");
      },
    },
    // quantityCurrent
    {
      field: "quantityCurrent",
      headerName: "Quantity Qty",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {row.quantity?.quantityCurrent || t("dnpinput1")}
          </div>
        );
      },
      type: "number",
      valueGetter: (data) => {
        if (data.row.quantity?.quantityCurrent) {
          return parseInt(data.row.quantity.quantityCurrent);
        } else {
          return Number.NEGATIVE_INFINITY;
        }
      },
    },
  ]
    .filter((col) => {
      if (!showPCA) {
        return col.id !== "flagged";
      } else {
        return true;
      }
    })
    .concat(classificationColumns)
    .filter((col) => {
      // get rid of event specific comments
      if (col.field.includes("Comments") && col.field !== "Comments") {
        return false;
      } else {
        return true;
      }
    })
    // TODO - Elastic Wait for this to get refactored on AC4
    .concat(customColumns);

  const onClick = (row) => {
    const { assetId = "" } = row;
    if (assetId) {
      const url = `/inventorySnapshot/${assetId}`;
      history.push(url);
    }
  };

  // Here we need to go through the columnOrder and handle Sorting / Renaming / Visibility settings
  if (columnOrder) {
    return modifyColumns({ columnOrder, historyTableColumns });
  }

  return { historyTableColumns };
}
