import { createAsyncThunk } from "@reduxjs/toolkit";
import DefaultOrganizationRolesMap from "../../../utils/DefaultOrganizationRolesMap";
import { setupConfig } from "../../../utils/helpers";

const consoleRolesMap = DefaultOrganizationRolesMap.console;

// Thunks enable us to dispatch async actions and create payloads with loading/fulfilled/error states that can be read from a slice's extraReducers property
// very powerful, we could potentially combine all of our state reducer logic with our API service
export const init = createAsyncThunk(
  "organization/init",
  async (_, thunkAPI) => {
    const { getState, extra: apiService, dispatch } = thunkAPI;
    // expected user to be initialized before initializing organization
    const { token = "", organizationId = "", appUserId = "" } = getState().user;

    const data = await apiService.organization
      .init({ token, organizationId, appUserId })
      .then((data) => {
        if (data.error) {
          return dispatch({
            type: "alert/showAlert",
            payload: {
              type: "error",
              text: data.error,
            },
          });
        } else if (data.organization) {
          const mobileRolesMap =
            data.organization.rolesMap?.mobile ||
            DefaultOrganizationRolesMap.mobile;

          //find the appUserType by using the appUserId to find the user in the usersMap
          const appUserType = data.usersMap[appUserId].propertiesMap.consoleRole
          const orgConfig = setupConfig(data.classifications, data.customs, appUserType, data.displayConfig)

          return {
            batches: data.batches || {},
            childOrganizations: data.childOrganizations,
            classifications: data.classifications || {},
            consoleRoles: consoleRolesMap,
            customs: data.customs || [],
            devices: data.devices,
            displayConfig: orgConfig,
            facilities: data.facilities || {},
            mobileRoles: mobileRolesMap,
            organization: data.organization,
            products: data.products || {},
            success: true,
            usersMap: data.usersMap || {},
            zones: data.zones || {},
          };
        } else {
          return dispatch({
            type: "alert/showAlert",
            payload: {
              text: "Something went wrong while initializing your organization's data. Please navigate to http://helpdesk.locatorx.com/support/home to open a support ticket.",
            },
          });
        }
      });

    // data.reduxRequestId = thunkAPI.requestId;
    return data;
  }
);
