const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const searchHistories = async (props, state) => {
  const { organizationId, token } = props;
  const {
    assetId,
    isExport,
    isInventorySnapshot = false,
    page = 0,
  } = state;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must_not: [

          // If the user is on the Inventory Snapshot page, then we do not need
          // to exclude this
          {
            terms: {
              asset_mode: [
                isInventorySnapshot ? "" : "Inventory",
                "Device",
                "Product",
              ],
            },
          },

          // We do not want Arrive / Depart / Enroute. These events signify a route is or trip is taking place.
          // We do a seperate API call to get events with these records and use the results to form polylines
          // rather markers.

          // No Arrive Events - Commenting out until we further mature polyline logic
          // {
          //   term: {
          //     event: "Arrive",
          //   },
          // },

          // No Depart Events - Commenting out until we further mature polyline logic
          // {
          //   term: {
          //     event: "Depart",
          //   },
          // },


          // No Enroute Events - Commenting out until we further mature polyline logic
          // {
          //   term: {
          //     event: "EnRoute",
          //   },
          // },

          // No Proximity Events
          {
            term: {
              event: "Proximity",
            },
          },

          // No Proximity Events
          {
            term: {
              event: "Moving Proximity",
            },
          },

          // do not include records with a lastEvent of "Scan – LogisticsPro". Using Regex because of the dash character
          //Match for strings that start with Scan and end with LogisticsPro
          {
            regexp: {
              "event": {
                "value": "Scan.*LogisticsPro",
              }
            }
          },
        ],
        must: [
          {
            term: {
              organization_id: organizationId,
            },
          },
          {
            bool: {
              must_not: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    limit: isExport ? 10000 : 25000,
    page,
    sort: "time_of_log DESC",
  };

  // If this is an inventory asset, we need to surpress events with External Scanned
  if (isInventorySnapshot) {
    elasticQuery.elasticSearchQuery.bool.must_not.push({
      term: { event: "External Scanned" },
    });
  }

  // Asset Id - In the case we are looking for histories from a certain assetId
  if (assetId) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_id: assetId,
      },
    });
  }

  const results = await fetch(`${apiUrl}assetHistories/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error:
          "Failed to fetch asset history data, please contact system administrator.",
      };
    });

  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      assetHistoryTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const assetHistory = {
  searchHistories,
  saveFilterSettings,
};
