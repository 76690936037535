import { buildExistingColumns } from "../../pages/settings/GlobalSettings/Tabs/DisplayConfig/displayConfigUtils";
import DefaultOrganizationRolesMap from "./DefaultOrganizationRolesMap";
import returnsDefaultDisplayColumns from "../../pages/settings/GlobalSettings/Tabs/DisplayConfig/utils";

export function FlattenUserRoles(appUserRoles, userType) {
  // e.g., consoleRoles = org.allRolesMap, appUserRoles = ["Admin", "someMobileRole"], userType = "Asset/Product"
  // filtering the appUser roles because I don't trust depending on the index of the array type

  const consoleRoles = DefaultOrganizationRolesMap.console;
  const usersConsoleRole = appUserRoles.filter((role) => consoleRoles[role])[0];
  const availableActions = { ...consoleRoles[usersConsoleRole]?.actions } || {};

  //if the users console role is mobile only, no navbar links are set.
  //no console access is kept in consideration for existing users that have this role
  if (usersConsoleRole === "No Console Access") {
    return;
  }
  if (usersConsoleRole === "Mobile Only") {
    return;
  }
  // This will append and remove the appropiate actions based on the userType
  switch (userType) {
    case "Asset":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });
      break;
    case "Asset/Product":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      break;
    case "Asset-Operations":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });
      break;
    case "Asset/Inventory":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
        "View Inventory",
        "View Inventory Settings",
        "View Product",
        "View Batches",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      break;
    case "Product":
      ["View Product Home"].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Devices"].forEach((item) => {
        delete availableActions[item];
      });
      break;
    case "Inventory":
      ["View Inventory", "View Inventory Settings"].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });
      break;
    case "Inventory/Product":
      ["View Inventory", "View Product", "View Batches", "View Inventory Settings"].forEach((item) => {
        availableActions[item] = {};
      });
      break;
    case "Warehouse-Operations":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      ["View Products", "View Batches"].forEach((item) => {
        delete availableActions[item];
      });

      break;
    case "SuperAdmin":
      [
        "View Asset History",
        "View Asset Settings",
        "View Asset Home",
        "View Asset Snapshot",
      ].forEach((item) => {
        availableActions[item] = {};
      });
      break;
    case "No Console Access":
      Object.keys(availableActions).forEach(
        (key) => delete availableActions[key]
      );
      break;
    case "Mobile Only":
      Object.keys(availableActions).forEach(
        (key) => delete availableActions[key]
      );
      break;
    default:
      break;
  }
  let viewPermissions = {};
  // resolving roles in main init function using promises so we can get everything resolved and setState only once when the component mounts
  Object.keys(availableActions).forEach((action) => {
    switch (action) {
      case "View Asset History":
        viewPermissions.showAssetHistory = true;
        break;
      case "View Batches":
        viewPermissions.showBatches = true;
        break;
      case "View Devices":
        viewPermissions.showDevices = true;
        break;
      case "View Facilities":
        viewPermissions.showFacilities = true;
        break;
      case "View Inventory":
        viewPermissions.showInventory = true;
        break;
      case "View Organizations":
        viewPermissions.showOrganizations = true;
        break;
      case "View Products":
        viewPermissions.showProducts = true;
        break;
      case "View Product Home":
        viewPermissions.showProductHome = true;
        break;
      case "View Settings":
        viewPermissions.showSettings = true;
        break;
      case "View Users":
        viewPermissions.showUsers = true;
        break;
      default:
        break;
    }
  });

  return { availableActions, viewPermissions, usersConsoleRole };
}

export const formatClassifications = (res) => {
  let classificationsUpdated = {};
  const { classifications: classificationsResponse = [] } = res;

  classificationsResponse.forEach((classification) => {
    const { formOptions = [], name = "" } = classification;
    let formOptionsHashed = {};

    // Here we create a hashmap of the formOptions. This is for the Settings page.
    // We might need to refactor this page, which is why we are leaving this logic here.
    formOptions.forEach((option) => (formOptionsHashed[option.label] = option));

    classificationsUpdated[name] = {
      ...classification,
      formOptionsHashed,
    };
  });
  return classificationsUpdated;
};

export const setupConfig = (classifications, customs, appUserType, displayConfig = []) => {

  const requiredTables = [
    { name: "Asset Status", value: "AssetStatus" },
    { name: "Asset History", value: "AssetHistory" },
    { name: "Asset Snapshot", value: "AssetSnapshot" },
    { name: "Event Analysis", value: "EventAnalysis" },
    { name: "Item Analysis", value: "ItemAnalysis" },
    { name: "Daily", value: "DailyHistory" },
    { name: "Inventory Status", value: "InventoryStatus" },
    { name: "Inventory History", value: "InventoryHistory" },
    { name: "Inventory Snapshot", value: "InventorySnapshot" },
    { name: "Movement", value: "MovementHistory" },
    { name: "Device", value: "Device"},
    { name: "Asset Item Analysis", value: "AssetItemAnalysis"},
    { name: "Asset Event Analysis", value: "AssetEventAnalysis"},
  ];
  let newTablesData = [];

  requiredTables.forEach((table) => {
    const { name, value } = table;

    // Is the specificTable here?
    const specificTable =
      displayConfig?.find((element) => element?.type === value) || null;
    const { columns = [] } = specificTable || {};

    // This if statement sets up the defaultColumns if needed. Otherwise it does not run.
    let defaultColumns =
      (specificTable?.columns?.length < 1 || specificTable === null)
        ? returnsDefaultDisplayColumns({
          appUserType,
          classifications,
          customs,
          name: value,
        })
        : buildExistingColumns({ columns, appUserType, classifications, customs, tableName: value });

    if (specificTable?.columns?.length < 1 || specificTable === null) {
      // Table does not exist on the array. Thus we need to set it up here.
      newTablesData.push({
        type: value,
        displayPerspective: "all",
        description: name,
        displayName: name,
        columns: defaultColumns,
        id: `${value}_all`,
      });
    }
    else {
      newTablesData.push({ ...specificTable, columns: defaultColumns })
    }
  });

  return newTablesData;
}

export const getPreviousNDays = (startDate, n) => {
  let previousDays = [];
  // Loop n times and subtract one day from the start date each time
  for (let i = 0; i < n; i++) {
    let currentDate = new Date(startDate.getTime() - (i * 24 * 60 * 60 * 1000));
    // convert to MM/DD/YYYY format and add to array
    previousDays.push(
      ("0" + (currentDate.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + currentDate.getDate()).slice(-2) +
      "/" +
      currentDate.getFullYear()
    );
  }

  return previousDays;
}

export const toMMDDYYYYFormat = (dateString) => {
  const date = new Date(dateString);

  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-US', options);
};

export function getDateXDaysAgo(startDate, numDays) {
  const date = new Date(startDate);
  date.setDate(date.getDate() - numDays);
  return date;
}

export function isValidDateObject(date) {
  return date instanceof Date && !isNaN(date);
}

export const isValidColor = (colorString) => {
  // Regular expression for hexadecimal color
  const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

  // Regular expression for RGB color
  const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;

  // Regular expression for RGBA color
  const rgbaRegex = /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d*(\.\d+)?)\s*\)$/;

  // Array of supported named colors
  const namedColors = [
    "aliceblue", "antiquewhite", "aqua", "aquamarine", "azure", "beige", "bisque",
    "black", "blanchedalmond", "blue", "blueviolet", "brown", "burlywood", "cadetblue",
    "chartreuse", "chocolate", "coral", "cornflowerblue", "cornsilk", "crimson", "cyan",
    "darkblue", "darkcyan", "darkgoldenrod", "darkgray", "darkgreen", "darkkhaki", "darkmagenta",
    "darkolivegreen", "darkorange", "darkorchid", "darkred", "darksalmon", "darkseagreen",
    "darkslateblue", "darkslategray", "darkturquoise", "darkviolet", "deeppink", "deepskyblue",
    "dimgray", "dodgerblue", "firebrick", "floralwhite", "forestgreen", "fuchsia", "gainsboro",
    "ghostwhite", "gold", "goldenrod", "gray", "green", "greenyellow", "honeydew", "hotpink",
    "indianred", "indigo", "ivory", "khaki", "lavender", "lavenderblush", "lawngreen", "lemonchiffon",
    "lightblue", "lightcoral", "lightcyan", "lightgoldenrodyellow", "lightgray", "lightgreen", "lightpink",
    "lightsalmon", "lightseagreen", "lightskyblue", "lightslategray", "lightsteelblue", "lightyellow",
    "lime", "limegreen", "linen", "magenta", "maroon", "mediumaquamarine", "mediumblue", "mediumorchid",
    "mediumpurple", "mediumseagreen", "mediumslateblue", "mediumspringgreen", "mediumturquoise",
    "mediumvioletred", "midnightblue", "mintcream", "mistyrose", "moccasin", "navajowhite", "navy",
    "oldlace", "olive", "olivedrab", "orange", "orangered", "orchid", "palegoldenrod", "palegreen",
    "paleturquoise", "palevioletred", "papayawhip", "peachpuff", "peru", "pink", "plum", "powderblue",
    "purple", "rebeccapurple", "red", "rosybrown", "royalblue", "saddlebrown", "salmon", "sandybrown",
    "seagreen", "seashell", "sienna", "silver", "skyblue", "slateblue", "slategray", "snow", "springgreen",
    "steelblue", "tan", "teal", "thistle", "tomato", "turquoise", "violet", "wheat", "white", "whitesmoke",
    "yellow", "yellowgreen"
  ];

  // Check if it matches any of the formats or is a named color
  return hexRegex.test(colorString) || rgbRegex.test(colorString) || rgbaRegex.test(colorString) || namedColors.includes(colorString.toLowerCase());
}


// gets our exported thunks into the right shape for the extraReducers in our slices, see thunks and extraReducers
// commenting out the API status for now
export const thunkSpreader = (thunks) =>
  Object.keys(thunks)
    .map((category) => {
      return Object.keys(thunks[category])
        .map((thunkKey) => {
          return {
            [thunks[category][thunkKey].pending]: (state, action) => {
              // state[category].status = "loading";
            },
            [thunks[category][thunkKey].fulfilled]: (state, action) => {
              return {
                ...state,
                ...action.payload,
              };
            },
            [thunks[category][thunkKey].rejected]: (state = {}, action) => {
              // error handling here
              // state.error.show = true;
              // state.error.text = action.payload.error;
            },
          };
        })
        .reduce((x, y) => {
          return {
            ...x,
            ...y,
          };
        }, {});
    })
    .reduce((x, y) => {
      return {
        ...x,
        ...y,
      };
    }, {});
