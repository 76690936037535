import { initSettings } from "./init";
import { thunkSpreader } from "../../../utils/helpers";
import * as thunks from "./thunks";

const thunkEnum = thunkSpreader(thunks);

export const extraReducers = { 
    [initSettings.pending]: (state, action) => {
        state.init.status = "loading";
    },
    [initSettings.fulfilled]: (state, action) => {
        return {
            ...state,
            init: {
                status: "success",
            },
            ...action.payload,
        };
    },
    [initSettings.rejected]: (state, action) => {
        return {
            ...state,
            init: {
                status: "error",
            },
        };
    },
    ...thunkEnum,
}