import Grid from "@mui/material/Grid";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import Typography from "@mui/material/Typography";

export default function ConfigureZonesModalContent(props) {

  const classes = {
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      margin: "1rem 0",
    },
    container: {
      padding: "1.5rem 0",
      textAlign: "center",
    },
  }

  const { selectedFacility, setRedirect } = props;

  return (
    <Grid sx={classes.container} container>
      {/* Content */}
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Facility Map Grid Succesfully Defined!
          You have the ability to configure your zones right away or later in the zone configuration page.
        </Typography>
        <MaterialUiButton
          label="Configure Zones"
          fullWidth={true}
          onClick={() => {
            setRedirect({
              isRedirect: true,
              pathName: "/facilities",
              state: {
                facility: selectedFacility,
                viewZones: true
              },
            });
          }}
          role="link"
          variant="text"
        />
      </Grid>
    </Grid>
  );
}
