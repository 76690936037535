import { Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

const StyledRadio = withStyles({
  root: {},
  checked: {},
})((props) => <Radio {...props} />);

export default function RadioList(props) {
  const {
    label,
    name,
    onChange,
    options = [],
    row = false,
    toolTip,
    value,
  } = props;
  return (
    <FormControl component="fieldset">
      {label ? (
        <Typography variant="h6">
          {label}
          {toolTip ? (
            <Tooltip title={toolTip}>
              <i className="fas fa-info-circle"></i>
            </Tooltip>
          ) : null}
        </Typography>
      ) : null}
      <RadioGroup
        aria-label={name}
        name={name}
        onChange={(event) => {
          onChange(event);
        }}
        row={row}
        value={value}
      >
        {options.map((element, index) => {
          const { label, labelPlacement, value } = element;
          return (
            <FormControlLabel
              control={<StyledRadio />}
              key={value}
              label={label}
              labelPlacement={labelPlacement}
              value={value}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
