import { isEmpty } from "lodash";

const getBatchData = async (props, batchId) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}batches/${batchId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

const getZonesData = async (props) => {
  const { apiUrl, token } = props;

  const results = await fetch(`${apiUrl}zones`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

const getBatchAssets = async (props, batchId) => {
  const { apiUrl, token } = props;
  const results = await fetch(`${apiUrl}batches/${batchId}/assets`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });
  return results;
};

const getProductData = async (props, batch) => {
  const { apiUrl, token } = props;
  const { topologiesList = [], topologyMap = {} } = batch;

  const results = await fetch(`${apiUrl}products`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then(async (p) => {
      const productsFromResponse = p.products;
      const productMap = {};
      productsFromResponse.forEach((element) => {
        const { productId } = element;
        productMap[productId] = element;
      });

      // get product ID for all products in a batch...
      const productArr = [];
      const recurseTopologies = (top) => {
        const obj = {};
        if (Array.isArray(top) && top.length) {
          top.map((el) => {
            if (
              el.productId &&
              productMap[el.productId] &&
              !obj[el.productId]
            ) {
              obj[el.productId] = true;
              productArr.push(productMap[el.productId]);
            }
            if (el.child) {
              recurseTopologies(el.child);
            }
            return null;
          });
        } else {
          if (
            top.productId &&
            productMap[top.productId] &&
            !obj[top.productId]
          ) {
            obj[top.productId] = true;
            productArr.push(productMap[top.productId]);
          }
          if (top.child) {
            recurseTopologies(top.child);
          }
        }
      };

      if (
        (!topologiesList || topologiesList.length === 0) &&
        !isEmpty(topologyMap)
      ) {
        recurseTopologies(topologyMap);
      } else {
        recurseTopologies(topologiesList);
      }

      const returnValue = Array.from(
        new Set(productArr.map((r) => r.productId))
      ).map((id) => {
        return {
          ...productArr.find((res) => res.productId === id),
        };
      });

      return returnValue;
    })
    .catch((err) => {
      console.log(err);
      return { error: err };
    });

  return results;
};

const submitUpdateEvent = async (props, updateEvent) => {
  const { apiUrl, token, facilities, userId } = props;
  const {
    assets = [],
    binLocation = null,
    event,
    location,
    note,
    zone = null,
  } = updateEvent;

  const payload = {
    action: event,
    appUserId: userId,
    assetIds: assets,
    binLocation: binLocation || null,
    facility: facilities[location?.value] || null,
    latitude: null,
    longitude: null,
    propertiesMap: {
      note: note,
    },
    zone:
      zone || binLocation
        ? {
            zoneId: zone || null,
            binLocation: binLocation || null,
          }
        : null,
  };

  if (
    location &&
    facilities[location.value] &&
    facilities[location.value].location
  ) {
    payload.latitude = facilities[location.value]?.location?.latitude || null;
    payload.longitude = facilities[location.value]?.location?.longitude || null;
  }
  const results = await fetch(`${apiUrl}assets/assetsAction`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update event, please contact system administrator.",
      };
    });

  return results;
};

const saveFilterSettings = async (
  props,
  batchHistoryTableSettings
) => {
  const { apiUrl, token, userId } = props;

  // Removing filters that do not need to be saved on the users batch history settings
  [
    "assets",
    "binLocations",
    "endDate",
    "events",
    "locals",
    "locations",
    "pca",
    "start",
    "startDate",
    "type",
    "users",
    "zones",
  ].forEach((item) => {
    if (batchHistoryTableSettings[item]) {
      delete batchHistoryTableSettings[item];
    }
  });

  const payload = {
    propertiesMap: {
      batchHistoryTableSettings: batchHistoryTableSettings,
    },
  };

  const results = await fetch(`${apiUrl}appUsers/${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });
  return results;
};

async function assignSelectedAssetsToDevice(
  props,
  selectedAssetsHash
) {
  const { apiUrl, token } = props;
  const assetsThatDidNotAssociateWithADevice = [];

  Object.keys(selectedAssetsHash).forEach(async (item) => {
    const { deviceId, deviceLabel, label, value } = selectedAssetsHash[item];
    const body = {
      parentId: deviceId,
    };

    await fetch(`${apiUrl}assets/${value}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(body),
    })
      .then(() => {
        fetch(`${apiUrl}assets/${deviceId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
          body: JSON.stringify({
            device: {
              status: "assigned",
            },
          }),
        }).then(() => {
          submitUpdateEvent(
            { ...props },
            {
              asset: {
                label,
                value,
              },
              event: {
                label: `Device ${deviceLabel} Associated`,
                value: `Device ${deviceLabel} Associated`,
              },
            }
          ).then((res) => {
            if (res.error) {
              props.setModal({
                modalShow: true,
                text: "There was a problem updating your assets",
                isError: true,
              });
            }
            props.setState({
              ...props.state,
              histories: {
                ...props.state.histories,
                assetHistories: [res.assetHistory].concat(
                  props.state.histories.assetHistories
                ),
              },
            });
          });
          return;
        });
      })
      .catch(() => {
        assetsThatDidNotAssociateWithADevice.push(item);
      });
  });

  return assetsThatDidNotAssociateWithADevice;
}

const getAppUser = async (props) => {
  const results = await fetch(`${props.apiUrl}appUsers/${props.userId}`, {
    headers: {
      "Content-Type": "application/json",
      "auth-token": props.token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return { error: res.error };
      }
      return res.appUser;
    });

  return results;
};

export {
  assignSelectedAssetsToDevice,
  getAppUser,
  getBatchAssets,
  getBatchData,
  getProductData,
  getZonesData,
  saveFilterSettings,
  submitUpdateEvent,
};
