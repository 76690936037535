import Select from "../../Forms/FieldTypes/Select";
import { cqrFontList, fontList } from "./fontList";

export const FontSelect = (props) => {
  const {
    isDisabled = false,
    label = "Font",
    onChange,
    useCQRFonts = false,
    value,
    variant = "outlined",
  } = props;
  return (
    <Select
      disabled={isDisabled}
      label={label}
      onChange={onChange}
      options={useCQRFonts ? cqrFontList : fontList}
      value={value}
      variant={variant}
    />
  );
};
