import { useEffect, useState } from "react";
import { extractAssetId } from "../../../utils/extractAssetId";
import { getAsset } from "../../../utils/API/Assets/Assets";
import { Grid, TextField, Typography } from "@mui/material";

export default function ScannedAssetsTextField(props) {
  const {
    isHack = false,
    label,
    multiline = false,
    onChange,
    readOnly = false,
    setAssetIdUsedInHack,
    setState,
    token,
    type = "text",
    value = "",
  } = props;
  const [tag, setTag] = useState("");

  function extrapolateAssetId(event) {
    const assetId = extractAssetId(event.target.value) || ""

    // This only runs if we have a valid assetId
    if (assetId.length > 0) {

      // Here in this hack we are throwing the assetId out into the parent.
      // That way when the submit fires off, we can fire off the function to 
      // turn this asset into a container asset. Then finish the rest of the form
      if (isHack) {
        setAssetIdUsedInHack(assetId);
      }

      getAsset({ assetId: assetId, token }).then((res) => {
        setTag(res.asset.tag);
        onChange({ target: { value: res.asset.assetId } });
      });
    } else {
      onChange(event);
    }
  }

  useEffect(() => {
    //  This is to accomodate for when the user clicks "Clear" on the form
    if (value === "" && tag !== "") {
      setTag("");
    }
  }, [tag, value]);

  return (
    <Grid container>
      {/* Title */}
      <Grid item xs={12}>
        <Typography
          align="left"
          component="div"
          display="flex"
          fontSize="14px"
          variant="subtitle1"
        >
          {label}
        </Typography>
      </Grid>

      {/* Input */}
      <Grid item xs={12} sx={{ pb: 2 }}>
        {readOnly ? (
          <Typography variant="subtitle2">{value}</Typography>
        ) : (
          <TextField
            disabled={readOnly}
            id="outlined-basic"
            label=""
            inputProps={{ inputMode: "none" }}
            fullWidth
            multiline={multiline}
            onChange={(event) => {
              extrapolateAssetId(event);
            }}
            type={type}
            value={tag}
          />
        )}
      </Grid>
    </Grid>
  );
}
