import { Box, Typography } from "@mui/material";
import React from "react";

function MapViewLegend(props) {
  const { state, setState } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: "rgba(0, 0, 0, 0.54)",
        fontWeight: 700,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Assets: </Typography>
        <Typography aria-label="green triangle" component="span">
          <i
            className="fas fa-caret-up"
            style={{
              color: "green",
              fontSize: "1.5rem",
              marginRight: "1rem",
            }}
          ></i>
        </Typography>
      </Box>
      {/* Box for radios */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Radios: </Typography>
        <Typography aria-label="black square" component="span">
          <i
            className="fas fa-square"
            style={{
              color: "black",
              marginRight: "1rem",
            }}
          ></i>
        </Typography>
      </Box>
      {/* Box for target zones: */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Target Zones: </Typography>
        <Typography aria-label="blue circle" component="span">
          <i
            className="fas fa-circle"
            style={{
              color: "#947447",
              marginRight: "1rem",
            }}
          ></i>
        </Typography>
      </Box>
      {/* Box for processing zones: */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Processing Zones: </Typography>
        <Typography aria-label="red circle" component="span">
          <i
            className="fas fa-circle"
            style={{
              color: "red",
              marginRight: "1rem",
            }}
          ></i>
        </Typography>
      </Box>
      {/* box for processing zones(highlighted): */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Processing Zones(highlighted): </Typography>
        <Typography aria-label="purple circle" component="span">
          <i
            className="fas fa-circle"
            style={{
              color: "purple",
              marginRight: "1rem",
            }}
          ></i>
        </Typography>
      </Box>
      {/* BOx for target zones(highlighted): */}
      <Box sx={{ display: "flex" }}>
        <Typography component="p">Target Zones(highlighted): </Typography>
        <Typography aria-label="orange circle" component="span">
          <i
            className="fas fa-circle"
            style={{
              color: "#03f8fc",
              marginRight: "5rem",
            }}
          ></i>
        </Typography>
      </Box>
    </Box>
  );
}

export default MapViewLegend;
