import moment from "moment-timezone";
const apiUrl = process.env.REACT_APP_API_ENDPOINT;

// Cancel scan is a api call thats fired off for the receive form.
// The receive form takes a user input, generates a assetId for that input, and then hits it with the costpoint endpoint.
// Thus if the user cancels their interaction mid form. We need to kill the assetId that was generated.
export const cancelScan = async (props) => {
  const { action, assetId = "", token } = props;
  const { formId } = action;
  let body = {
    action: "Cancel Scan",
    propertiesMap: {
      formId,
      formData: [
        {
          fieldType: "cancelScan",
          fieldKey: "assetId",
          fieldValue: assetId,
        },
      ],
    },
  };

  const results = await fetch(`${apiUrl}assets/${assetId}/action`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });
  return results;
};

export const externalScanFromApp = async (props) => {
  const { assetMode = "Inventory", externalId, token } = props;
  const results = await fetch(
    `${apiUrl}assets/externalScanFromApp?forceNewAsset=true`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({ assetMode, externalId, assetType: "Inventory" }),
    }
  )
    .then((res) => res.json())
    .then((json) => json)
    .catch((res) => {
      return res;
    });

  return results;
};

// At some point this function should replace showLastEvents. Need to know more about implementing Redux before
// continuing
export const searchAssets = async (props, providedState) => {
  const {
    assetMode,
    classifications = {},
    facilityId,
    hasShortage,
    inputValue,
    isInventory = false,
    organizationId,
    skip,
    token,
  } = props;
  const { filters = {}, isExport = false, page = 0 } = providedState;
  let {
    assetCategories = {},
    assetIds,
    assetType,
    binLocation,
    binLocations,
    category,
    //destructure classifications from filters under a different name to avoid confusion.
    classifications: classificationsFromFilters,
    customs,
    endDate = null,
    facility,
    isSearchForDevice = false,
    lastEvent,
    lastEvents,
    limit,
    locals,
    locations,
    pca,
    state,
    startDate = null,
    tag,
    user,
    users,
    zone,
    zones,
  } = filters;

  // escape special characters from string inputs
  let escapedAssetType = assetType ? assetType : null;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
        ],
        must_not: [
          // No devices
          {
            terms: {
              // If the user is on the Inventory Snapshot page, then we do not need
              // to exclude this
              asset_mode: ["Device"],
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    skip,
    // Limit behaves differently than I expected on Elastic Search. We have to increment the count up as we skip along.
    limit: isExport ? 1000 : limit || 25,
    range: {},
    page,
    sort: "timeOfLog DESC",
  };

  if (!isSearchForDevice) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      bool: {
        must_not: [
          {
            nested: {
              path: "device",
              query: {
                exists: {
                  field: "device.status",
                },
              },
            },
          },
        ],
      },
    });
  }

  // Logic for inventory searches
  if (isInventory) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_mode: "Inventory",
      },
    });
  }

  // TODO - Elastic - You need to go back to bitBucket and find out where some of those unused variables were previously being used
  // Asset Tag
  if (assetIds && assetIds.length) {
    const assetIdArray = assetIds.map((assetId) => {
      return assetId.value;
    });

    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: {
        asset_id: assetIdArray,
      },
    });
  }

  // Asset Mode
  if (assetMode === "Inventory") {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_mode: "Inventory",
      },
    });
  }

  // Asset Type - TODO - Im adding this is now to mimic the old SOLR Query. But Im starting to think state.assetType never gets hit.
  if (escapedAssetType) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: {
        asset_type: escapedAssetType,
      },
    });
  }

  // Bin Location
  if (binLocation?.length || binLocations?.length) {
    let binLocationsArray = [];

    if (binLocation?.length) {
      binLocationsArray = binLocation?.map((bin) => {
        return {
          term: {
            "zone.bin_location.keyword": bin.value,
          },
        };
      });
    } else {
      binLocationsArray = binLocations?.map((bin) => {
        return {
          term: {
            "zone.bin_location.keyword": bin.value,
          },
        };
      });
    }

    elasticQuery.elasticSearchQuery.bool.must.push({
      bool: {
        should: [
          {
            nested: {
              path: "zone",
              query: {
                bool: {
                  should: binLocationsArray,
                },
              },
            },
          },
        ],
      },
    });
  }

  // Categories - we have to accomodate assetcategories and categories due to users with this presaved.
  if (
    assetCategories?.length > 0 ||
    (category?.length > 0 && Array.isArray(category))
  ) {
    // We build up the array
    const categoryArray =
      assetCategories?.length > 0
        ? assetCategories
        : category.map((cat) => {
          return cat.value;
        });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { category: categoryArray },
    });
  }

  // Classifications
  const classificationFilters = Object.keys(filters || {})
    .filter(
      (filterKey) => classifications[filterKey] && filters[filterKey] !== null
    )
    .map((filterKey) => filters[filterKey]);

  if (classificationFilters?.length) {
    classificationFilters?.map((classArray) => {
      // We initially declare the object we are going to push into the query
      let queryObject = {
        nested: {
          path: "classification_data",
          query: {
            bool: {
              must: [],
              should: [],
              minimum_should_match: 1,
            },
          },
        },
      };

      // We build up the queryObject here
      classArray?.map((childClass) => {
        // Make sure the parentId is present
        if (queryObject.nested.query.bool.must.length === 0) {
          queryObject.nested.query.bool.must.push({
            match: {
              "classification_data.key.keyword": childClass.parentLabel,
            },
          });
        }

        // Push the children
        queryObject.nested.query.bool.should.push({
          match: {
            "classification_data.value.keyword": childClass.label,
          },
        });
        return null;
      });

      elasticQuery.elasticSearchQuery.bool.must.push(queryObject);

      return null;
    });
  }

  let shouldMatchCount = 0;
  // Customs
  if (customs && customs.length > 0) {
    customs.forEach((item) => {
      // Makes sure all fields without a value are not included in the query
      if (item.value !== "") {
        // This sets up for should matches
        shouldMatchCount += 1;

        elasticQuery.elasticSearchQuery.bool.should.push({
          nested: {
            path: "custom_data",
            query: {
              bool: {
                must: [
                  {
                    match: {
                      "custom_data.key.keyword": item.label,
                    },
                  },
                  {
                    match: {
                      "custom_data.value.keyword": item.value,
                    },
                  },
                ],
              },
            },
          },
        });
      }
    });
  }

  //Classifications From Filters
  if (classificationsFromFilters && classificationsFromFilters.length > 0) {
    classificationsFromFilters.forEach((item) => {
      // This sets up for should matches
      shouldMatchCount += 1;

      elasticQuery.elasticSearchQuery.bool.should.push({
        nested: {
          path: "classification_data",
          query: {
            bool: {
              must: [
                {
                  match: {
                    "classification_data.key.keyword": item.label,
                  },
                },
                {
                  match: {
                    "classification_data.value.keyword": item.value,
                  },
                },
              ],
            },
          },
        },
      });
    });
  }

  if (shouldMatchCount > 0) {
    elasticQuery.elasticSearchQuery.bool.minimum_should_match =
      shouldMatchCount;
  }
  //else set shouldMatchCount to null
  else {
    elasticQuery.elasticSearchQuery.bool.minimum_should_match = null;
  }

  // Event
  if ((lastEvents && lastEvents?.length) || (lastEvent && lastEvent?.length)) {
    // We Build up the array
    const lastEventArray =
      lastEvents && lastEvents?.length
        ? lastEvents
        : lastEvent.map((event) => {
          return event.value;
        });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { last_event: lastEventArray },
    });
  }

  // Facility (multiple)
  if ((locations && locations.length) || (facility && facility.length)) {
    // We build the array
    let locationsSet =
      locations && locations.length
        ? locations
        : facility.map((e) => {
          return e.value?.facilityId || e;
        });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { facility_id: locationsSet },
    });
  }

  // Facility (single)
  if (facilityId) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      term: { facility_id: facilityId },
    });
  }

  // Has Shortage - Some of our dashboard widgets use this
  if (hasShortage) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      bool: {
        must: [
          {
            nested: {
              path: "quantity",
              query: {
                term: {
                  "quantity.has_shortage": true,
                },
              },
            },
          },
        ],
      },
    });
  }

  // Input Value - Wild card searches from the different Filters Menu
  if (inputValue) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      regexp: {
        "tag.wildcard": {
          value: `${inputValue}.*`,
        },
      },
    });

    elasticQuery.sort = "tag ASC";
  }

  // PCA
  if (pca) {
    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { flagged: true },
    });
  }

  // State - Some components are using the word locals and some components are using the word state. Need to unify this
  if ((locals && locals.length) || (state && state.length)) {
    if (locals && locals.length > 0) {
      let localsArray = locals.map((e) => {
        return e.value;
      });

      // And then push it to the query
      elasticQuery.elasticSearchQuery.bool.must.push({
        terms: { state: localsArray },
      });
    } else {
      let statesArray = state.map((e) => {
        return e.value;
      });

      elasticQuery.elasticSearchQuery.bool.must.push({
        terms: { state: statesArray },
      });
    }
  }

  // Tag
  if (tag) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      regexp: {
        "tag.wildcard": {
          value: `${tag}.*`,
        },
      },
    });
  }

  // Users
  if (users?.length || user?.length) {
    // We Build the Array
    const usersArray = users?.length
      ? users
      : user.map((user) => {
        return user.value;
      });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      terms: { app_user_id: usersArray },
    });
  }

  // Zones
  if ((zones && zones.length) || (zone && zone.length)) {
    let zonesArray =
      zones && zones.length
        ? zones
        : zone.map((zone) => {
          const { internalZoneType = null, value = "" } = zone;

          // if {zoneId}.internalZoneId === 'processing' then we know the zone is a
          // pZone. If that value is === 'target' then we know the zone is a tZone.

          if (internalZoneType === "processing") {
            return { "zone.p_zone_id": value };
          } else if (internalZoneType === "target") {
            return { "zone.t_zone_id": value };
          } else {
            return { "zone.zone_id": value };
          }
        });

    // And then push it to the query
    elasticQuery.elasticSearchQuery.bool.must.push({
      nested: {
        path: "zone",
        query: {
          bool: {
            must: [
              {
                terms: {
                  "zone.zone_id": zonesArray,
                },
              },
            ],
          },
        },
      },
    });
  }

  // convert time to UTC time, e.g., if EST time add four hours, since events are stored in UTC / greenwich mean time in the database
  if (moment(startDate).isValid() || moment(endDate).isValid()) {
    elasticQuery.elasticSearchQuery.bool.must.push({
      range: {
        time_of_log: {
          gte: moment(startDate).isValid()
            ? moment(startDate).startOf("day").valueOf()
            : null,
          lte: moment(endDate).isValid()
            ? moment(endDate).endOf("day").valueOf()
            : "now",
        },
      },
    });
  }

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });
  return results;
};

export const getAsset = async (props) => {
  const { assetId = "", token } = props;

  const results = await fetch(`${apiUrl}assets/${assetId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });
  return results;
};

// This api call is used primarly with forms. 
export const turnAssetIntoAContainerAsset = async (props) => {
  const { assetId, token } = props;
  const body = { assetMode: "Container", assetType: "Pallet" };

  const results = await fetch(`${apiUrl}assets/${assetId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return {
        error: "Failed to update data, please contact system administrator.",
      };
    });

  return results;
};
