import { useEffect, useState } from "react";
import { Grid } from "@mui/material/";
import { isEqual } from "lodash";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import HorizontalTabs from "../../../components/Tabs/HorizontalTabs";
import InventoryContent from "../InventoryContent/InventoryContent";
import InventoryHistory from "../inventoryHistory/InventoryHistory";
import InventoryStatus from "../inventoryStatus/InventoryStatus";
import DailyEvents from "../InventoryDailyEvents/DailyEvents";
import InventoryAnalysis from "../InventoryAnalysis/InventoryAnalysis";
import Movement from "../InventoryMovement/Movement";
import MapView from "../InventoryMapView/MapView";

export default function Inventory(props) {
  const [tab, setTab] = useState(0);
  const classifications = useSelector(
    (state) => state.organization.classifications,
    isEqual
  );
  const customs = useSelector((state) => state.organization.customs, isEqual);
  const facilities = useSelector(
    (state) => state.organization.facilities,
    isEqual
  );
  const history = useHistory();

  // This useEffect looks at the query params and will manually control
  // the Horizontal Tabs below
  useEffect(() => {
    const currentTab = history.location.search;
    if (currentTab === "?tab=status") {
      setTab(0);
    } else if (currentTab === "?tab=history") {
      setTab(1);
    } else if (currentTab === "?tab=create") {
      setTab(2);
      //movement tab is disabled for now which causes a change in the tab indeces
    } else if (currentTab === "?tab=movement") {
      setTab(3);
    } else if (currentTab === "?tab=daily") {
      setTab(4);
    } else if (currentTab === "?tab=itemAnalysis") {
      setTab(5);
    } else if (currentTab === "?tab=analysis") {
      setTab(6);
    }
    else if (currentTab === "?tab=mapDisplay") {
      setTab(7);
    }
    
    // } else if (currentTab === "?tab=daily") {
    //   setTab(3);
    // } else if (currentTab === "?tab=itemAnalysis") {
    //   setTab(4);
    // } else if (currentTab === "?tab=analysis") {
    //   setTab(5);
    // }
  }, [history.location.search]);

  return (
    <Grid container>
      {/* Generate CQR for newly created asset Dialog - Triggers when someone finishes creating a new inventory asset */}
      <Grid item xs={12}>
        <HorizontalTabs
          appendQueryParams
          customValue={tab}
          fullWidth={true}
          noAppBarColor
          noElevation
          position="sticky"
        >
          {/* Inventory Status */}
          <InventoryStatus
            {...props}
            label="Status"
            onClick={() => {
              history.push({ search: "?tab=status" });
              setTab(0);
            }}
          />

          {/* Inventory History */}
          <InventoryHistory
            {...props}
            label="History"
            onClick={() => {
              history.push({ search: "?tab=history" });
              setTab(1);
            }}
          />

          {/* Create New Inventory Asset */}
          <InventoryContent
            {...props}
            customs={customs}
            classifications={classifications}
            facilities={facilities}
            label="Create New Item"
            onClick={() => {
              history.push({ search: "?tab=create" });
              setTab(2);
            }}
          />

          {/* Movement */}
          <Movement
            {...props}
            label="Movement"
            onClick={() => {
              history.push({ search: "?tab=movement" });
              setTab(3);
            }}
          />

          {/* Daily Events */}
          <DailyEvents
            {...props}
            label="Daily"
            onClick={() => {
              history.push({ search: "?tab=daily" });
              setTab(4);
            }}
          />
          {/* Item Analysis */}
          <InventoryAnalysis
            {...props}
            isItemAnalysis={true}
            label="Item Analysis"
            useSelector={useSelector}
            onClick={() => {
              history.push({ search: "?tab=itemAnalysis" });
              setTab(5);
            }}
          />

          {/* Event Analysis */}
          <InventoryAnalysis
            {...props}
            isItemAnalysis={false}
            label="Event Analysis"
            useSelector={useSelector}
            onClick={() => {
              history.push({ search: "?tab=analysis" });
              setTab(6);
            }}
          />

          {/* Map Display */}
          <MapView
            {...props}
            label="Map Display"
            onClick={() => {
              history.push({ search: "?tab=mapDisplay" });
              setTab(7);
            }}
          />
        </HorizontalTabs>
      </Grid>
    </Grid>
  );
}
