import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function TextFormatButtonGroup(props) {

  const classes = {
    positioning: {
      paddingTop: ".85rem",
      marginLeft: ".5rem"
    },
  }

  const {
    disabled = false,
    textFormats = [],
    onChange,
    size = "small",
  } = props;

  const handleFormat = (event, newFormats) => {
    onChange(newFormats);
  };

  return (
    <ToggleButtonGroup
      aria-label="text formatting"
      sx={classes.positioning}
      exclusive
      onChange={handleFormat}
      size={size}
      value={textFormats}
    >
      <ToggleButton disabled={disabled} value="bold" aria-label="bold">
        <FormatBoldIcon />
      </ToggleButton>
      <ToggleButton disabled={disabled} value="italic" aria-label="italic">
        <FormatItalicIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
