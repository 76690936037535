import { InputLabel } from "@mui/material";
import {
  Box,
  FormControl,
  InputAdornment,
  Input,
  OutlinedInput,
} from "@mui/material";

export default function CurrencyTextBox(props) {
  const {
    error,
    fullWidth = true,
    label,
    labelPlacement = "top",
    labelStyles = {},
    onChange,
    size = "small",
    value,
    variant = "outlined",
  } = props;

  return (
    <Box
      sx={
        labelPlacement === "start"
          ? {
            display: "flex",
            alignItems: "center",
          }
          : {}
      }
    >
      <Box
        sx={{
          color: "#6D6E70",
          display: "flex",
          fontFamily: "Lato",
          fontWeight: "bold !important",
          justifyContent: "space-between",
          lineHeight: 1,
          marginBottom: `${labelPlacement === "start" ? "inherit" : ".5rem"}`,
          marginTop: `${labelPlacement === "start" ? "inherit" : ".2rem"}`,
          marginRight: `${labelPlacement === "start" ? ".5rem" : ""}`,
          ...labelStyles,
        }}
      >
        <InputLabel
          sx={{
            color: "#6D6E70",
            fontFamily: "Lato",
            fontWeight: "bold",
          }}
        >
          {label}
        </InputLabel>
      </Box>
      <FormControl
        className="simple-select"
        error={error}
        size={size}
        sx={{ width: "100%" }}
        variant={variant ? variant : "standard"}
      >
        {variant === "outlined" ? (
          <OutlinedInput
            currencySymbol=""
            error={error}
            fullWidth={fullWidth}
            helperText={`${error ? "Required Field" : ""}`}
            onChange={(event) => {
              onChange(event);
            }}
            outputFormat="string"
            size={size}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            type="number"
            value={value}
          />
        ) : (
          <Input
            currencySymbol=""
            error={error}
            fullWidth={fullWidth}
            helperText={`${error ? "Required Field" : ""}`}
            onChange={(event) => {
              onChange(event);
            }}
            outputFormat="string"
            size={size}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            style={{ marginTop: "13px" }}
            type="number"
            value={value}
          />
        )}
      </FormControl>
    </Box>
  );
}
