import { Alert, AlertTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";

function MaterialConfirmationModal(props) {
  const {
    closeModal,
    content,
    modalOpen,
    severity = "success",
    title,
    variant = "filled",
  } = props;
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={() => {
        closeModal();
      }}
      open={modalOpen || false}
    >
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              closeModal();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity={severity} variant={variant}>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {content}
      </Alert>
    </Dialog>
  );
}

export default MaterialConfirmationModal;
