import { Box, Typography } from '@mui/material';
import { deleteAssembly } from '../api';
import { naturalSort } from '../../../utils/naturalSort';
import ConfirmationModalContent from '../../../components/Modals/ConfirmationModalContent';
import ModalDialog from '../../../components/Modals/ModalDialog/ModalDialog';
import React, { useState } from 'react'
import SimpleList from '../../../components/Lists/SimpleList';

function Assemblies(props) {

  const { setState, state } = props;

  const histories = state?.histories?.histories || [];

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [selectedAssembly, setSelectedAssembly] = useState(null);

  const assemblyList = [];

  const usedLabels = {};

  histories.forEach(item => {
    if (item.assemblyName && item.event) {
      const potentialLabel = `(${item.event}) - ${item.assemblyName}`;

      // Check if the potential label is not already used
      if (!usedLabels[potentialLabel]) {
        assemblyList.push({
          label: potentialLabel,
          id: item.assemblyId,
        });

        // Mark the label as used
        usedLabels[potentialLabel] = true;
      }
    }
  });

  function handleRemoveAssembly() {

    deleteAssembly(props, selectedAssembly).then((res) => {
      if (res.success) {
        // filter out the assembly that was removed
        const filteredAssemblies = assemblyList.filter(assembly => assembly.assemblyId !== selectedAssembly)
        setState((prevState) => {
          return {
            ...prevState,
            assetData: {
              ...prevState.assetData,
              assemblyList: filteredAssemblies
            },
          };
        });
        // close the modal
        setConfirmationModalIsOpen(false)
        setSelectedAssembly(null)
      }
    })

  }

  return (
    <>
      <ModalDialog
        handleClose={() => {
          setConfirmationModalIsOpen(false);
        }}
        open={confirmationModalIsOpen}
        title={"Assembly Removal"}
        content={
          <ConfirmationModalContent
            content={`Are you sure you want to remove this assembly?`}
            handleSubmit={handleRemoveAssembly}
            handleCancel={() => {
              setConfirmationModalIsOpen(false);
            }}
          />
        }
      />
      <Box
        sx={{ display: "flex", flexDirection: "column", maxHeight: "50vh", overflow: "auto" }}
      >
        <Typography variant="h6" sx={{ alignSelf: "flex-start" }}>
          (Event) - Assembly Name
        </Typography>
        <SimpleList
          items={
            assemblyList
              .sort((a, b) => naturalSort(a.label, b.label))
          }
        // onClickRemove={(assemblyId) => {
        //   setConfirmationModalIsOpen(true);
        //   setSelectedAssembly(assemblyId);
        // }}
        />
      </Box>
    </>
  )
}

export default Assemblies