export default function modifyColumns(props) {
  const { columnOrder, historyTableColumns } = props
  let modifiedHistoryTableColumns = [];

  columnOrder.forEach((item) => {
    const { displayName, displayOrder, displayVisible = true, name } = item;
    const itemsIndex = historyTableColumns.findIndex(
      (element) => element.field === name
    );
    const originalHeaderName = historyTableColumns[itemsIndex]?.headerName;

    // If the user has chosen to have this visible...
    // If the itemsIndex === -1 this tells us that this is an option on the saved columnOrder
    // but IS NOT a valid option on the historyTableColumns. This code was written before we are 
    // given the ability to actually delete customs or classifications. We are having problems with
    // them randomly disapearing. This is a temporary fix to prevent the app from displaying rows 
    // that do not need to be there.
    if (displayVisible && itemsIndex !== -1) {
      modifiedHistoryTableColumns.push({
        ...historyTableColumns[itemsIndex],
        displayOrder,
        headerName:
          originalHeaderName !== displayName ? displayName : originalHeaderName,
      });
    }
  });

  // We sort by the displayOrder
  modifiedHistoryTableColumns.sort((a, b) => a.displayOrder - b.displayOrder);

  return { historyTableColumns: modifiedHistoryTableColumns };
}
