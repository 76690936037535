import { InputLabel } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function SimpleSwitch(props) {

  const classes = {
    label: {
      color: "#6D6E70",
      fontFamily: "Lato Semibold",
      fontWeight: "bold",
      marginBottom: 0
    },
  }

  const {
    checked,
    color = "primary",
    disabled = false,
    formControlLabelStyles,
    inputProps = {},
    label = "",
    labelPlacement,
    labelStyles,
    onChange,
    required,
    size,
  } = props;
  return (
    <FormControlLabel
      sx={formControlLabelStyles}
      control={
        <Switch
          checked={checked}
          color={color}
          disabled={disabled}
          inputProps={inputProps}
          onChange={onChange}
          required={required}
          size={size}
        />
      }
      label={
        <InputLabel sx={{...classes.label, ...(labelStyles || {}) }}>
          {label}
        </InputLabel>
      }
      labelPlacement={labelPlacement}
    />
  );
}
