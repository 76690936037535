import { useSelector, useDispatch } from "react-redux";

import { CssBaseline, Typography } from "@mui/material";

import { hideAlert } from "../../globalStore/slices/alert/alertSlice";

import MaterialConfirmationModal from "../Modals/MaterialConfirmationModal";

export default function NoAuthLayout(props) {

  const classes = {
    root: {
      display: "flex",
      height: "100vh",
      fontSize: "1rem",
      lineHeight: "1.5",
      overflow: "auto",
    },
    content: {
      minHeight: "100vh",
      flexGrow: 1,
    },
  };
  
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alert);

  return (
    <>
      <CssBaseline />
      <Typography component="div" sx={classes.root}>
        <Typography component="main" sx={classes.content}>
          <MaterialConfirmationModal
            closeModal={() => {
              dispatch(hideAlert({}));
            }}
            content={alert.text}
            modalOpen={alert.show}
            severity={alert.type || "error"}
          />
          {props.children ? props.children : null}
        </Typography>
      </Typography>
    </>
  );
}
