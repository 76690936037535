import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MaterialUiButton from "../Buttons/MaterialUiButton/MaterialUiButton"
import Modal from "@mui/material/Modal";

export default function ImageModal(props) {
  const classes = {
    container: {
      padding: "1rem 0;",
      textAlign: "center",
      border: "1px solid red",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "1rem",

    },
    image: {
      width: "100%",
    },
    imgName: {
      selfAlign: "center",
      color: "white",
      fontWeight: "bold",
      margin: "1rem 0",
    },
  }

  const { modalOpen, setModalOpen, image } = props;
  const { preview, name } = image;
  const body = (
    <Grid container sx={classes.container}>
      <Grid item xs={12}>
        <Typography sx={classes.imgName} component={"h4"} variant="h4">{name}</Typography>
        <Grid item xs={12} >
          <MaterialUiButton
            color="primary"
            // fullWidth={true}
            label="Close"
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </Grid>

      </Grid>
      <Grid item xs={12} >
        <img src={preview} sx={classes.image} alt="preview" />
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
    >
      {body}
    </Modal>
  );
}
