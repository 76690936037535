import { Box } from "@mui/material";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";

export default function EventAndUserFilters(props) {
  const {
    compareFn,
    fieldStyle,
    organization = {},
    setState,
    state = {},
  } = props;
  const { filters = {} } = state;
  const { events, users } = filters;
  const { eventTypesMap = [] } = organization;
  const eventSelectOptions = Object.keys(eventTypesMap)
    .map((item) => ({
      label: item,
      value: item,
    }))
    .sort((a, b) => compareFn(a, b));
  const userSelectOptions = useSelector(
    (state) =>
      Object.keys(state.organization.usersMap).map((appUserId) => {
        const user = state.organization.usersMap[appUserId];
        const { firstName = "", lastName = "" } = user;
        return { label: `${firstName} ${lastName}`, value: appUserId };
      }),
    isEqual
  ).sort((a, b) => compareFn(a, b));

  return (
    <>
      {/* Events */}
      <Box sx={fieldStyle}>
        <SimpleSelect
          label="Events"
          onChange={(event) =>
            setState((prevState) => ({
              ...prevState,
              filters: {
                ...prevState.filters,
                events: event.target.value,
              },
            }))
          }
          onChipDelete={(event) => {
            setState((prevState) => {
              let newState = { ...prevState };

              // Remove the old item from the array
              newState.filters.events = newState.filters.events.filter(
                (item) => item !== event
              );

              return newState;
            });
          }}
          multiple
          options={eventSelectOptions}
          size="large"
          value={events}
          variant="outlined"
        />
      </Box>

      {/* User */}
      <Box sx={fieldStyle}>
        <SimpleSelect
          label="Users"
          onChange={(event) =>
            setState((prevState) => ({
              ...prevState,
              filters: {
                ...prevState.filters,
                users: event.target.value,
              },
            }))
          }
          onChipDelete={(event) => {
            setState((prevState) => {
              let newState = { ...prevState };

              // Remov ethe old item from the array
              newState.filters.users = newState.filters.users.filter((item) => {
                return item !== event;
              });

              return newState;
            });
          }}
          multiple
          options={userSelectOptions}
          size="large"
          variant="outlined"
          value={users}
        />
      </Box>
    </>
  );
}
