import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Redirect } from "react-router-dom";
import { showAlert } from "../../../globalStore/slices/alert/alertSlice";
import {
  updateFacilities,
  removeFacility,
} from "../../../globalStore/slices/organization/organizationSlice";
import ConfirmationModalContent from "../../../components/Modals/ConfirmationModalContent";
import CreateFacility from "./CreateFacility/CreateFacility";
import EditFacility from "./EditFacility/EditFacility";
import FacilityDetail from "./FacilityDetail/FacilityDetail";
import FacilityTable from "./FacilityTable/FacilityTable";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import Loading from "../../../components/Loading/Loading";
import ModalDialog from "../../../components/Modals/ModalDialog/ModalDialog";

// Component uses xlxs which is very heavy, which is why we are using lazy/Suspense
const ImportLocations = lazy(() => import("./ImportLocations/ImportLocations"));

export default function Facilities(props) {
  const {
    apiUrl,
    facilities,
    dispatchGlobal,
    organization,
    permissions = {},
    setView = {},
    token,
  } = props;
  const [state, setState] = useState({
    facilities: [],
  });
  const [fullScreenModal, setFullScreenModal] = useState(false);
  const [dialog, setDialog] = useState({
    dialogTitle: "",
    dialogShow: false,
    selectedLocation: {},
  });
  const [redirect, setRedirect] = useState({
    isRedirect: false,
    selectedFacility: "",
  });

  const mounted = useRef(false);
  // first render useEffect, retrieve facilities; retrieve facilities function is memoized via useCallback so it can be used in the useEffect dep array without creating an infinite loop
  useEffect(() => {
    const { state: routerState = {} } = props.location;
    const { modalShow = false, modal = "" } = routerState;

    setState((prevState) => {
      return {
        ...prevState,
        facilities: Object.keys(facilities).map((k) => facilities[k]),
      };
    });

    if (modalShow) {
      setDialog({ dialogTitle: modal, dialogShow: modalShow });
    }

    mounted.current = true;
    // This controls the quickLinks and modal behavior
    return props.history.listen((location) => {
      setDialog({ dialogTitle: modal, dialogShow: modalShow });
    });
  }, [facilities, props.history, props.location]);

  const handleDelete = (id) => {
    fetch(`${apiUrl}facilities/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify({ deleteImages: true }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          //invoke removeFacility to update the store
          dispatchGlobal(removeFacility({ facilityId: id }));
          handleConfirmationModal("Facility Successfully Deleted");
          dialogClose();
        }
      })
      .catch((err) => {
        handleConfirmationModal("There was a problem", true);
      });
  };

  const handleConfirmationModal = (text, error) => {
    dispatchGlobal(
      showAlert({ type: error ? "error" : "success", text: text })
    );
  };

  const dialogClose = () =>
    setDialog({
      ...dialog,
      dialogShow: false,
      dialogTitle: "",
      selectedLocation: {},
    });

  const { dialogShow, selectedLocation, dialogTitle } = dialog;
  const { propertiesMap = {} } = organization;
  const { locationTypes = [] } = propertiesMap;

  const switchDialog = (dialog) => {
    switch (dialog) {
      case "Facility Detail":
        return (
          <FacilityDetail
            handleClose={() => {
              dialogClose();
            }}
            open={true}
            token={token}
            apiUrl={apiUrl}
            locationTypes={locationTypes}
            facility={selectedLocation}
            notificationModal={handleConfirmationModal}
          />
        );
      case "Create Facility":
        return (
          <CreateFacility
            apiUrl={apiUrl}
            dispatchGlobal={dispatchGlobal}
            hasFormFields={true}
            locationTypes={locationTypes}
            notificationModal={handleConfirmationModal}
            onHide={dialogClose}
            token={token}
            updateFacilities={updateFacilities}
          />
        );
      case "Import Facilities":
        return (
          <Suspense fallback={<Loading />}>
            <ImportLocations
              apiUrl={apiUrl}
              locationTypes={locationTypes}
              notificationModal={handleConfirmationModal}
              onHide={dialogClose}
              onSuccess={() => console.log("YOU NEED TO WORK ON THIS")}
              organization={organization}
              token={token}
            />
          </Suspense>
        );
      case "Edit Facility":
        return (
          <EditFacility
            apiUrl={apiUrl}
            dispatchGlobal={dispatchGlobal}
            facility={selectedLocation}
            hasFormFields={true}
            locationTypes={locationTypes}
            match={{
              params: { facilityId: selectedLocation.facilityId },
            }}
            notificationModal={handleConfirmationModal}
            onHide={dialogClose}
            onSuccess={() => console.log("YOU NEED TO WORK ON THIS")}
            token={token}
            updateFacilities={updateFacilities}
          />
        );
      case "Delete Facility":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedLocation.name}? This would result in ALL associated uploaded images being deleted. This action cannot be
            undone.`}
            handleSubmit={() => {
              handleDelete(selectedLocation.facilityId);
            }}
            handleCancel={() => {
              dialogClose();
            }}
          />
        );
      default:
        return;
    }
  };

  function toolBarButtons() {
    return (
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        <HoverIconButton
          cypressId="facilities-btn-create-facility"
          icon="fas fa-plus"
          iconDirection="right"
          handleClick={() =>
            setDialog({
              ...dialog,
              dialogShow: true,
              dialogTitle: "Create Facility",
            })
          }
          text="Create Facility"
        />

        <HoverIconButton
          cypressId="facilities-btn-import-facilities"
          icon="fas fa-file-import"
          iconDirection="right"
          handleClick={() =>
            setDialog({
              ...dialog,
              dialogTitle: "Import Facilities",
              dialogShow: true,
            })
          }
          text="Import Facilities"
        />

        <HoverIconButton
          cypressId="facilities-btn-map"
          icon="fas fa-map"
          iconDirection="right"
          handleClick={() =>
            setView({
              viewFacilitiesMap: true,
            })
          }
          text="Map"
        />
      </Grid>
    );
  }

  return (
    <Grid container>
      <Box mt={3} width="100%">
        {redirect.isRedirect ? (
          <Redirect
            to={{
              pathname: "/facilities/overview",
              state: { selectedFacility: redirect.selectedFacility },
            }}
          />
        ) : null}
        <ModalDialog
          handleClose={dialogClose}
          open={dialogShow}
          title={`${dialogTitle}${selectedLocation?.name ? ` - ${selectedLocation?.name}` : ""
            }`}
          content={switchDialog(dialogTitle)}
          fullScreen={fullScreenModal}
        />
        <Grid item xs={12}>
          {/* Header */}
          <Box mt={4} mb={3} textAlign="left">
            <Typography component="h1" variant="h4">
              Facilities
            </Typography>
          </Box>
        </Grid>
        {/* Table */}
        <Grid item xs={12}>
          <FacilityTable
            state={state}
            diaog={dialog}
            setDialog={setDialog}
            setRedirect={setRedirect}
            permissions={permissions}
            setView={setView}
            toolBarButtons={toolBarButtons}
          />
        </Grid>
      </Box>
    </Grid>
  );
}
