import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  OverlayView,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api';

const DynamicIconRender = lazy(() =>
  import("../IconPicker/DynamicIconRender")
);

const containerStyle = {
  width: '100%',
  height: '100%'
};

const libraries = ['places', 'drawing', 'marker', 'core']

// Things about this component:
// 1. Renders markers on the map
// 2. Renders popUpContent when a marker is clicked
// 3. Centers the map when a marker is clicked
// 4. Renders icons on the map
// 5. Animates the map when the user navigates to different points
// 6. This is a controlled component. So whatever invokes this will need to set 
// the default location / first item. 

export default function GoogleMapComponent(props) {
  // Event Types map is required if you want to render icons.
  const {
    eventTypesMap = {},
    mapSettings = { center: { lat: 60.363007, lng: -43.63390 }, zoom: 5 },
    markersToRender = [],

    // Marker key is what is used as a key when we iterate through the given markers
    markerKey = 'assetHistoryId',

    // popUpContent is what appears when a marker is clicked.
    popUpContent = null,

    // Polylines show 'trips' devices take instead of a line of markers
    polylines = [],

    // polylinePopUpContent is what appears when a polyline is clicked.
    polylinePopUpContent = null
  } = props;
  const {
    center = { lat: 60.363007, lng: -43.63390 },
    zoom = 5
  } = mapSettings
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_MAP_TOKEN
  })
  const mapRef = useRef();
  const [activeMarker, setActiveMarker] = useState(null);

  // We require two seperate info windows for markers and polylines.
  // The info window that sits on the marker is styled to sit directly ontop of the marker.
  // The UI is thrown off when we abstract this away from the marker component 
  const [polylineInfoWindowState, setPolylineInfoWindowState] = useState({
    isOpen: false,
    position: null
  })

  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
  }, [])

  // This useEffect is to animate the map as the user navigates to different points
  useEffect(() => {
    if (mapRef.current?.panTo && mapSettings.center && mapSettings.center.lat !== undefined && mapSettings.center.lng !== undefined)
      mapRef.current?.panTo(mapSettings.center)

  }, [mapSettings.center])

  return isLoaded ?
    <>
      <GoogleMap
        center={center}
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        options={{ zoomControl: true }}
        zoom={zoom}
      >

        {/* Info window for Polylines */}
        {polylineInfoWindowState.isOpen ? <InfoWindow
          position={polylineInfoWindowState.position}
          onCloseClick={() => setPolylineInfoWindowState({ isOpen: false, position: null })}
        >
          {polylinePopUpContent(polylineInfoWindowState)}
        </InfoWindow> : null}

        {/* Markers */}
        {markersToRender.length > 0 ? markersToRender.map((marker, index) => {
          const { event = '', latitude = 0, longitude = 0 } = marker

          // We dont know where the items are going to be passed from. So we need to 
          // define that when we invoke this component. The 'marker' variable looks 
          // like {...normalAssetData, assetHistoryId: xxxxx} and the markerKey is a string "assetHistoryId".
          // So when we invoke marker[markerKey] we get the assetHistoryId.

          const uniqueIdentifierUUID = marker[markerKey]

          return (
            (eventTypesMap[event] && eventTypesMap[event].icon) ?
              <OverlayView
                key={uniqueIdentifierUUID}
                position={{ lat: latitude, lng: longitude }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <Suspense>
                  <div onClick={() => setActiveMarker(uniqueIdentifierUUID)}>
                    <DynamicIconRender
                      iconName={eventTypesMap[event]?.icon || "LocationOn"}
                    />
                  </div>
                  {(activeMarker === uniqueIdentifierUUID && popUpContent !== null) ?
                    <InfoWindow position={{ lat: latitude, lng: longitude }} onCloseClick={() => setActiveMarker(null)}>
                      {popUpContent(marker)}
                    </InfoWindow>
                    : null}
                </Suspense>
              </OverlayView>
              :
              <Marker
                key={uniqueIdentifierUUID}
                position={{ lat: latitude, lng: longitude }}
                onClick={(e) => {
                  // This is the onClick that happens when you click on a marker.
                  // This is how you are going ot center the map when you click on a table event
                  setActiveMarker(uniqueIdentifierUUID)
                }}
              >
                {/* Clicking on a marker sets the active marker as the unique identifier. This lets us know which marker to display */}
                {(activeMarker === uniqueIdentifierUUID && popUpContent !== null) ? <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  {popUpContent(marker)}
                </InfoWindow> : null}
              </Marker>

          )
        }) : null}

        {/* Polylines */}
        {polylines.length > 0 ? polylines.map((polyline, index) => {
          const { path = [], color = 'blue', weight = 5 } = polyline

          return <Polyline
            key={index}
            onClick={(e) => {
              setPolylineInfoWindowState({
                data: polyline,
                isOpen: true,
                position: { lat: e.latLng.lat(), lng: e.latLng.lng() }
              })
            }}
            options={{ strokeColor: color, strokeWeight: weight }}
            path={path}
          >
          </Polyline>
        }) : null}
      </GoogleMap ></> : null
}