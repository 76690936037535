import React from "react";

import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/styles";
import { Typography } from "@mui/material";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  controlledPagination,
}) => {

  const theme = useTheme();

  const classes = {
    search: {
      display: "flex",
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      border: "1px #A6A8AB solid",
      // backgroundColor: fade(theme.palette.common.white, 0.15),
      // "&:hover": {
      //   backgroundColor: fade(theme.palette.common.white, 0.25),
      // },
  
      // marginRight: "10px",
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: "auto",
        width: "auto",
      },
      // justifyContent: "flex-end",
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      marginLeft: 30,
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 2),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("xl")]: {
        width: 250,
      },
    },
  };

  const count = preGlobalFilteredRows.length;

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.
  return (
    <Typography component="div" sx={classes.search}>
      <InputBase
        value={globalFilter || ""}
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={
          controlledPagination
            ? `${count} items in page...`
            : `${count} items...`
        }
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
      <Typography component="div" sx={classes.searchIcon}>
        <SearchIcon color="primary" />
      </Typography>
    </Typography>
  );
};

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
};

export default GlobalFilter;
