import { Box, Button } from "@mui/material";

export default function ActionButtons(props) {

  const classes = {
    button: {
      background: "none",
      border: "none",
      marginRight: "10px",
    },
  }

  const { content } = props;
  const iconContent = content.map((element, index) => {
    const { action, disabled = false, icon, title } = element;

    return (
      <Button
        sx={classes.button}
        disabled={disabled}
        key={index}
        onClick={action}
      >
        <i
          className={icon}
          style={disabled ? { pointerEvents: "none" } : {}}
          title={title}
        />
      </Button>
    );
  });
  return (
    <Box alignItems="center" display="flex">
      {iconContent}
    </Box>
  );
}
