import { Box, Grid } from "@mui/material";
import HoverIconButton from "../../../components/ReusedComponents/HoverIconButton";
import FacilitiesOverviewMap from "./FacilitiesOverviewMap";

export default function FacilitiesMap(props) {

  const classes = {
    mapContainer: {
      display: "flex",
      height: "75vh",
      justifyContent: "center",
    },
    iconButton: {
      "&:hover": {
        backgroundColor: "rgb(46, 105, 255, 0.1)",
      },
    },
    titleContainer: {
      display: "flex",
      marginBottom: "1rem",
      justifyContent: "space-between",
    },
  }

  const { setView, apiUrl, token } = props;

  return (
    <Box mt={3}>
      <Grid container>
        <Grid item sx={classes.titleContainer} xs={12}>
            <h3>Facilities Map</h3>
            <HoverIconButton
              cypressId="facilities-map-btn-back"
              handleClick={() => setView({ viewFacilityMap: false })}
              icon="fas fa-arrow-left"
              iconDirection="left"
              text="Back to Facilities"
            />
        </Grid>
        <Grid sx={classes.mapContainer} id="facilitiesMap" item xs={12}>
          <FacilitiesOverviewMap
            apiUrl={apiUrl}
            defaultZoomLevel={4}
            height="100%"
            token={token}
            width="90%"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
