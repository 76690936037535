// This function was created to work with the ReactDropZoneForImages component
export const addImageToOrganization = async (props, file, callback) => {
  const { apiUrl, organizationId, token } = props;
  const { name } = file[0];
  const body = new FormData();
  const reader = new FileReader();
  let image = null;
  const newBlob = new Blob([file[0].image]);

  reader.onload = async () => {
    image = reader.result;

    // Appending the file to the form
    body.append("pngimagedata", new Blob([image]));
    body.append("jsondata", JSON.stringify({ name }));
    const results = await fetch(
      `${apiUrl}organizations/${organizationId}/images`,
      {
        method: "POST",
        headers: {
          "auth-token": token,
        },
        body,
      }
    )
      .then((response) => response.json())
      .then((json) => json)
      .catch((err) => {
        console.log(err);
      });

    callback(results)
    return results;
  };

  reader.readAsArrayBuffer(newBlob);
};

export const deleteImageFromOrganization = async (props, IdOfImageToDelete) => {
  const { apiUrl, organizationId, token } = props
  const results = await fetch(
    `${apiUrl}organizations/${organizationId}/images/${IdOfImageToDelete}`,
    {
      method: "DELETE",
      headers: {
        "auth-token": token,
      }
    }
  )
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err);
    });

  return results
}

export const createOrganization = async (props, payload) => {
  const { apiUrl, token } = props
  const results = await fetch(`${apiUrl}organizations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload)
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err)
    })
  return results
}

export const editOrganization = async (props, payload) => {
  const { apiUrl, organizationId, token } = props
  const results = await fetch(`${apiUrl}organizations/${organizationId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload)
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err)
    })
  return results
}

export const getChildrenOrganizationUsers = async (props) => {
  const { apiUrl, organizationId, token } = props
  const results = await fetch(`${apiUrl}organizations/${organizationId}/appUsers`, {
    method: "GET",
    headers: {
      "auth-token": token,
    }
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err)
    })
  return results
}