import { uploadTemplateImages } from "./api";
import ReactDropZoneForImages from "../../components/Dropzone/ReactDropZoneForImages";
import Select from "../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../components/Forms/FieldTypes/TextField";
import Typography from "@mui/material/Typography";

const uploadImageTooltipText = (
  <p>
    Once an image has been uploaded, check the image preview while using the QR
    Size Adjustment, Version Adjustment, and Template Size Adjustment selections
    to make a corresponding QR Label best suit your or your companies needs:{" "}
    <br />
    <br />
    <ul>
      <li>
        For Small Templates LocatorX recommends images in the range of 70px x
        30px.
      </li>
      <li>
        For Medium Templates LocatorX recommends images in the range of 150px x
        50px.
      </li>
      <li>
        For Large Templates LocatorX recommends images in the range of 270px x
        60px.
      </li>
    </ul>
    *Other sized images can also be used with adjustments.
  </p>
);

export default function SelectionTool(props) {
  const {
    aboveImages,
    belowImages,
    disabled = false,
    onChange,
    position = "",
    setState,
    type,
    value = "",
  } = props;
  const selectSelector = () => {
    switch (type) {
      case "item":
        const itemLevelDataOptions = [{ label: "Item Tag", value: "tag" }];
        return (
          <Select
            disabled={disabled}
            label="Item Level Data"
            onChange={(event) => {
              const selectedItem = itemLevelDataOptions.find(
                (item) => item.value === event.target.value
              );
              onChange(selectedItem);
            }}
            options={itemLevelDataOptions}
            value={value.value}
            variant="outlined"
          />
        );
      case "batch":
        const batchLevelDataSelectionOptions = [
          { disabled: true, label: "Please Select", value: "" },
          { label: "Batch ID", value: "tag" },
          { label: "Batch Name", value: "name" },
          { label: "Batch Origin", value: "originString" },
          { label: "Final Destination", value: "destinationString" },
          { label: "Date Created", value: "timeCreated" },
        ];
        return (
          <Select
            disabled={disabled}
            label="Batch Level Data"
            onChange={(event) => {
              const selectedItem = batchLevelDataSelectionOptions.find(
                (item) => item.value === event.target.value
              );
              onChange(selectedItem);
            }}
            options={batchLevelDataSelectionOptions}
            value={value.value}
            variant="outlined"
          />
        );
      case "product":
        const productLevelDataOptions = [
          { label: "Product Name", value: "name" },
          { label: "Product Code", value: "tag" },
          { label: "Product Category", value: "productType" },
        ];

        return (
          <Select
            disabled={disabled}
            label="Product Level Data"
            onChange={(event) => {
              const selectedItem = productLevelDataOptions.find(
                (item) => item.value === event.target.value
              );
              onChange(selectedItem);
            }}
            options={productLevelDataOptions}
            value={value.value}
            variant="outlined"
          />
        );
      case "images":
        return (
          <ReactDropZoneForImages
            handleChange={(element) => {
              // This is a seperate function to update the session images
              uploadTemplateImages(
                {
                  ...props,
                  qrBackgroundId:
                    props.qrBackgroundSession.qrBackgroundSessionId,
                },
                element
              );

              setState((prevState) => {
                const body = { ...prevState, images: element };
                if (position === "above") {
                  body.aboveImages = element;
                } else {
                  body.belowImages = element;
                }
                return body;
              });
            }}
            images={position === "above" ? aboveImages : belowImages}
            limitOne={true}
            multiple={false}
            position={position}
            readOnly={disabled}
            tooltipText={uploadImageTooltipText}
          />
        );
      case "userDefinedText":
        return (
          <>
            <Typography variant="h6">User Defined Text</Typography>
            <SimpleTextField
              disabled={disabled}
              id="outlined-multiline-static"
              inputProps={{
                maxLength: 150,
              }}
              label="Multiline"
              multiline
              onChange={(event) => {
                onChange({
                  label: "userDefinedText",
                  value: event.target.value || "",
                });
              }}
              rows={4}
              value={value.value}
              variant="outlined"
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return selectSelector();
}
