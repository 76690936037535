import { formatClassifications } from "../../utils/helpers";

export const reducers = {
  // Redux Toolkit allows us to write "mutating" logic in reducers. It
  // doesn't actually mutate the state because it uses the Immer library,
  // which detects changes to a "draft state" and produces a brand new
  // immutable state based off those changes
  addZone: (state, action) => {
    state.zones[action.payload.zoneId] = action.payload;
  },
  editZone: (state, action) => {
    state.zones[action.payload.zoneId] = action.payload;
  },
  removeZone: (state, action) => {
    delete state.zones[action.payload.zoneId];
  },
  removeZones: (state, action) => {
    action.payload.forEach((zoneId) => {
      delete state.zones[zoneId];
    });
  },
  updateBatches: (state, action) => {
    state.batches = action.payload;
  },
  updateChildOrganizations: (state, action) => {
    state.childOrganizations = action.payload;
  },
  updateClassifications: (state, action) => {
    state.classifications = formatClassifications(action.payload);
  },
  updateCustoms: (state, action) => {
    state.customs = action.payload;
  },
  updateDisplayConfig: (state, action) => {
    state.displayConfig = action.payload;
  },
  updateFacilities: (state, action) => {
    const { body, facilityId } = action.payload;
    state.facilities[facilityId] = body;
  },
  updateFacility: (state, action) => {
    state.facilities[action.payload.facilityId] = action.payload;
  },
  updateFacilityZones: (state, action) => {
    const { facilityId, updatedZones } = action.payload;
    state.facilities[facilityId].zones = updatedZones;
  },
  removeFacility: (state, action) => {
    delete state.facilities[action.payload.facilityId];
  },
  updateUsersMap: (state, action) => {
    const { appUserId = "", body = {}, isDelete = false } = action.payload;
    if(isDelete) {
      delete state.usersMap[appUserId]
    } else {
      state.usersMap[appUserId] = body;
    }
  },
  updateOrganization: (state, action) => {
    state.mobileRoles = action.payload.rolesMap?.mobile || state.mobileRoles;
    state.organization = action.payload;
  },
  updateProducts: (state, action) => {
    const { productId, body } = action.payload;
    state.products[productId] = body;
  },
  updateTableColumnConfigs: (state, action) => {
     const { columnConfigs } = action.payload;
     state.tableColumnConfigs = columnConfigs
  }
};
