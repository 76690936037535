import React from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";

export default function RecursiveTreeView(props) {
  
  const classes = {
    root: {
      flexGrow: 1,
    },
    label: {
      fontFamily: "Lato",
      fontWeight: 500,
      fontSize: "1rem",
    },
  }

  const data = React.useMemo(() => props.data, [props.data]);

  const renderTree = (nodes) => (
    <TreeItem
      classes={{ label: classes.label }}
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.label}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      sx={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={data?.defaultExpanded || ["root"]}
      defaultSelected={data?.defaultSelected || "root"}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={(e, value) => {}}
    >
      {data ? renderTree(data) : null}
    </TreeView>
  );
}
