import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function ConfirmPCA(props) {
  const handleClose = (confirmed) => {
    props.setConfirmPCA(false);
    if (!confirmed) {
      props.cancel();
    }
  };

  return (
    <Dialog
      open={props.confirmPCA}
      onClose={handleClose}
      onBackdropClick={() => {
        handleClose(false);
      }}
      aria-labelledby="PCA Confirmation"
      aria-describedby="Please confirm PCA for this batch."
    >
      <DialogTitle>{"PCA"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Adding Items to the LocatorX Product Certificate Authority incurs
          additional costs. Are you sure that you wish to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            handleClose(true);
          }}
          color="primary"
        >
          Yes
        </Button>
        <Button
          onClick={(e) => {
            handleClose(false);
          }}
          color="primary"
          autoFocus
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
