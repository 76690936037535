import { Box, Tooltip } from "@mui/material";

export default function ZoneRadius(props) {
  const {
    backgroundColor = "rgba(0,0,0,0.5)",
    label = "",
    radiusX,
    style = {},
  } = props;

  return (
    // We apply css rules to this to make it into a circle with a radius of 10px
    <Tooltip title={label}>
      <Box
        sx={{
          ...style,
          borderRadius: "50%",
          backgroundColor,
          width: radiusX * 2 + "px",
          height: radiusX * 2 + "px",
          // transform: "translate(-50%, -50%)",
        }}
      >
        {/* {label} */}
      </Box>
    </Tooltip>
  );
}
