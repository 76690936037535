import { createRadio, deleteRadio, editRadio } from '../api';
import { Grid, IconButton, Typography } from '@mui/material';
import ActionButtons from '../../../../components/Buttons/ActionButtons';
import AddIcon from "@mui/icons-material/Add";
import CreEditModal from '../../../../components/Modals/CreEditModal/CreEditModal';
import MaterialUiDataGridPro from '../../../../components/Tables/DataGridPro/MaterialUiDataGridPro'
import React, { useEffect, useState } from 'react'
import SimpleTextField from '../../../../components/Forms/FieldTypes/TextField';
import NotificationModal from '../../../../components/Modals/NotificationModal';

function RadioTable(props) {

    const { apiUrl, token, state, setState, radios, zoneId } = props

    const { selectedRadio } = state || {}

    const { radioId, radioMac, xyz } = selectedRadio || {}

    const initialRadioModalFields = {
        radioMac: { error: false, id: "radioMac", label: "MAC Address", required: false, type: "text", value: "", },
        xCoordinate: { error: false, id: "xCoordinate", label: "X Coordinate", required: false, type: "number", value: "", },
        yCoordinate: { error: false, id: "yCoordinate", label: "Y Coordinate", required: false, type: "number", value: "", },
        zCoordinate: { error: false, id: "zCoordinate", label: "Z Coordinate", required: false, type: "number", value: "", },
    }

    const [radioModalFields, setRadioModalFields] = useState(initialRadioModalFields)

    const [modalOpen, setModalOpen] = useState(false)
    const [modalType, setModalType] = useState("Create")

    useEffect(() => {
        if (modalType === "Create") {
            setRadioModalFields(initialRadioModalFields)
            return
        }
        setRadioModalFields(
            {
                radioMac: { error: false, id: "radioMac", label: "MAC Address", required: false, type: "text", value: radioMac, },
                xCoordinate: { error: false, id: "xCoordinate", label: "X Coordinate", required: false, type: "number", value: xyz?.x, },
                yCoordinate: { error: false, id: "yCoordinate", label: "Y Coordinate", required: false, type: "number", value: xyz?.y, },
                zCoordinate: { error: false, id: "zCoordinate", label: "Z Coordinate", required: false, type: "number", value: xyz?.z, },
            }
        );
    }, [selectedRadio, modalOpen])

    const [alertModalState, setAlertModalState] = useState({
        alertModalColor: "success",
        modalShow: false,
        modalText: "",
    });

    function validateFields(data) {
        const errors = {};

        // Validate each field
        for (const field in data) {
            const { id, label, required, value } = data[field];

            if (required && (value === undefined || value === '')) {
                errors[id] = `${label} is required.`;
            }

        }

        // Check if x, y, and z coordinates are all present or all absent
        const coordinates = ['xCoordinate', 'yCoordinate', 'zCoordinate'];
        const coordinateValues = coordinates.map(coord => data[coord].value);
        const atLeastOneCoordinatePresent = coordinateValues.some(value => value !== undefined && value !== '');

        if (atLeastOneCoordinatePresent && !coordinateValues.every(value => value !== undefined && value !== '')) {
            coordinates.forEach(coord => {
                errors[coord] = 'All coordinates (X, Y, Z) must be present if at least one is present.';
            });
        }

        return errors;
    }

    const columns = [
        {
            field: "radioId",
            headerName: "Radio ID",
            renderCell: (data) => {
                return <Typography component="p">{data.row.radioId}</Typography>;
            },
            flex: 4,
        },
        {
            field: "radioMac",
            headerName: "MAC Address",
            renderCell: (data) => {
                return <Typography component="p">{data.row.radioMac}</Typography>;
            },
            flex: 3,
        },
        {
            field: "Actions",
            filterable: false,
            flex: 1,
            headerName: "",
            hideable: false,
            renderCell: (data) => {
                return (<>
                    <ActionButtons
                        content={[
                            {
                                icon: "fas fa-edit",
                                title: "Edit Zone",
                                action: () => {
                                    setState({
                                        ...state,
                                        selectedRadio: data.row,
                                        selectedRadioId: data.row.radioId,
                                    });
                                    setModalOpen(true)
                                    setModalType("Edit")
                                }
                            },
                            {
                                icon: "fas fa-trash-alt",
                                title: "Delete Zone",
                                action: () => handleDeleteSubmit(data.row.radioId),
                            }
                        ]
                        }
                    />
                </>
                );
            },
            sortable: false,
        },
    ];



    function handleCreateSubmit() {
        const errors = validateFields(radioModalFields);
        const hasErrors = Object.keys(errors).length > 0;

        if (hasErrors) {
            setRadioModalFields((prevState) => {
                const fields = { ...prevState };

                for (const field in fields) {
                    if (errors[field]) {
                        fields[field].error = true;
                        fields[field].errorText = errors[field];
                    }
                }

                return fields;
            });

            return;
        }

        const body = {
            radioMac: radioModalFields.radioMac.value,
            primaryPZoneId: zoneId,
            xyz: {
                x: radioModalFields.xCoordinate.value,
                y: radioModalFields.yCoordinate.value,
                z: radioModalFields.zCoordinate.value,
            },
        }

        createRadio({ apiUrl: apiUrl, token: token }, body).then((res) => {
            if (res.success) {
                setModalOpen(false)
                setState((prevState) => ({
                    ...prevState,
                    radios: [...prevState.radios, res.radio],
                }));
                //set alert modal to success. With text of "Radio successfully created"
                setAlertModalState((prevState) => ({
                    ...prevState,
                    alertModalColor: "success",
                    modalShow: true,
                    modalText: "Radio successfully created",
                }));

            }
        })
    }

    function handleEditSubmit() {
        const errors = validateFields(radioModalFields);
        const hasErrors = Object.keys(errors).length > 0;

        if (hasErrors) {
            setRadioModalFields((prevState) => {
                const fields = { ...prevState };

                for (const field in fields) {
                    if (errors[field]) {
                        fields[field].error = true;
                        fields[field].errorText = errors[field];
                    }
                }

                return fields;
            });

            return;
        }

        const body = {
            radioMac: radioModalFields.radioMac.value,
            xyz: {
                x: radioModalFields.xCoordinate.value,
                y: radioModalFields.yCoordinate.value,
                z: radioModalFields.zCoordinate.value,
            },
        }

        editRadio({ apiUrl: apiUrl, token: token }, body, radioId).then((res) => {
            if (res.success) {
                setModalOpen(false)
                setState((prevState) => ({
                    ...prevState,
                    radios: prevState.radios.map((radio) => {
                        if (radio.radioId === radioId) {
                            return res.radio
                        }
                        else {
                            return radio
                        }
                    }),
                }));
                setAlertModalState((prevState) => ({
                    ...prevState,
                    alertModalColor: "success",
                    modalShow: true,
                    modalText: "Radio successfully edited",
                }));
            }
        })

    }

    function handleDeleteSubmit(selectedRadioId) {
        deleteRadio({ apiUrl: apiUrl, token: token }, selectedRadioId).then((res) => {
            if (res.success) {
                setState((prevState) => ({
                    ...prevState,
                    radios: prevState.radios.filter((radio) => radio.radioId !== selectedRadioId),
                }));
                setAlertModalState((prevState) => ({
                    ...prevState,
                    alertModalColor: "success",
                    modalShow: true,
                    modalText: "Radio successfully deleted",
                }));
            }
        })
    }

    const fields = (
        <>
            {/* radioMac field */}
            <Grid item xs={12}>
                <SimpleTextField
                    error={radioModalFields.radioMac.error}
                    errorText={radioModalFields.radioMac.errorText}
                    label="MAC Address"
                    id="radioMac"
                    inputProps={{ "data-cypress-id": "zones-radio-mdl-txt-radiomac" }}
                    onChange={(event) => {
                        handleChange(event);
                    }}
                    value={radioModalFields.radioMac.value}
                />
            </Grid>

            {/* xCoordinate field */}
            <Grid item xs={12}>
                <SimpleTextField
                    error={radioModalFields.xCoordinate.error}
                    errorText={radioModalFields.xCoordinate.errorText}
                    label="X Coordinate"
                    id="xCoordinate"
                    inputProps={{ "data-cypress-id": "zones-radio-mdl-txt-xcoordinate" }}
                    onChange={(event) => {
                        handleChange(event);
                    }}
                    type="number"
                    value={radioModalFields.xCoordinate.value}
                />
            </Grid>

            {/* yCoordinate field */}
            <Grid item xs={12}>
                <SimpleTextField
                    error={radioModalFields.yCoordinate.error}
                    errorText={radioModalFields.yCoordinate.errorText}
                    label="Y Coordinate"
                    id="yCoordinate"
                    inputProps={{ "data-cypress-id": "zones-radio-mdl-txt-ycoordinate" }}
                    onChange={(event) => {
                        handleChange(event);
                    }}
                    type="number"
                    value={radioModalFields.yCoordinate.value}
                />
            </Grid>

            {/* zCoordinate field */}
            <Grid item xs={12}>
                <SimpleTextField
                    error={radioModalFields.zCoordinate.error}
                    errorText={radioModalFields.zCoordinate.errorText}
                    label="Z Coordinate"
                    id="zCoordinate"
                    inputProps={{ "data-cypress-id": "zones-radio-mdl-txt-zcoordinate" }}
                    onChange={(event) => {
                        handleChange(event);
                    }}
                    type="number"
                    value={radioModalFields.zCoordinate.value}
                />
            </Grid>
        </>
    )

    function handleChange(e) {
        const { id, value } = e.target;

        setRadioModalFields((prevState) => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                value: value,
            },
        }));
    }

    return (
        <Grid item sx={{ padding: "10px" }} xs={12}>
            {/* Success Modal */}
            <NotificationModal
                confirmationModalShow={alertModalState.modalShow}
                modalClose={() => {
                    setAlertModalState((prevState) => ({
                        alertModalColor: "success",
                        modalShow: false,
                        modalText: "",
                    }));
                }}
                confirmationText={alertModalState.modalText}
                color={alertModalState.alertModalColor}
                icon="fas fa-times-circle"
            />
            <Grid sx={{ display: "flex", gap: "10px" }}>
                <Typography component="h3" variant='h5'> Radio Units</Typography>
                <IconButton
                    aria-label={"Add New Radio"}
                    // data-cypress-id={}
                    onClick={() => {
                        setModalOpen(true)
                        setModalType("Create")
                    }
                    }
                    size="large"
                    style={{ padding: "0px" }}
                >
                    <AddIcon style={{ color: "#32355C" }} />
                </IconButton>
            </Grid>
            <CreEditModal
                fields={fields}
                handleEditSubmit={handleEditSubmit}
                handleCreateSubmit={handleCreateSubmit}
                modalOpen={modalOpen}
                modalType={modalType}
                setModalOpen={setModalOpen}
                state={state}
                setState={setState}
                title="Radio"
            />

            <MaterialUiDataGridPro
                autoHeight={false}
                columns={columns}
                getRowId={(row) => row.radioId}
                rows={radios || []}
                pageSize={10}
                checkboxSelection={false}
            />
        </Grid>
    )
}

export default RadioTable