const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const getConfigTables = async (props) => {
  const { token } = props;

  const results = await fetch(`${apiUrl}clientOrganizationConfig`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      return {
        error: "Failed to get settings, please contact system administrator.",
        // return { error: err }
      };
    });

  return results;
};

const editConfigTables = async (props) => {
  const { token, tablesData } = props;

  const results = await fetch(`${apiUrl}clientOrganizationConfig`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify({
      gridConfigs: tablesData,
    }),
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      return { error: err };
    });

  return results;
};

const createNewForm = async (props) => {
  const { token, formInfo } = props;
  const {
    formName,
    formDescription,
    fieldsFromCloneParent = { staticFields: [], fields: [] },
  } = formInfo;
  const payload = {
    name: formName,
    description: formDescription,
    multiUseFields: fieldsFromCloneParent,
    
    // Wild that we have to deal with this, once we remove fields from the backend we can remove this
    fields: []
  };

  const results = await fetch(`${apiUrl}forms`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .then((json) => json)
    .catch((err) => {
      return { error: err };
    });

  return results;
};

export const settings = {
  createNewForm,
  editConfigTables,
  getConfigTables,
};
