import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { cloneDeep, isEqual } from "lodash";
import { thunks } from "../../globalStore/slices/organization/organizationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ActionButtons from "../../components/Buttons/ActionButtons";
import ConfirmationModalContent from "../../components/Modals/ConfirmationModalContent";
import EditOrganization from "./EditOrganization";
import HoverIconButton from "../../components/ReusedComponents/HoverIconButton";
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import MaterialUiDataGridPro from "../../components/Tables/DataGridPro/MaterialUiDataGridPro";
import ModalDialog from "../../components/Modals/ModalDialog/ModalDialog";
import Users from "../users/Users";
import {
  GridToolbar,
} from "@mui/x-data-grid-pro";

export default function Organizations(props) {
  const {
    apiUrl,
    history,
    location: locationFromProps,
    token,
    userRoles,
  } = props;
  const childOrganizations = cloneDeep(
    useSelector((state) => state.organization.childOrganizations, isEqual)
  );

  const organizationsMap = Object.values(childOrganizations)

  const [state, setState] = useState({
    confirmationModalShow: false,
    isCreate: false,
    selectedOrganization: {},
    showOrganizationUsers: false,
    switchModal: [],
  });

  const handleState = (updatedState) => {
    setState((s) => {
      return {
        ...s,
        ...updatedState,
      };
    });
  };

  const dispatchGlobal = useDispatch();
  const location = useLocation();

  const { retrieveChildOrganizations: retrieveChildOrganizationsThunk } =
    thunks.childOrganizations;

  // onSuccess function retrieves child organizations, patch/diff with redux store, and resets location state
  const retrieveOrganizations = () => {
    dispatchGlobal(retrieveChildOrganizationsThunk());
    location.state = {};
  };

  // wrapping init up in useCallback to prevent useEffect loop
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(retrieveChildOrganizationsThunk());
      }
    },
    [retrieveChildOrganizationsThunk, dispatchGlobal]
  );

  const mounted = useRef(false);

  useEffect(() => {
    const { state: routerState = {} } = locationFromProps || {};
    const {
      modal = "",
      modalShow = false,
      modalTitle = "",
      isCreate = false,
    } = routerState;

    setState((s) => {
      return {
        ...s,
        isCreate,
      };
    });

    if (modalShow) {
      setState((s) => {
        return {
          ...s,
          modal,
          modalTitle,
          modalShow,
        };
      });
    }

    init(mounted.current);

    mounted.current = true;

    // This cleans up the quickLinks and modal behavior
    return history.listen((location) => {
      setState((s) => {
        return { ...s, ...location.state };
      });
    });
  }, [history, init, locationFromProps]);

  const handleDeleteOrganization = () => {
    const { selectedOrganization = {} } = state;
    const { organizationId = "" } = selectedOrganization;

    fetch(`${apiUrl}organizations/${organizationId}`, {
      method: "DELETE",
      headers: {
        "auth-token": token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          handleConfirmationModal("Organization Successfully Deleted");
          retrieveOrganizations();
        }
      })
      .catch(() => {
        handleConfirmationModal("There was a problem", true);
      });
  };

  const handleConfirmationModal = (confirmationText, error = false) => {
    handleState({
      confirmationModalShow: true,
      confirmationText,
      modalShow: false,
      error,
    });
  };

  const {
    confirmationModalShow,
    confirmationText,
    isCreate,
    modal,
    selectedOrganization,
    showOrganizationUsers,
  } = state;

  let modalClose = () =>
    handleState({
      isCreate: false,
      modalShow: false,
      selectedOrganization: {},
    });

  const tableColumns = [
    {
      field: "name",
      flex: 2,
      headerName: "Organization Name",
      renderCell: (data) => {
        const row = data.row;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleState({
                selectedOrganization: row,
                modalShow: true,
                modal: "Organization Information",
              });
            }}
          >
            {row.name}
          </div>
        );
      },
      valueGetter: (data) => data?.row?.name || "",
    },
    {
      field: "city",
      flex: 2,
      headerName: "City",
      renderCell: (data) => {
        const row = data.row;
        const { address = {} } = row;
        return (
          <div
            className="pointer"
            id="city"
            onClick={() => {
              handleState({
                selectedOrganization: row,
                modalShow: true,
                modal: "Organization Information",
              });
            }}
          >
            {address.city}
          </div>
        );
      },
      valueGetter: (data) => data?.row?.address?.city || "",
    },
    {
      field: "state",
      flex: 1,
      headerName: "State",
      renderCell: (data) => {
        const row = data.row;
        const { address = {} } = row;
        return (
          <div
            className="pointer"
            onClick={() => {
              handleState({
                selectedOrganization: row,
                modalShow: true,
                modal: "Organization Information",
              });
            }}
          >
            {address.state}
          </div>
        );
      },
      valueGetter: (data) => data?.row?.address?.state || "",
    },
    {
      disableSortBy: true,
      field: "export",
      flex: 1.5,
      headerName: "Actions",
      minWidth: 100,
      sortable: false,
      renderCell: (data) => {
        const buttonIcons = [];
        const row = data.row;
        if (userRoles["Edit Organizations"]) {
          // Edit Organizations details
          buttonIcons.push({
            icon: "fas fa-edit",
            title: "Edit",
            action: () => {
              handleState({
                modal: "Edit Organization",
                modalShow: true,
                selectedOrganization: row,
              });
            },
          });

          // Edit Organizations Users
          buttonIcons.push({
            icon: "fas fa-users",
            title: "Modify Users",
            action: () => {
              handleState({
                selectedOrganization: row,
                showOrganizationUsers: true,
              });
            },
          });
        }
        if (userRoles["Delete Organizations"]) {
          buttonIcons.push({
            icon: "fas fa-trash-alt",
            title: "Delete",
            action: () => {
              handleState({
                modal: "Delete Organization",
                modalShow: true,
                selectedOrganization: row,
              });
            },
          });
        }
        return <ActionButtons content={buttonIcons} />;
      },
    },
  ];

  const switchModal = (modal) => {
    switch (modal) {
      case "Edit Organization":
        return (
          <EditOrganization
            apiUrl={apiUrl}
            hasFormFields={true}
            isCreate={isCreate}
            notificationModal={handleConfirmationModal}
            onHide={modalClose}
            onSuccess={retrieveOrganizations}
            organization={selectedOrganization}
            token={token}
          />
        );
      case "Create Organization":
        return (
          <EditOrganization
            hasFormFields={true}
            notificationModal={handleConfirmationModal}
            onHide={modalClose}
            onSuccess={retrieveOrganizations}
            token={token}
            apiUrl={apiUrl}
            isCreate={isCreate}
            organization={selectedOrganization}
          />
        );
      case "Organization Information":
        return (
          <EditOrganization
            notificationModal={handleConfirmationModal}
            onHide={modalClose}
            onSuccess={retrieveOrganizations}
            token={token}
            apiUrl={apiUrl}
            isCreate={isCreate}
            readOnly={true}
            organization={selectedOrganization}
          />
        );
      case "Delete Organization":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedOrganization.name}? This action cannot be
              undone.`}
            handleSubmit={() => {
              handleDeleteOrganization();
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      default:
        return;
    }
  };

  function toolBarButtons() {
    return (
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        {userRoles["Create Organizations"] ? (
          <HoverIconButton
            handleClick={() =>
              handleState({
                isCreate: true,
                modal: "Create Organization",
                modalShow: true,
              })
            }
            icon="fas fa-plus"
            iconDirection="right"
            text="Create Organization"
          />
        ) : null}
      </Grid>)
  }

  return (
    <Box mt={3}>
      {showOrganizationUsers ?
        <Users
          {...props}
          apiUrl={apiUrl}
          handleGoBack={() => handleState({ showOrganizationUsers: false })}
          isMainOrg={false}
          selectedOrganization={selectedOrganization}
          token={token}
        /> :
        <Grid container>
          <ModalDialog
            handleClose={modalClose}
            open={state.modalShow}
            title={modal}
            content={switchModal(modal)}
          />
          <MaterialConfirmationModal
            content={confirmationText}
            closeModal={() => handleState({ confirmationModalShow: false, isCreate: false, selectedOrganization: {}, })}
            modalOpen={confirmationModalShow}
            variant="filled"
          />

          {/* Header */}
          <Grid item xs={12}>
            <Box mt={4} mb={3} textAlign="left">
              <Typography component="h1" variant="h4">
                Organizations
              </Typography>
            </Box>
          </Grid>

          {/* Table */}
          <Grid item xs={12}>
            <MaterialUiDataGridPro
              checkboxSelection={false}
              columns={tableColumns}
              components={GridToolbar}
              getRowId={(row) => row.organizationId}
              loading={false}
              needsCustomToolbar={true}
              rowCount={organizationsMap.length}
              rows={organizationsMap}
              toolBarButtons={toolBarButtons}
            />
          </Grid>
        </Grid>
      }
    </Box>
  );
}