import { Typography } from "@mui/material";

export default function PCAWarningIcon(props) {

  const classes = {
    container: {
      color:'#343a40',
      cursor: "default",
    },
    pcaWarning: {
      color: "red",
      "&:hover": {
        color: "red !important",
      },
    },
  }

  const { onClick, data } = props;
  return (
    <Typography
      component="div"
      onClick={() => onClick(data.row.original)}
      className={classes.container}
    >
      <i className={`fas fa-exclamation-circle ${classes.pcaWarning}`} />
    </Typography>
  );
}
