import React from "react";
import { useTable } from "react-table";

import PropTypes from "prop-types";

import { Table, TableBody, TableContainer, TableHead } from "@mui/material";

import { HeaderRow, HeaderCell, BodyRow, BodyCell } from "./StyledComponents";

const EnhancedTable = ({ columns, data }) => {
  
  const classes = {
    tableContainer: {
      border: "rgb(50, 53, 93, 0.2) 2px solid",
      borderRadius: "4px",
      backgroundColor: "#FAFAFA",
    },
  }
  
  const { getTableProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <TableContainer sx={classes.tableContainer}>
      <Table {...getTableProps()} size={"small"}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <HeaderRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <HeaderCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </HeaderCell>
              ))}
            </HeaderRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <BodyRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <BodyCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </BodyCell>
                  );
                })}
              </BodyRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// PropTypes for type checking
EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default EnhancedTable;
