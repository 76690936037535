import React, { useEffect, useState } from 'react'
import TreeMap from "react-d3-treemap";
import { getTreeData } from '../api';

function Aggregation(props) {
  const {
    assetData,
    apiUrl,
    token,
  } = props;

  const facilityId = assetData?.facilityId || null;
  const itemNumber = assetData?.customMap?.["Item Number"] || null;
  const organizationId = assetData?.organizationId || null;
  
  const [treeData, setTreeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const data = await getTreeData(facilityId, itemNumber, organizationId, apiUrl, token)
      if (data.error) {
        setIsLoading(false)
        return
      }
      const totalFacilityCount = data?.count

      const formattedResp = processObject(data?.zoneCountByFacility?.tree) || {}

      // Rename value key to parentCount
      formattedResp.parentCount = formattedResp.value
      delete formattedResp.value

      // convert formattedResp.parentCount to integer and compare to totalFacilityCount. If the totalFaciliyCount is greater than the parentCount, create a new object with the difference and add it to the children array.
      const parentCount = parseInt(formattedResp.parentCount)
      if (totalFacilityCount > parentCount) {
        const diff = totalFacilityCount - parentCount
        const unZonedObj = {
          name: "un-zoned",
          parentCount: `${diff}`,
          type: "branch",
          //in order to get the un-zoned object to show up in the tree, it needs to have a children array with a single object in it. The object needs to have a name and value key. The name key should be "un-binned" and the value key should be the difference between the totalFacilityCount and the parentCount.
          children: [
            {
              name: "un-binned",
              value: `${diff}`,
              type: "leaf",
            }
          ]
        }
        formattedResp.children.push(unZonedObj)
      }

      // Have to do this because count represents the total number of items in the facility, even when zones arent present.
      formattedResp.parentCount = `${totalFacilityCount}`;

      setTreeData(formattedResp)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  function processObject(obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (typeof obj[prop] === 'object') {
          // Recurse through child objects
          processObject(obj[prop]);
          
          // Check for type and value keys
          if (obj[prop].hasOwnProperty('type') && obj[prop].hasOwnProperty('value')) {
            if (obj[prop]['type'] === 'root' || obj[prop]['type'] === 'branch') {
              // Rename value key to parentCount
              obj[prop]['parentCount'] = obj[prop]['value'];
              delete obj[prop]['value'];
            }
          }

          // Check for name key with value "missing" and update to "un-binned"
          if (obj[prop].hasOwnProperty('name') && obj[prop]['name'] === 'missing') {
            obj[prop]['name'] = 'un-binned';
          }

          // Check for branches or leaves keys
          if (prop === 'branches') {
            // Rename branches key to children
            obj['children'] = obj[prop];
            delete obj[prop];
          } else if (obj[prop].hasOwnProperty('branches')) {
            // Rename branches key to children
            obj[prop]['children'] = obj[prop]['branches'];
            delete obj[prop]['branches'];
          } else if (obj[prop].hasOwnProperty('leaves')) {
            // Rename leaves key to children
            obj[prop]['children'] = obj[prop]['leaves'];
            delete obj[prop]['leaves'];
          }
        }
      }
    }
    return obj;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  return (
    <div className='treemap-container'>
        <h1>Item Number: {itemNumber}</h1>
        {
          !itemNumber && <div>Object must have item number and be part of a facility in order to aggregate</div>
        }
        <TreeMap breadCrumbClassName='treemap-breadcrumb' data={treeData} height={500} tooltipClassName='treemap-tooltip' width={700} />
    </div>
  )
}

export default Aggregation