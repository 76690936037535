import { Grid, Typography } from "@mui/material";

export default function NoFacilityPresent() {
  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant="h6">
          No facility has been selected. Please click "Back to Facilities" and
          select a facility.
        </Typography>
      </Grid>
    </Grid>
  );
}
