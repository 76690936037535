import React from "react";

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFlexLayout,
  useResizeColumns,
  useColumnOrder,
} from "react-table";

import PropTypes from "prop-types";

import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";

import {
  StyledTableHead,
  BodyRow,
  BodyCell,
  IndeterminateCheckbox,
} from "./StyledComponents";

import TableToolbar from "./TableToolbar";
import TablePaginationActions from "./TablePaginationActions";
import HeaderGroups from "./DnDHeaderGroups";

import { useStyles } from "./styles";

const EnhancedTable = ({
  columns,
  data,
  onColumnDrop,
  defaultColumnOrder,
  dragEnabled,
  tableHeader,
  isSelect,
  selectAllPageRows,
  onSelect,
  stickyHeader,
  defaultSort,
  toolbarChildren,
  language,
  totalCountLabel,
  assetStatusMap,
}) => {
  const classes = useStyles();

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    setColumnOrder,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          let r1 = row1.values[columnName] || "";
          let r2 = row2.values[columnName] || "";
          // Intl Collator can help us sort diacritics and other idiosyncracies across languages, supported on 95% of browsers (including IE). Setting "sensitivity" to "base" ignores case sensitivity, and "numeric" to "true" sorts alphanumerically
          return new Intl.Collator(language || null, {
            numeric: true,
            sensitivity: "base",
          }).compare(r1, r2);

          // try locale compare if intl collator acts up... intl collator is better performance, however.
          // return r1.localeCompare(r2, language || undefined, {
          //   numeric: true,
          //   sensitivity: "base",
          // });
        },
        number: (row1, row2, columnName) => {
          let r1 = row1.values[columnName];
          let r2 = row2.values[columnName];
          return r1 > r2 ? -1 : r1 < r2 ? 1 : 0;
        },
      },
      initialState: {
        pageIndex: 0,
        sortBy: React.useMemo(() => defaultSort, [defaultSort]),
        columnOrder: React.useMemo(() => defaultColumnOrder, [
          defaultColumnOrder,
        ]),
        pageSize: 25,
      },
    },
    useResizeColumns,
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    // hook into the table instance, e.g., instance.allColumns
    (instance) => {
      // if isSelect has been passed down through props, enable checkboxes on table
      if (isSelect) {
        instance.allColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // see https://react-table.tanstack.com/docs/api/useRowSelect to view how row select props are controlled in the react table
            // in this configuration, I am extending the default onChange behavior to include a function that may or may not be passed down from a parent component, e.g., controlling some HOC state
            Header: ({
              getToggleAllPageRowsSelectedProps,
              toggleAllPageRowsSelected,
              isAllPageRowsSelected,
            }) => {
              const { onChange } = {
                ...getToggleAllPageRowsSelectedProps(),
              };

              return (
                <IndeterminateCheckbox
                  onChange={(e) => {
                    if (selectAllPageRows) {
                      selectAllPageRows(
                        pageRef.current.map((row) => row.original),
                        isAllPageRowsSelected
                      );
                    }
                    onChange(e);
                    toggleAllPageRowsSelected(!isAllPageRowsSelected);
                  }}
                  className={classes.headerCheckbox}
                  checked={isAllPageRowsSelected}
                />
              );
            },

            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              const rowSelectedProps = { ...row.getToggleRowSelectedProps() };
              return (
                <IndeterminateCheckbox
                  onChange={(e) => {
                    if (onSelect) {
                      onSelect(row.original);
                    }
                    rowSelectedProps.onChange(e);
                  }}
                  className={classes.checkbox}
                  checked={rowSelectedProps.checked}
                />
              );
            },
            minWidth: "50px!important",
          },
          ...columns,
        ]);
      }
    }
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  // const [dense, setDense] = React.useState(false);

  // keep pageRef up to date with current page, helps with onSelect and selectAllPageRows
  const pageRef = React.useRef(page);
  React.useEffect(() => {
    pageRef.current = page;
  }, [page]);

  // hack to make default columns work
  React.useEffect(() => {
    setColumnOrder(defaultColumnOrder);
  }, [defaultColumnOrder, setColumnOrder]);

  return <>
    <TableToolbar
      numSelected={selectedRowIds ? Object.keys(selectedRowIds).length : ""}
      preGlobalFilteredRows={preGlobalFilteredRows}
      setGlobalFilter={setGlobalFilter}
      globalFilter={globalFilter || ""}
      tableHeader={tableHeader}
      toolbarChildren={toolbarChildren}
      count={data.length}
      totalCountLabel={totalCountLabel}
      assetStatusMap={assetStatusMap}
    />

    <TableContainer className={classes.tableContainer}>
      <Table
        stickyHeader={stickyHeader || false}
        {...getTableProps()}
        // size={dense ? "small" : "medium"}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StyledTableHead>
          <HeaderGroups
            headerGroups={headerGroups}
            allColumns={allColumns}
            setColumnOrder={setColumnOrder}
            classes={classes}
            isSelect={isSelect}
            onColumnDrop={onColumnDrop}
            dragEnabled={dragEnabled}
          />
        </StyledTableHead>
        <TableBody style={{ position: "relative", zIndex: 0 }}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <BodyRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <BodyCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </BodyCell>
                  );
                })}
              </BodyRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    {/* pagination component is meant to be child of table > tableBody > tr. Creating a new table component below to keep separate from main table */}

    <div className={classes.paginationContainer}>
      <Table>
        <TableBody>
          <TableRow>
            <TablePagination
              classes={{
                toolbar: classes.paginationToolbar,
                spacer: classes.paginationSpacer,
                caption: classes.paginationCaption,
                select: classes.paginationSelect,
              }}
              style={{ borderBottom: "none" }}
              rowsPerPageOptions={[10, 25, 50, 100, 250]}
              colSpan={3}
              count={data.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: false,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableBody>
      </Table>
      {/* <FormControlLabel
        control={
          <Switch
            color={"primary"}
            checked={dense}
            onChange={() => setDense(!dense)}
          />
        }
        label="Compact view"
      /> */}
    </div>
  </>;
};

// PropTypes for type checking
EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  // below are optional props
  tableHeader: PropTypes.string,
  defaultSort: PropTypes.array,
  stickyHeader: PropTypes.bool,
  isSelect: PropTypes.bool,
  selectAllPageRows: PropTypes.func,
  onSelect: PropTypes.func,
  toolbarChildren: PropTypes.any,
  language: PropTypes.string,
  defaultColumnOrder: PropTypes.array,
  onColumnDrop: PropTypes.func,
  dragEnabled: PropTypes.bool,
  totalCountLabel: PropTypes.string,
  assetStatusMap: PropTypes.bool,
};

export default EnhancedTable;
