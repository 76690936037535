const csmToken = process.env.REACT_APP_CSM_TOKEN;

export const editForm = async (props) => {
  const {
    apiUrl,
    formId,
    payload,
    token,
  } = props;

  const results = await fetch(`${apiUrl}forms/${formId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload)
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err)
    })
  return results

};

export const deleteForm = async (props) => {
  const {
    apiUrl,
    formId,
    token,
  } = props;

  const results = await fetch(`${apiUrl}forms/${formId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err)
    })
  return results

};

export const getForms = async (props) => {
  const {
    apiUrl,
    token,
  } = props;

  const results = await fetch(`${apiUrl}forms/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err)
    })
  return results
};

export const getForm = async (props) => {
  const {
    apiUrl,
    formId,
    token,
  } = props;

  const results = await fetch(`${apiUrl}forms/${formId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((err) => {
      console.log(err)
    })
  return results
};

