const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const showLastEvents = async (props, state) => {
  const { facilityId = "", organizationId, token } = props;

  const {  isExport = false, page = 0} = state;

  let elasticQuery = {
    elasticSearchQuery: {
      bool: {
        must: [
          {
            term: {
              current_owner_id: organizationId,
            },
          },
          {
            bool: {
              must_not: [
                {
                  nested: {
                    path: "device",
                    query: {
                      exists: {
                        field: "device.status",
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
        must_not: [
          {
            terms: {
              // If the user is on the Inventory Snapshot page, then we do not need
              // to exclude this
              asset_mode: [
                "Device",
                "Inventory",
                "Product",
              ],
            },
          },
          {
            term: {
              event: "External Scanned"
            }
          },
          {
            term: {
              event: "Proximity"
            }
          },
          {
            term: {
              event: "Moving Proximity"
            }
          },
          {
            regexp: {
              "last_event": {
                "value": "Scan.*LogisticsPro",
              }
            }
          },
        ],
        should: [],
      },
    },
    limit: "",
    range: {},
    page,
    sort: [
      {
        tag: "desc",
      },
    ],
  };

  elasticQuery.limit = isExport ? 1000 : 10000;

    //if facilityId is not empty, add it to the query
    if (facilityId) {
      elasticQuery.elasticSearchQuery.bool.must.push({
        term: {
          facility_id: facilityId,
        },
      });
    }

  const results = await fetch(`${apiUrl}assets/search`, {
    method: "POST",
    headers: {
      "auth-token": token,
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(elasticQuery),
    body: JSON.stringify(elasticQuery),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((err) => {
      console.log(err);
      console.log(elasticQuery);
      return {
        error: "Failed to fetch data, please contact system administrator.",
      };
    });
  return results;
};

export const saveFilterSettings = async (props, filters) => {
  const { token, appUserId } = props;
  const payload = {
    propertiesMap: {
      assetStatusTableSettings: filters,
    },
  };
  const results = await fetch(`${apiUrl}appUsers/${appUserId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
    body: JSON.stringify(payload),
  })
    .then((results) => results.json())
    .then((results) => results)
    .catch((err) => {
      console.log(err);
      console.log(payload);
      return {
        error: "Failed to save settings, please contact system administrator.",
      };
    });

  return results;
};

export const retrieveParentIds = async (props) => {
  const { token } = props;
  const results = await fetch(`${apiUrl}assets/assetParents`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  })
    .then((results) => results.json())
    .then((results) => {
      return results;
    });

  return results;
};

export const assets = {
  retrieveParentIds,
  saveFilterSettings,
  showLastEvents,
};
