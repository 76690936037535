import { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { naturalSort } from "../../../utils/naturalSort";
import { submitUpdateEvent } from "../api";
import Grid from "@mui/material/Grid";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import Paper from "@mui/material/Paper";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";

function Update(props) {

  const theme = useTheme();

  const classes = {
    formArea: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(2),
      textAlign: "left",
    },
    input: {
      width: "100%",
    },
    root: {
      flexGrow: 1,
      height: "100%",
    },
    select: {
      marginTop: theme.spacing(1),
      width: "100%",
    },
    submit: {
      marginTop: theme.spacing(4),
    },
  }

  const {
    apiUrl,
    assetsArray,
    eventTypes,
    facilities,
    setLoading,
    setModal,
    setState,
    state,
    token,
    userId,
  } = props;
  const itemOptions = [...assetsArray]
    .map((ass) => {
      return {
        label: ass.tag,
        value: ass.assetId,
      };
    })
    .sort((a, b) => naturalSort(a.label, b.label));
  
  const inititalUpdateEvent = {
    assets: null,
    binLocation: "",
    event: "",
    location: "",
    note: "",
    zone: "",
  }
  const [updateEvent, setUpdateEvent] = useState(inititalUpdateEvent);

  const [zones, setZones] = useState([]);

  const setFacilityZones = async (facilityId) => {
    if (!facilityId) {
      setZones([]);
    } else {
      const results = await fetch(`${apiUrl}facilities/${facilityId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      }).then((res) => res.json());

      if (results.success && results.facility?.zones?.length > 0) {
        setZones(results.facility.zones);
      } else {
        setZones([]);
      }
    }
  };

  const [isValid, setIsValid] = useState(false);

  const facilityOptions = Object.keys(facilities)
    .map((key) => {
      return {
        value: key,
        label: facilities[key].name,
      };
    })
    .sort((a, b) => naturalSort(a.label, b.label));

  useEffect(() => {
    if (
      updateEvent?.location?.value ||
      updateEvent?.event ||
      updateEvent?.assets?.length > 0
    ) {
    // if (
    //   updateEvent?.location?.value &&
    //   updateEvent?.event?.value &&
    //   updateEvent?.assets?.length > 0
    // ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [updateEvent]);
  return (
    <Box sx={classes.root}>
      <h4 style={{ textAlign: "left" }}>Update Event Log</h4>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Paper sx={classes.formArea} elevation={0}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                // Set the loading spinner on the BatchHistory page
                setLoading({ loading: true });

                submitUpdateEvent(
                  { apiUrl, token, facilities, userId },
                  updateEvent
                ).then((res) => {
                  // Turn off the loading spinner
                  setLoading({ loading: false });

                  if (res.error) {
                    setModal({
                      modalShow: true,
                      text: `Uh-oh! Something went wrong while updating event log... ${res.error}`,
                      isError: true,
                    });
                  } else {
                    setModal({
                      modalShow: true,
                      text: `Event update success!`,
                      isError: false,
                    });

                    setTimeout(() => {
                      setModal({
                        modalShow: false,
                        text: "",
                        isError: false,
                      });
                    }, 1500);

                    setState({
                      ...state,
                      histories: {
                        ...state.histories,
                        assetHistories: res.assetHistories.concat(
                          state?.histories?.assetHistories || []
                        ),
                        count: state.histories.count + 1,
                      },
                    });

                    setUpdateEvent(inititalUpdateEvent);
                  }
                });
              }}
            >
              <Grid container spacing={3}>
                {/* Item Select */}
                <Grid item xs={12} sm={4}>
                  <SimpleSelect
                    inputProps={{ "data-cypress-id": "update-batch-event-log-select-item" }}
                    label="Item"
                    // onChange={handleItemSelectChange}
                    multiple
                    onChange={(e) =>
                      setUpdateEvent((prevState) => ({
                        ...prevState,
                        assets: e.target.value,
                      }))
                    }
                    onChipDelete={(e) =>
                      setUpdateEvent((prevState) => {
                        const newAssets = prevState.assets.filter(
                          (item) => item !== e
                        );
                        return { ...prevState, assets: newAssets };
                      })
                    }
                    onClear={() => {
                      setUpdateEvent((prevState) => ({
                        ...prevState,
                        assets: null,
                      }));
                    }}
                    options={itemOptions}
                    // required
                    value={updateEvent.assets || []}
                    variant="outlined"
                  />
                </Grid>

                {/* Facility Select */}
                <Grid item sm={4} xs={12}>
                  <SimpleSelect
                    inputProps={{ "data-cypress-id": "update-batch-event-log-select-facility-name" }}
                    label="Facility Name"
                    onChange={(e) => {
                      const facilityLabel = facilityOptions.find(
                        (item) => item.value === e?.target?.value
                      )?.label;
                      setUpdateEvent({
                        ...updateEvent,
                        location: {
                          label: facilityLabel,
                          value: e?.target?.value,
                        },
                        zone: null,
                      });
                      setFacilityZones(e?.target?.value || null);
                    }}
                    onClear={() => {
                      setUpdateEvent((prevState) => ({
                        ...prevState,
                        location: null,
                        zone: null,
                      }));
                      setZones([]);
                    }}
                    options={facilityOptions}
                    // required
                    value={updateEvent?.location?.value}
                    variant="outlined"
                  />
                </Grid>

                {/* Zones */}
                {zones?.length > 0 ? (
                  <Grid item xs={12} sm={4}>
                    <SimpleSelect
                      classes={classes}
                      inputProps={{ "data-cypress-id": "update-batch-event-log-select-zone" }}
                      label="Zone"
                      onChange={(e) => {
                        setUpdateEvent({
                          ...updateEvent,
                          zone: e.target.value,
                        });
                      }}
                      onClear={() => {
                        setUpdateEvent((prevState) => ({
                          ...prevState,
                          zone: null,
                        }));
                      }}
                      options={zones
                        .map((zone) => {
                          return {
                            value: zone.zoneId,
                            label: zone.name,
                          };
                        })
                        .sort((a, b) => naturalSort(a.label, b.label))}
                      value={updateEvent.zone}
                      variant="outlined"
                    />
                  </Grid>
                ) : null}

                {/* Event Select */}
                <Grid item xs={12} sm={4}>
                  <SimpleSelect
                    inputProps={{ "data-cypress-id": "update-batch-event-log-select-event" }}
                    label="Event"
                    onChange={(e) => {
                      setUpdateEvent({
                        ...updateEvent,
                        event: e?.target?.value,
                      });
                    }}
                    options={eventTypes
                      .filter(
                        (e) =>
                          e !== "origin" &&
                          e !== "scan" &&
                          e !== "leaveGeoFence" &&
                          e !== "enterGeoFence"
                      )
                      .map((event) => {
                        return {
                          value: event,
                          label: event,
                        };
                      })
                      .sort((a, b) => naturalSort(a.label, b.label))}
                    // required
                    value={updateEvent.event}
                    variant="outlined"
                  />
                  
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                {/* Bin Location */}
                <Grid item xs={12} sm={6}>
                  <SimpleTextField
                    sx={classes.input}
                    inputProps={{ "data-cypress-id": "update-batch-event-log-txt-bin-location" }}
                    label="Bin Location"
                    margin="normal"
                    multiline={true}
                    name="binLocation"
                    onChange={(e) => {
                      setUpdateEvent({
                        ...updateEvent,
                        binLocation: e.target.value,
                      });
                    }}
                    required={false}
                    rows="1"
                    type="text"
                    value={updateEvent.binLocation}
                    variant="standard"
                  />
                </Grid>

                {/* Notes */}
                <Grid item xs={12} sm={6}>
                  <SimpleTextField
                    autoComplete="note"
                    sx={classes.input}
                    inputProps={{ "data-cypress-id": "update-batch-event-log-txt-notes" }}
                    label="Notes"
                    margin="normal"
                    multiline={true}
                    name="note"
                    onChange={(e) => {
                      setUpdateEvent({
                        ...updateEvent,
                        note: e.target.value,
                      });
                    }}
                    required={false}
                    rows="1"
                    type="text"
                    value={updateEvent.note}
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <MaterialUiButton
                className={classes.submit}
                color="submit"
                cypressId="update-batch-event-log-btn-submit"
                disabled={!isValid}
                label="Submit"
                type="submit"
                variant="contained"
              />
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Update;
