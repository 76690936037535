import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function CQRInformationCard(props) {

  const classes = {
    flex: {
      display: "flex",
    },
    header: {
      textAlign: "center",
      marginBottom: "1rem",
    },
    root: {
      margin: ".5rem 1rem",
    },
    title: {
      marginRight: "1rem",
      fontWeight: "bold",
    },
  }

  const {
    assetCount,
    assetType,
    batchAsset,
    batchName,
    productName,
    selectedAssets = {},
  } = props;
  const cardContent = batchAsset ? (
    <>
      <Grid sx={classes.flex} item xs={12}>
        <Typography sx={classes.title} variant="body1">
          Batch ID:
        </Typography>
        <Typography variant="body1">{batchName}</Typography>
      </Grid>
      <Grid sx={classes.flex} item xs={12}>
        <Typography sx={classes.title} variant="body1">
          Product Name:
        </Typography>
        <Typography variant="body1">{productName}</Typography>
      </Grid>
      <Grid sx={classes.flex} item xs={12}>
        <Typography sx={classes.title} variant="body1">
          Unit of Measure:
        </Typography>
        <Typography variant="body1">{assetType}</Typography>
      </Grid>
      <Grid sx={classes.flex} item xs={12}>
        <Typography sx={classes.title} variant="body1">
          Count:
        </Typography>
        <Typography variant="body1">{assetCount}</Typography>
      </Grid>
    </>
  ) : (
      <>
        <Grid item xs={9}>
          <Grid sx={classes.flex} xs={3}>
            <Typography sx={classes.title} variant="body1">
              Count:
            </Typography>
            <Typography variant="body1">
              {Object.keys(selectedAssets).length}
            </Typography>
          </Grid>
          <div>
            {Object.keys(selectedAssets).map((asset, index) => {
              const { assetMode, assetTag, tag } = selectedAssets[asset];

              return (
                <Grid sx={classes.flex} >
                  <Typography sx={classes.title} variant="body1">
                    {assetMode === "Inventory" ? "Inventory Tag" : "Asset Tag"}:
                  </Typography>
                  <Typography key={`${tag}-${index}`} variant="body1">
                    {tag || assetTag || ""}
                  </Typography>
                </Grid>
              );
            })}
          </div>
        </Grid>
      </>
  );
  return (
    <Card sx={classes.root}>
      <CardContent>
        <Typography sx={classes.header} variant="h4">
          Information
        </Typography>
        <Grid container>{cardContent}</Grid>
      </CardContent>
    </Card>
  );
}
