import { forwardRef } from "react";
import {
  AppBar,
  Button,
  Box,
  Dialog,
  Grid,
  Slide,
  Typography,
  Toolbar,
} from "@mui/material";
// import { FormFieldText } from "../FormFieldText";
import SimpleTextField from "../TextField";
import MaterialUiButton from "../../../Buttons/MaterialUiButton/MaterialUiButton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InformationSlide(props) {
  const { assetInfo = {}, open, setOpen } = props;
  const { asset = {} } = assetInfo;
  const { assets = [], tag = "" } = asset;
  const theAssetTheDeviceIsAttachedToo = assets[0] || {};
  const { customMap = {} } = theAssetTheDeviceIsAttachedToo;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      size="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
    >
      {/* Title */}
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography component="h1" variant="h5">{tag}</Typography>
        </Toolbar>
      </AppBar>

      {/* Asset Information */}
      <Grid
        container
        sx={{ height: "100%", padding: "0 1rem", paddingTop: "1rem" }}
      >
        <Grid item xs={12}>

          {/* Item Number */}

          {/* For NGC we are hard coding this. This is not scalable for other orgs. Further down
          the line we will need to find out if we need to append all custom data elements to this window */}
          <SimpleTextField
            label="Item Number"
            value={customMap['Item Number']}
            disabled={true}
          />

          {/* Unit of Measure */}
          <SimpleTextField
            label="Unit of Measure"
            value={customMap['Inv UOM']}
            disabled={true}
          />

          {/* Description */}
          <SimpleTextField
            label="Description"
            value={customMap['Item Description']}
            disabled={true}
          />
        </Grid>
      </Grid>

      {/* Close Button */}
      <Box marginBottom="2rem" marginTop="2rem" padding="0 1rem">
        <MaterialUiButton
          fullWidth
          label="Close"
          onClick={() => {
            handleClose();
          }}
          variant="contained"
        />
      </Box>
    </Dialog>
  );
}
