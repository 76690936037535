import { formatLatLon } from "../../../../utils/lat_lon_utils";
import { isEmpty } from "lodash";
import { parseUserInfo } from "../utils";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useTranslation as UseTranslation } from "react-i18next";
import modifyColumns from "../../../../utils/modifyColumns"

export default function buildColumns(props) {
  const {
    appUserType,
    classifications,
    columnOrder,
    customs,
    facilities,
    history,
    timeZone,
    zones,
  } = props;

  const { t } = UseTranslation();

  const showPCA = ![
    "Asset-Operations",
    "Asset",
    "Warehouse-Operations",
  ].includes(appUserType);

  const classificationColumns = !isEmpty(classifications)
    ? Object.keys(classifications).map((classKey) => {
        const { name } = classifications[classKey];
        return {
          field: name,
          headerName: name,
          renderCell: (data) => {
            const { assetId, row } = data;
            const { classificationMap = {} } = row;

            const classificationValue = Object.keys(classificationMap).filter(
              (k) => k === name
            )[0];

            return (
              <div
                className="pointer"
                key={assetId}
                onClick={() => onClick(row)}
              >
                {classificationMap[classificationValue] || t("dnpinput1")}
              </div>
            );
          },
        };
      })
    : [];

  const customColumns = !isEmpty(customs)
    ? customs.map((item) => {
        const { name } = item;
        return {
          field: name,
          headerName: name,
          renderCell: (data) => {
            const { row, tabIndex } = data;
            const { customMap = {} } = row;

            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];

            return (
              <div
                className="pointer"
                key={tabIndex}
                onClick={() => onClick(row)}
              >
                {customMap[customValue] || t("dnpinput1")}
              </div>
            );
          },
        };
      })
    : [];

  const historyTableColumns = [
    // timeOfLog
    {
      field: "timeOfLog",
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { timeOfLogLong } = data.row;

        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {timeOfLogLong
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (data) => {
        const timeOfLogLong = data.row.timeOfLogLong;
        return timeOfLogLong ? new Date(timeOfLogLong) : 
        //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
        new Date(8640000000000000);
      }
    },
    // assetTag
    {
      field: "assetTag",
      headerName: "Asset Tag",
      id: "assetTag",
      renderCell: (data) => {
        const { row, tabIndex } = data;
        const { assetTag } = row;
        return (
          <div className="pointer" key={tabIndex} onClick={() => onClick(row)}>
            {assetTag ? assetTag : t("dnpinput1")}
          </div>
        );
      },
    },
    // category
    {
      field: "category",
      headerName: "Asset Category",
      id: "category",
      renderCell: (data) => {
        const { assetId, row } = data;
        const { category } = row;
        return (
          <div className="pointer" key={assetId} onClick={() => onClick(row)}>
            {category ? category : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) =>
        data.row.category ? data.row.category : t("dnpinput1"),
    },
    // event
    {
      field: "event",
      headerName: "Event",
      id: "event",
      renderCell: (data) => {
        const { assetId, row } = data;
        const { event } = row;
        return (
          <div
            className="pointer"
            key={assetId}
            style={{
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => onClick(row)}
          >
            {event ? event : t("dnpinput1")}
          </div>
        );
      },
    },
    // user
    {
      field: "user",
      headerName: "User",
      id: "user",
      renderCell: (data) => {
        const { assetId, row } = data;
        const userInfo = parseUserInfo(row);
        return (
          <div
            className="pointer"
            key={assetId}
            style={{
              alignItems: "center",
              display: "flex",
            }}
            onClick={() => onClick(row)}
          >
            {userInfo || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => parseUserInfo(data.row),
    },
    // facility
    {
      field: "facility",
      headerName: "Facility Name",
      id: "facility",
      renderCell: (data) => {
        const { facility, facilityId, latitude, longitude } = data.row;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {facility
              ? facility
              : !latitude || !longitude
              ? t("dnpinput1")
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered"}
          </div>
        );
      },
      valueGetter: (data) => {
        const { row } = data;
        return row.facility ? row.facility : !row.latitude || !row.longitude
          ? t("dnpinput1")
          : row.facilityId &&
            facilities[row.facilityId] &&
            facilities[row.facilityId].name
          ? facilities[row.facilityId].name
          : "Not Registered";
      },
    },
    // zone
    {
      field: "zone",
      headerName: "Zone",
      id: "zone",
      renderCell: (data) => {
        const { zone = {} } = data.row;
        const {
          zoneName = "",
          pZoneName = "",
          zoneId = "",
        } = zone;
        
        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {/* Show pZoneName. If not present show pZoneName. If not present the Zone Name */}
            {pZoneName
              ? pZoneName
              : zoneName
              ? zoneName
              : zoneId && zones[zoneId]
              ? zones[zoneId].name
              : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { zone = {} } = data.row;
        const {
          zoneName = "",
          pZoneName = "",
          zoneId = "",
        } = zone;

        return pZoneName
          ? pZoneName
          : zoneName
          ? zoneName
          : zoneId && zones[zoneId]
          ? zones[zoneId].name
          : t("dnpinput1");
      },
    },
    // binLocation
    {
      field: "binLocation",
      headerName: "Bin Location",
      id: "binLocation",
      renderCell: (data) => {
        const { zone = {} } = data.row;
        const { binLocation = "" } = zone;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              data.row.zone
                ? binLocation
                : data.row.binLocation
                ? data.row.binLocation
                : data.row.propertiesMap && data.row.propertiesMap.binLocation
                ? data.row.propertiesMap.binLocation
                : t("dnpinput1")
            }
          </div>
        );
      },
      valueGetter: (data) => data.row.zone?.binLocation || "",
    },
    // state
    {
      field: "state",
      headerName: "State",
      id: "state",
      renderCell: (data) => {
        const { assetId, row } = data;
        const { state = t("dnpinput1") } = row;
        return (
          <div className="pointer" key={assetId} onClick={() => onClick(row)}>
            {state ? state : t("dnpinput1")}
          </div>
        );
      },
    },
    // latLong
    {
      field: "latLong",
      headerName: "Latitude, Longitude",
      id: "latLong",
      renderCell: (data) => {
        const { row, assetId } = data;
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return (
          <div className="pointer" key={assetId} onClick={() => onClick(row)}>
            {formattedLatLong}
          </div>
        );
      },
      valueGetter: (data) => {
        const { row } = data;
        const { latitude, longitude } = row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");
        return formattedLatLong;
      },
    },
  ]
    .filter((col) => {
      if (!showPCA) {
        return col.id !== "flagged";
      } else {
        return true;
      }
    })
    .concat(classificationColumns)
    // TODO - Elastic Wait for this to get refactored on AC4
    .concat(customColumns);

  const onClick = (row) => {
    const { assetId = "", assetType = "", batchId = "" } = row;
    if (assetId || batchId) {
      const url = batchId
        ? `/batches/${batchId}/history/${assetType}`
        : `/assetSnapshot/${assetId}`;
      history.push(url);
    }
  };

    // Here we need to go through the columnOrder and handle Sorting / Renaming / Visibility settings
    if (columnOrder) {
      return modifyColumns({ columnOrder, historyTableColumns });
    }

  return { historyTableColumns };
}
