import { useEffect, useMemo, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import GoogleMapComponent from "../../../components/Maps/GoogleMapComponent";
import { Typography } from "@mui/material"

export default function FacilitiesOverviewMap(props) {
  const facilitiesSelector = useSelector(
    (state) => state.organization.facilities,
    isEqual
  );
  const [mapSettings, setMapSettings] = useState({
    center: { lat: 60.363007, lng: -43.63390 },
    zoom: 5,
  });

  const facilities = useMemo(
    () =>
      Object.keys(facilitiesSelector)
        .map((k) => facilitiesSelector[k])
        .map((facility, idx) => {
          const validateLat =
            /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          const validateLong =
            /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
          if (
            facility &&
            (facility.location?.latitude !== 0 ||
              facility.location?.longitude !== 0) &&
            validateLat.test(facility.location?.latitude) &&
            validateLong.test(facility.location?.longitude)
          ) {
            return facility;
          }
          return null;
        })
        .filter((facility) => facility !== null) || [],
    [facilitiesSelector]
  );

  const markersToRender = facilities.map((facility, idx) => {
    const { location = {} } = facility;
    const { latitude, longitude } = location;

    return { ...facility, latitude, longitude };
  });

  const popUpContent = (facility) => {
    const { propertiesMap = null, location = null, facilityType = null, name = null, radius = null } = facility
    const { address1 = null, city = null, state = null, } = location
    const { locationDetails = null } = propertiesMap || {}

    return (
      <div
        className="popup"
      >
        {name ? (
          <Typography variant='h5' sx={{ marginTop: "5px" }}>{name}</Typography>
        ) : (
          ""
        )}
        {facilityType ? <Typography variant='h5'>{facilityType}</Typography> : ""}{" "}
        {location ? (
          <>
            <span>
              {address1 &&
                city &&
                state
                ? address1 +
                ", " +
                city +
                ", " +
                state
                : city && state
                  ? city + ", " + state
                  : ""}
            </span>
          </>
        ) : null}
        {radius ? <div>Radius: {radius} KM </div> : ""}
        {locationDetails ? (
          <span>Note: {locationDetails}</span>
        ) : (
          ""
        )}
      </div>
    )
  }

  // This useEffect sets the center of hte map to the first facility in the list
  useEffect(() => {
    if (facilities.length > 0) {
      const { location = {} } = facilities[0];
      const { latitude, longitude } = location;
      setMapSettings((prev) => ({ ...prev, center: { lat: latitude, lng: longitude } }))
    }
  }, [facilities])

  return (
    <GoogleMapComponent mapSettings={mapSettings} markersToRender={markersToRender} markerKey="facilityId" popUpContent={popUpContent} />
  );
}