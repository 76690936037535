import Button from "@mui/material/Button";

export default function MaterialUiButton(props) {
  const cssClasses = {
    cancel: {
      color: "#FFFFFF !important",
      backgroundColor: "#32355C !important",
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
        color: "#32355C !important",
      },
    },
    delete: {
      backgroundColor: "#B71D1A !important",
      color: "#FFFFFF !important",
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
        color: "#B71D1A !important",
      },
    },
    disabled: {
      backgroundColor: "#6D6E70 !important",
      color: "#E6E7E8 !important",
      "&:hover": {
        backgroundColor: "#6D6E70 !important",
        color: "#E6E7E8 !important",
      },
    },
    submit: {
      backgroundColor: "#A0C46D !important",
      color: "#FFFFFF !important",
      "&:hover": {
        backgroundColor: "#FFFFFF !important",
        color: "#A0C46D !important",
      },
    },
  };

  const {
    className = {},
    color = "primary",
    cypressId,
    disabled = false,
    disableElevation,
    disableFocusRipple,
    disableRipple,
    fullWidth = false,
    id,
    label = "Submit",
    onClick,
    onMouseDown = () => {},
    role,
    size,
    sx = {},
    type,
    variant = "contained",
  } = props;

  return (
    <Button
      sx={{
        ...(color === "cancel" ? cssClasses.cancel : {}),
        ...(color === "delete" ? cssClasses.delete : {}),
        ...(color === "submit" ? cssClasses.submit : {}),
        ...(color === "primary" ? { backgroundColor: "#32355C !important" } : {}),
        ...(disabled ? cssClasses.disabled : {}),
        ...(className ? className : {}),
        ...sx,
      }}
      color={
        color === "cancel" ? "inherit" : color === "delete" ? "inherit" : color === "submit" ? "inherit" : color
      }
      data-cypress-id={cypressId}
      disabled={disabled || color === "disabled" ? true : false}
      disableElevation={disableElevation}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      fullWidth={fullWidth}
      id={id ? id : null}
      onClick={onClick}
      onMouseDown={onMouseDown}
      role={role}
      size={size}
      type={type}
      variant={variant}
    >
      {label}
    </Button>
  );
}
