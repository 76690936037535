import { formatLatLon } from "../../../../utils/lat_lon_utils";
import { isEmpty } from "lodash";
import { parseUserInfo } from "../utils";
import { unix_utc_to_local_12_hour_clock } from "../../../../utils/date_time_utils";
import { useTranslation as UseTranslation } from "react-i18next";
import moment from "moment-timezone";
import modifyColumns from "../../../../utils/modifyColumns";

export default function columns(props) {
  const {
    appUserType,
    classifications,
    columnOrder,
    customs,
    facilities,
    history,
    timeZone,
    zones,
  } = props;

  const { t } = UseTranslation();

  const showPCA = ![
    "Asset-Operations",
    "Asset",
    "Warehouse-Operations",
  ].includes(appUserType);

  const classificationColumns = !isEmpty(classifications)
    ? Object.keys(classifications).map((classKey) => {
        const { name } = classifications[classKey];
        return {
          field: name,
          headerName: name,
          id: name,
          renderCell: (data) => {
            const { classificationMap = {} } = data.row;

            const classificationValue = Object.keys(classificationMap).filter(
              (k) => k === name
            )[0];

            return (
              <div className="pointer" onClick={() => onClick(data.row)}>
                {classificationMap[classificationValue] || t("dnpinput1")}
              </div>
            );
          },
          valueGetter: (data) => {
            const { row } = data;

            if (row.classificationMap) {
              const classificationValue = row.classificationMap[classKey] || "";
              return classificationValue;
            }
            return "";
          },
        };
      })
    : [];

  const customColumns = !isEmpty(customs)
    ? customs.map((item) => {
        const { name } = item;
        return {
          field: name,
          headerName: name,
          id: name,
          renderCell: (data) => {
            const { customMap = {} } = data.row;

            const customValue = Object.keys(customMap).filter(
              (k) => k === name
            )[0];

            return (
              <div className="pointer" onClick={() => onClick(data.row)}>
                {customMap[customValue] || t("dnpinput1")}
              </div>
            );
          },
          valueGetter: (data) => {
            const { row } = data;

            if (row.customMap) {
              const customValue = row.customMap[name] || "";
              return customValue;
            }
            return "";
          },
        };
      })
    : [];

  const historyTableColumns = [
    // timeOfLog
    {
      field: "timeOfLog",
      headerName: "Date/Time",
      id: "timeOfLog",
      renderCell: (data) => {
        const { timeOfLogLong } = data.row;

        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {timeOfLogLong
              ? unix_utc_to_local_12_hour_clock(timeOfLogLong, timeZone)
              : t("dnpinput1")}
          </div>
        );
      },
      type: "dateTime",
      valueGetter: (data) => {
        const timeOfLogLong = data.row.timeOfLogLong;
        return timeOfLogLong ? new Date(timeOfLogLong) : 
        //furthest date in the future. We want records without a timeOfLogLong to be at the bottom of the table in case of "newest to oldest" sort
        new Date(8640000000000000);
      }
    },
    // tag
    {
      field: "tag",
      headerName: "Asset Tag",
      id: "tag",
      renderCell: (data) => {
        const { tag } = data.row;
        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {tag ? tag : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => (data.row.tag ? data.row.tag : t("dnpinput1")),
    },
    // category
    {
      field: "category",
      headerName: "Asset Category",
      id: "category",
      renderCell: (data) => {
        const { category } = data.row;

        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {category ? category : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) =>
        data.row.category ? data.row.category : t("dnpinput1"),
    },
    // description
    {
      field: "description",
      headerName: "Description",
      id: "description",
      renderCell: (data) => {
        const { propertiesMap = {} } = data.row;

        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row)}
          >
            {propertiesMap?.description || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) =>
        data.row.propertiesMap?.description || t("dnpinput1"),
    },
    // lastEvent
    {
      field: "lastEvent",
      headerName: "Last Event",
      id: "lastEvent",
      renderCell: (data) => {
        const { lastEvent } = data.row;
        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row)}
          >
            {lastEvent ? lastEvent : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) =>
        data.row.lastEvent ? data.row.lastEvent : t("dnpinput1"),
    },
    // user
    {
      field: "user",
      headerName: "User",
      id: "user",
      valueGetter: (data) => parseUserInfo(data.row),
      renderCell: (data) => {
        const row = data.row;
        const userInfo = parseUserInfo(row);

        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick(data.row)}
          >
            {userInfo || t("dnpinput1")}
          </div>
        );
      },
    },
    // facility
    {
      field: "facility",
      headerName: "Facility Name",
      id: "facility",
      renderCell: (data) => {
        const { facility, facilityId, latitude, longitude } = data.row;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {facility
              ? facility
              : !latitude || !longitude
              ? t("dnpinput1")
              : facilityId &&
                facilities[facilityId] &&
                facilities[facilityId].name
              ? facilities[facilityId].name
              : "Not Registered"}
          </div>
        );
      },
      valueGetter: (data) =>
        !data.row.latitude || !data.row.longitude
          ? t("dnpinput1")
          : data.row.facilityId &&
            facilities[data.row.facilityId] &&
            facilities[data.row.facilityId].name
          ? facilities[data.row.facilityId].name
          : "Not Registered",
    },
    // zone
    {
      field: "zone",
      headerName: "Zone",
      id: "zone",
      renderCell: (data) => {
        const { zone = {} } = data.row;
        const {
          zoneName = "",
          pZoneName = "",
          zoneId = "",
        } = zone;
        return (
          <div className="pointer" onClick={() => onClick(data.row)}>
            {/* Show pZoneName. If not present show pZoneName. If not present the Zone Name */}
            {pZoneName
              ? pZoneName
              : zoneName
              ? zoneName
              : zoneId && zones[zoneId]
              ? zones[zoneId].name
              : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        const { zone = {} } = data.row;
        const {
          zoneName = "",
          pZoneName = "",
          zoneId = "",
        } = zone;
        
        return pZoneName
          ? pZoneName
          : zoneName
          ? zoneName
          : zoneId && zones[zoneId]
          ? zones[zoneId].name
          : t("dnpinput1");
      },
    },
    // binLocation
    {
      field: "binLocation",
      headerName: "Bin Location",
      id: "binLocation",
      renderCell: (data) => {
        const { zone = {} } = data.row;
        const { binLocation = "" } = zone;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {
              // We have to accomodate for older assets that have the BinLocation in propertiesMap
              // The first ternay checks to see if its on the main object. The second checks if its
              // in the propertiesMap. The third then checks for it in the zone type.
              data.row.zone
                ? binLocation
                : data.row.binLocation
                ? data.row.binLocation
                : data.row.propertiesMap && data.row.propertiesMap.binLocation
                ? data.row.propertiesMap.binLocation
                : t("dnpinput1")
            }
          </div>
        );
      },
      valueGetter: (data) => data.row.zone?.binLocation || "",
    },
    // state
    {
      field: "state",
      headerName: "State",
      id: "state",
      renderCell: (data) => {
        const { state = t("dnpinput1") } = data.row;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {state ? state : t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => (data.row.state ? data.row.state : t("dnpinput1")),
    },
    // latLong
    {
      field: "latLong",
      headerName: "Latitude, Longitude",
      id: "latLong",
      renderCell: (data) => {
        const { latitude, longitude } = data.row;

        const formattedLatLong =
          latitude && longitude
            ? formatLatLon(latitude, longitude)
            : t("dnpinput1");

        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {formattedLatLong}
          </div>
        );
      },
      valueGetter: (data) =>
        `${data.row.latitude ? data.row.latitude : t("dnpinput1")}, ${
          data.row.longitude ? data.row.longitude : t("dnpinput1")
        }`,
    },
    // parentId
    {
      field: "parentId",
      headerName: "Parent Asset Tag",
      id: "parentId",
      renderCell: (data) => {
        const row = data.row;

        const parent =
          !row.ancestorList || !row.ancestors
            ? ""
            : row.ancestorList.filter(
                (ancestor) => ancestor.assetId === row.ancestors[0]
              )[0];

        return (
          <div
            className="pointer"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => onClick({ assetId: parent?.assetId || null })}
          >
            {parent.tag || t("dnpinput1")}
          </div>
        );
      },
      valueGetter: (data) => {
        if (!data.row.ancestorList || !data.row.ancestors) {
          return "";
        } else {
          // immediate parent is the first item in ancestors set, the child item's parent node in the hierarchy tree
          const parent = data.row.ancestorList.filter(
            (ancestor) => ancestor.assetId === data.row.ancestors[0]
          )[0];
          return parent.tag || "";
        }
      },
    },
    // timeCreatedUser
    {
      field: "timeCreatedUser",
      headerName: "Create Date",
      id: "timeCreatedUser",
      renderCell: (data) => {
        const row = data.row;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {row.timeCreatedUser
              ? moment(row.timeCreatedUser, "YYYYMMDDHHmmss").format(
                  "MM/DD/YYYY"
                )
              : t("dnpinput1")}
          </div>
        );
      },
      type: "date",
      valueGetter: (data) =>
        data.row.timeCreatedUser
          ? moment(data.row.timeCreatedUser, "YYYYMMDDHHmmss").format(
              "MM/DD/YYYY"
            )
          : "",
    },
    // timeNeeded
    {
      field: "timeNeeded",
      headerName: "Need Date",
      id: "timeNeeded",
      renderCell: (data) => {
        const row = data.row;
        return (
          <div className={"pointer"} onClick={() => onClick(data.row)}>
            {row.timeNeeded
              ? moment(row.timeNeeded, "YYYYMMDDHHmmss").format("MM/DD/YYYY")
              : t("dnpinput1")}
          </div>
        );
      },
      type: "date",
      valueGetter: (data) =>
        data.row.timeNeeded
          ? moment(data.row.timeNeeded, "YYYYMMDDHHmmss").format("MM/DD/YYYY")
          : "",
    },
    // Currently commenting these columns out for now. We have plans of possibly refactoring quanitity.
    // But for now, these columsn will be hidden until thats been done
    // {
    //   field: "quantityNeeded",
    //   headerName: "Quantity Needed",
    //   id: "quantityNeeded",
    //   renderCell: (data) => {
    //     const row = data.row;
    //     return (
    //       <div className={"pointer"} onClick={() => onClick(data.row)}>
    //         {row.quantity?.quantityNeeded || t("dnpinput1")}
    //       </div>
    //     );
    //   },
    //   type: "number",
    //   valueGetter: (data) => {
    //     if(data.row.quantity?.quantityNeeded) { return parseInt(data.row.quantity.quantityNeeded) }
    //     else { return Number.NEGATIVE_INFINITY }
    //   }
    // },
    // {
    //   field: "quantityShorted",
    //   headerName: "Quantity Short",
    //   id: "quantityShorted",
    //   renderCell: (data) => {
    //     const row = data.row;
    //     return (
    //       <div className={"pointer"} onClick={() => onClick(data.row)}>
    //         {row.quantity?.quantityShorted || t("dnpinput1")}
    //       </div>
    //     );
    //   },
    //   type: "number",
    //   valueGetter: (data) => {
    //     if(data.row.quantity?.quantityShorted) { return parseInt(data.row.quantity.quantityShorted) }
    //     else { return Number.NEGATIVE_INFINITY }
    //   },
    // },
    // {
    //   field: "quantityCurrent",
    //   headerName: "Quantity Qty",
    //   id: "quantityCurrent",
    //   renderCell: (data) => {
    //     const row = data.row;
    //     return (
    //       <div className="pointer" onClick={() => onClick(data.row)}>
    //         {row.quantity?.quantityCurrent || t("dnpinput1")}
    //       </div>
    //     );
    //   },
    //   type: "number",
    //   valueGetter: (data) => {
    //     if(data.row.quantity?.quantityCurrent) { return parseInt(data.row.quantity.quantityCurrent) }
    //     else { return Number.NEGATIVE_INFINITY }
    //   },
    // },
    // {
    //   field: "quantityStowed",
    //   headerName: "Qty Added",
    //   id: "quantityStowed",
    //   renderCell: (data) => {
    //     const row = data.row;
    //     return (
    //       <div className="pointer" onClick={() => onClick(data.row)}>
    //         {row.quantity?.quantityStowed || t("dnpinput1")}
    //       </div>
    //     );
    //   },
    //   type: "number",
    //   valueGetter: (data) => {
    //     if(data.row.quantity?.quantityStowed) { return parseInt(data.row.quantity.quantityStowed) }
    //     else { return Number.NEGATIVE_INFINITY }
    //   }
    // },
    // {
    //   field: "quantityPicked",
    //   headerName: "Qty Removed",
    //   id: "quantityPicked",
    //   renderCell: (data) => {
    //     const row = data.row;
    //     return (
    //       <div className="pointer" onClick={() => onClick(data.row)}>
    //         {row.quantity?.quantityPicked || t("dnpinput1")}
    //       </div>
    //     );
    //   },
    //   type: "number",
    //   valueGetter: (data) => {
    //     if(data.row.quantity?.quantityPicked) { return parseInt(data.row.quantity.quantityPicked) }
    //     else { return Number.NEGATIVE_INFINITY }
    //   }
    // },
  ]
    // We check to see if the users type needs the PCA field.
    .filter((col) => {
      if (!showPCA) {
        return col.id !== "flagged";
      } else {
        return true;
      }
    })
    .concat(classificationColumns)
    // TODO - Elastic - Uncomment this out once customs are fixed
    .concat(customColumns);

  const onClick = (row) => {
    const { assetId = "", assetType = "", batchId = "" } = row;
    if (assetId || batchId) {
      const url = batchId
        ? `/batches/${batchId}/history/${assetType}`
        : `/assetSnapshot/${assetId}`;
      history.push(url);
    }
  };

  // Here we need to go through the columnOrder and handle Sorting / Renaming / Visibility settings
  if (columnOrder) {
    return modifyColumns({ columnOrder, historyTableColumns });
  }

  return { historyTableColumns };
}
