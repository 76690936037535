import { CircularProgress, Typography } from "@mui/material";

export default function Loading(props) {
  const { color = "", margin, opaque, styles = {} } = props;

  const classes = {
    loading: {
      alignItems: "center",
      color: color,
      display: "flex",
      height: "100%",
      justifyContent: "center",
      margin: margin,
      position: "absolute",
      visibility: "visible",
      width: "100%",
      zIndex: 1,
    },
    opaqueStyle: {
      backgroundColor: "rgba(255,255,255,0.7)",
      bottom: "0",
      left: "0",
      position: "fixed",
      right: "0",
      top: "0",
      width: "100%",
    },
  }

  return (
    <Typography
      component="div"
      sx={{
        ...classes.loading,
        ...(opaque ? classes.opaqueStyle : {}),
        ...styles
      }}
    >
      <CircularProgress />
      <span>Loading...</span>
    </Typography>
  );
}
