import React from "react";

import clsx from "clsx";
import GlobalFilter from "./GlobalFilter";
import PropTypes from "prop-types";

import { Grid, Typography, Toolbar, lighten, useTheme } from "@mui/material";

const TableToolbar = (props) => {

  const theme = useTheme();

  const classes = {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      color: theme.palette.common.black,
      backgroundColor: lighten(theme.palette.primary.light, 0.85),
    },
    title: {
      flex: "1 1 100%",
    },  
  }

  const {
    numSelected,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    controlledPagination,
    toolbarChildren,
    count,
    totalCountLabel,
    assetStatusMap,
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
        <Grid item xs={4} xl={4}>
          Total {totalCountLabel || "Items"}: {count}
        </Grid>
        <Grid item xs={8} xl={7}>
          {" "}
          <GlobalFilter
            controlledPagination={controlledPagination}
            highlighted={numSelected > 0}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Grid>
      </Grid>

      {numSelected > 0 ? (
        <Typography
          sx={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={classes.title} variant="h4" id="tableHeader">
          {props.tableHeader ? props.tableHeader : ""}
        </Typography>
      )}
      {assetStatusMap ? null : (
        <Grid container spacing={3} justifyContent="flex-end" alignItems={"center"}>
          {toolbarChildren}
        </Grid>
      )}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  assetStatusMap: PropTypes.bool,
};

export default TableToolbar;
