import { createSlice } from "@reduxjs/toolkit";

import { initSettings } from "./thunks/init";
import * as thunks from "./thunks/thunks";
import { extraReducers } from "./thunks/extraReducers";

const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        init: {
            status: "idle",
        },
        displayConfig: [],
    },
    reducers: {},
    extraReducers: {
        ...extraReducers,
    },
 });


 export { initSettings, thunks };

 export default settingsSlice.reducer;