import { Box, Grid } from "@mui/material";
import lxlogobluegreen from "../../assets/lxlogobluegreen.png";

export default function SplashPage() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          alignItems="center"
          display="flex"
          height="100vh"
          justifyContent="center"
          width="100%"
        >
          <img alt="locatorx company logo" id="company-logo" src={lxlogobluegreen} height={"50%"} />
        </Box>
      </Grid>
    </Grid>
  );
}
