import React from "react";
import ApprovalIcon from '@mui/icons-material/Approval';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import RemoveIcon from "@mui/icons-material/Remove";
import ListItemText from "@mui/material/ListItemText";
import { IconButton, Typography, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { naturalSort } from "../../utils/naturalSort";

export default function SimpleList(props) {

  const theme = useTheme();

  const classes = {
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }

  const {
    disabled = false,
    isClassification = false,
    isColor,
    items,
    itemBtns,
    noSelectHighlight,
    onClick,
    onClickClone,
    onClickEdit,
    onClickRemove,
  } = props;
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  return (
    <Typography component="div" sx={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        {items && items.length
          ? items
              .filter((el) => el !== null)
              .sort((a, b) => naturalSort(a.label, b.label))
              .map((item, idx) => (
                <ListItem
                  aria-selected={selectedIndex === idx ? true : false}
                  disabled={disabled}
                  selected={!noSelectHighlight ? selectedIndex === idx : null}
                  button={!noSelectHighlight ? true : false}
                  key={`${idx} - ${item.id}`}
                  onClick={() => {
                    if (onClick) {
                      // onClick(item.id);
                      onClick(isClassification ? item.name : item.id);
                    }
                    setSelectedIndex(idx);
                  }}
                >
                  <ListItemText
                    style={
                      isColor && item.color ? { color: `${item.color}` } : null
                    }
                    primary={item.label ? item.label : item.name}
                  />
                  {onClickEdit ? (
                    <ListItemIcon>
                      <IconButton
                        //replace empty spaces with dash from id
                        data-cypress-id={`${itemBtns?.edit}-${item.id ? item.id.replace(/\s+/g, '-') : item.label}`}
                        onClick={() => {
                          onClickEdit(item.id);
                        }}
                        size="large">
                        <EditIcon style={{ color: "#32355C" }} />
                      </IconButton>
                    </ListItemIcon>
                  ) : null}
                  {onClickRemove ? (
                    <ListItemIcon>
                      <IconButton
                        data-cypress-id={`${itemBtns?.remove}-${item.id ? item.id.replace(/\s+/g, '-') : item.label}`}
                        onClick={(e) => {
                          onClickRemove(item.id ? item.id : item.customId);
                          setSelectedIndex(null);
                          e.stopPropagation();
                        }}
                        size="large">
                        <RemoveIcon style={{ color: "#b71d1a" }} />
                      </IconButton>
                    </ListItemIcon>
                  ) : null}
                  {onClickClone ? (
                    <ListItemIcon>
                      <IconButton
                        aria-label="clone (use as template for new form)"
                        data-cypress-id={`${itemBtns?.clone}-${item.id ? item.id.replace(/\s+/g, '-') : item.label}`}
                        onClick={(e) => {
                          onClickClone(item.id);
                          setSelectedIndex(null);
                          e.stopPropagation();
                        }}
                        title="Clone (use as template for new form)"
                        size="large">
                        <ApprovalIcon style={{ color: "#32355C" }} />
                      </IconButton>
                    </ListItemIcon>
                  ) : null}
                </ListItem>
              ))
          : null}
      </List>
    </Typography>
  );
}
