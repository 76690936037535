import { Grid, Typography } from "@mui/material";
import { GridPagination } from "@mui/x-data-grid-pro";
import { isEqual } from "lodash";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import buildColumns from "../inventoryHistory/Table/columns";
import InventoryAnalyzeFilters from "./InventoryAnalyzeFilters";
import InventoryStatusBuildColumns from "../inventoryStatus/Table/columns";
import MaterialUiDataGridPro from "../../../components/Tables/DataGridPro/MaterialUiDataGridPro";
import moment from "moment";

export default function InventoryAnalysis(props) {
  const {
    apiUrl,
    appUserType,
    handleSave,

    // The things this boolean does is determine what filters are appended, what endpoint we hit, and the id to be used for the row.
    // Everything else is the same
    isItemAnalysis = false,
    organizationId,
    token,
    userId,
    useSelector = () => {},
  } = props;
  const [state, setState] = useState({
    filters: {
      assetCategories: [],
      binLocations: [],
      classifications: [],
      customs: [],
      endDate: new Date(),
      events: [],
      limit: 10000,
      // Start date willd default to todays date
      startDate: new Date(),
      tag: "",
      users: [],
    },
    assetHistories: [],
    realCount: false,
  });

  const { realCount } = state;

  // OK to destructure, as no changes to org data takes place in this page
  const { zones, facilities, customs, classifications } =
    useSelector((state) => state.organization, isEqual) || {};

  const displayConfigs =
    useSelector((state) => state.organization.displayConfig) || [];
  const history = useHistory();
  const specificConfig = isItemAnalysis
    ? displayConfigs.find((element) => element.type === "ItemAnalysis")
    : displayConfigs.find((element) => element.type === "EventAnalysis");
  const timeZone = props.timeZone?.value || null;
  const columnOrder = specificConfig?.columns || null;

  const { historyTableColumns } = buildColumns({
    apiUrl,
    appUserType,
    classifications,
    columnOrder,
    customs,
    facilities,
    handleSave,
    history,
    setState,
    timeZone,
    token,
    userId,
    zones,
  });

  const { historyTableColumns: inventoryTableColumns } =
    InventoryStatusBuildColumns({
      apiUrl,
      appUserType,
      classifications,
      columnOrder,
      customs,
      facilities,
      handleSave,
      history,
      setState,
      timeZone,
      token,
      userId,
      zones,
    });

    const currentTime = moment().tz(timeZone && timeZone.value ? timeZone.value : timeZone ? timeZone : moment.tz.guess()).format("YYYY-MM-DD");
    const exportFileName = isItemAnalysis ? `Inv-Analysis_${currentTime}` : `Event-Analysis_${currentTime}`;

    function CustomPagination() {
      return (<Typography component="div"
        sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2px"
      }}
      >
        {realCount && realCount > 10000 ?  <Typography component="p" sx={{ fontSize: "0.875rem" }} >Real Count: {realCount}</Typography> : <p>{" "}</p>}
        <GridPagination />
      </Typography>
      )
    }
  return (
    <Grid
      container
      className="inventory-analyze"
      style={{
        marginTop: "1rem",
        width: "100% !important",
        padding: "0 15px",
        margin: "0 auto",
      }}
    >
      {/* Filters  */}
      <Grid item xs={12}>
        <InventoryAnalyzeFilters
          apiUrl={apiUrl}
          isItemAnalysis={isItemAnalysis}
          setState={setState}
          state={state}
          organizationId={organizationId}
          token={token}
        />
      </Grid>

      {/* Table */}
      <Grid item xs={12} sx={{ margin: "1rem 0 !important" }}>
        <MaterialUiDataGridPro
          autoHeight={false}
          checkboxSelection={false}
          columns={isItemAnalysis ? inventoryTableColumns : historyTableColumns}
          customComponents={{
            Footer: CustomPagination,
          }}
          delayedLoad={false}
          disableExportOption={false}
          exportFileName={exportFileName}
          getRowId={(row) => isItemAnalysis ? row.assetId : row.assetHistoryId}
          loading={false}
          rows={state?.assetHistories}
        />
      </Grid>
    </Grid>
  );
}
